define('dealer-portal/components/mark-sold-form', ['exports', 'ember', 'ember-changeset', 'ember-i18n', 'moment'], function (exports, _ember, _emberChangeset, _emberI18n, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    bosAllowed: false,
    fundingAllowed: false,

    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('model');
      this.unitChangeset = new _emberChangeset['default'](model.unit);
      this.bosChangeset = new _emberChangeset['default'](model.bosUFile);
      this.fundChangeset = new _emberChangeset['default'](model.fundUFile);
    },

    didRender: function didRender() {
      var now = new Date();
      var mm = now.getMonth() + 1;
      var dd = now.getDate();
      var yyyy = now.getFullYear();
      var today = yyyy + '/' + mm + '/' + dd;
      window.$('.date-sold').val((0, _moment['default'])(today).format('YYYY-MM-DD'));
    },

    saleTypeOptions: _ember['default'].computed('i18n.locale', function () {
      return [{ value: this.get('i18n').t('unitShowPage.unknown'), id: 1 }, { value: this.get('i18n').t('unitShowPage.cash'), id: 2 }, { value: this.get('i18n').t('unitShowPage.finance'), id: 3 }, { value: this.get('i18n').t('unitShowPage.auction'), id: 4 }, { value: this.get('i18n').t('unitShowPage.buyHerePayHere'), id: 5 }, { value: this.get('i18n').t('unitShowPage.wholesaler'), id: 6 }];
    }),

    actions: {
      cancel: function cancel() {
        this.set('soldForm', false);
      },
      updateFileTypeSelect: function updateFileTypeSelect(value) {
        var selected = value.target.value;
        _ember['default'].set(this, 'value', selected);
        this.set('nameError', false);
      },
      fileSelect: function fileSelect(value) {
        this.set('typeError', false);
      },
      expired: function expired() {},
      isAllowed: function isAllowed(file, type) {
        var setAllowed = type == 1 ? 'bosAllowed' : 'fundingAllowed';
        this.set(setAllowed, false);
        var allowed = ['text/plain', 'application/pdf', 'image/png', 'image/jpeg'];
        var self = this;
        allowed.forEach(function (type) {
          if (file.type.match(type)) {
            self.set(setAllowed, true);
          }
        });
      },
      submit: function submit() {
        var self = this;
        var unit = this.get('unitChangeset');
        var bosUFile = this.get('bosChangeset');
        var fundUFile = this.get('fundChangeset');
        if ((0, _moment['default'])(unit.get('dateSold')).isValid() && unit.get('saleType')) {
          var bosFile = null;
          var fundFile = null;
          if (document.getElementById('file-field-bos').files.length) {
            bosFile = document.getElementById('file-field-bos').files[0];
            this.send('isAllowed', bosFile, 1);
            if (this.get('bosAllowed')) {
              bosUFile.setProperties({ name: 'BOS', description: 'BOS uploaded ' + 'uploaded by Dealer through Dealer Portal when unit marked as sold',
                unit_id: this.get('model.unit.id'), ufile: bosFile });
            } else {
              this.set('typeError', true);
              return false;
            }
          } else {
            this.set('bosAllowed', true);
          }
          if (document.getElementById('file-field-funding-notice').files.length) {
            fundFile = document.getElementById('file-field-funding-notice').files[0];
            this.send('isAllowed', fundFile, 2);
            if (this.get('fundingAllowed')) {
              fundUFile.setProperties({ name: 'Funding Notice', description: 'Funding Notice uploaded by Dealer through Dealer Portal when unit ' + 'marked as sold', unit_id: this.get('model.unit.id'), ufile: fundFile });
            } else {
              this.set('typeError', true);
              return false;
            }
          } else {
            this.set('fundingAllowed', true);
          }
          if (this.get('bosAllowed') && this.get('fundingAllowed')) {
            this.set('typeError', false);
            this.set('showForm', false);
            this.set('formError', false);
            $('.mark-sold-button').attr("disabled", "disabled").val('Updating...');
            unit.set('soldOn', window.$('.date-sold').val());
            this.attrs.save(unit, bosUFile, fundUFile);
          }
        } else {
          this.set('formError', true);
        }
      }
    }
  });
});