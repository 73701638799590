define('dealer-portal/routes/access-home/request-floor', ['exports', 'ember', 'ember-changeset-validations', 'dealer-portal/validations/request-floor', 'ember-changeset'], function (exports, _ember, _emberChangesetValidations, _dealerPortalValidationsRequestFloor, _emberChangeset) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    RequestFloorValidations: _dealerPortalValidationsRequestFloor['default'],

    beforeModel: function beforeModel() {
      var userLevel = this.get('session').get('data').authenticated.level;
      if (userLevel === 'financial' || userLevel === 'sales') {
        this.transitionTo('access-home.index');
      }
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        dealer: this.store.findRecord('dealer', this.get('session.data.authenticated.dealerId'))
      });
    },

    //auctions: this.store.findAll('auction')
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('unitFiles', []);
      controller.set('showSubmit', true);
      controller.set('changeset', new _emberChangeset['default'](model.dealer, (0, _emberChangesetValidations['default'])(_dealerPortalValidationsRequestFloor['default']), _dealerPortalValidationsRequestFloor['default']));
      // let auctions = [];
      // let auctionList = model.auctions;
      // for(let x = 0; x < auctionList.get('length'); x++) {
      //   let auction = auctionList.objectAt(x).get('data');
      //   auctions.pushObject(Ember.Object.create({code: auction.name, text: auction.name}));
      // }
      // controller.set('model', Ember.Object.create({code: ''}));
      // controller.set('auctionsList', auctions);
    }
  });
});