define('dealer-portal/helpers/number-format', ['exports', 'ember-string-helpers/helpers/number-format'], function (exports, _emberStringHelpersHelpersNumberFormat) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberStringHelpersHelpersNumberFormat['default'];
    }
  });
  Object.defineProperty(exports, 'numberFormat', {
    enumerable: true,
    get: function get() {
      return _emberStringHelpersHelpersNumberFormat.numberFormat;
    }
  });
});