define("dealer-portal/controllers/access-home/request-floor", ["exports", "ember", "dealer-portal/validations/request-floor", "dealer-portal/adapters/application", "jquery"], function (exports, _ember, _dealerPortalValidationsRequestFloor, _dealerPortalAdaptersApplication, _jquery) {
  exports["default"] = _ember["default"].Controller.extend({
    ajax: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    userLog: _ember["default"].inject.service(),
    RequestFloorValidations: _dealerPortalValidationsRequestFloor["default"],
    unitFiles: [],
    auctionsList: [],
    showAddPhotoForm: true,
    showAddBOSForm: true,
    showAddTitleForm: true,
    showAddFileForm: true,
    bosUploaded: false,
    titleUploaded: false,
    showSubmit: true,
    showAuctions: false,
    showResults: false,
    selection: null,
    selectionName: null,
    auctionError: false,
    currentScroll: -1,

    uploadFile: function uploadFile(unitId, name, file) {
      var url = new _dealerPortalAdaptersApplication["default"]().fullURL("units/" + unitId + "/unit-files");
      var headers = new _dealerPortalAdaptersApplication["default"]().headersWithAuth(this.get("session"), this.get("userLog"));
      headers["X-Content-Name"] = name;
      headers["X-Content-Original-Name"] = file.name;
      this.get("ajax").request(url, {
        method: "POST",
        data: file,
        processData: false,
        contentType: "application/octet-stream",
        headers: headers
      });
    },

    init: function init() {
      var self = this;
      (0, _jquery["default"])(document).ready(function () {
        (0, _jquery["default"])(".auction_autocomplete input.ember-text-field").attr("autocomplete", "off");

        (0, _jquery["default"])(document).mouseup(function () {
          if (self.get("showResults")) {
            self.setProperties({
              showResults: false,
              currentScroll: 0
            });
          }
        });
        (0, _jquery["default"])(document).keyup(function (e) {
          if (self.get("showResults")) {
            var current = self.get("currentScroll");
            (0, _jquery["default"])("#index" + current).removeClass("active_list_selection");
            console.log(e.which);
            switch (e.which) {
              case 38:
                if (current === 0) {
                  current = self.get("auctionsList.length") - 1;
                } else {
                  current -= 1;
                }
                break;
              case 40:
                if (current === self.get("auctionsList.length") - 1) {
                  current = 0;
                } else {
                  current += 1;
                }
                break;
              default:
                return;
            }
            (0, _jquery["default"])("#index" + current).addClass("active_list_selection");
            self.set("currentScroll", current);
          }
        });
        (0, _jquery["default"])(document).keypress(function (e) {
          if (e.which === 13) {
            self.send("selectAuction", self.get("auctionsList")[self.get("currentScroll")]);
          }
        });
      });
    },
    buttonDisabled: _ember["default"].computed("bosUploaded", "titleUploaded", function () {
      if (this.get("bosUploaded") && this.get("titleUploaded")) {
        console.log("submit allowed");
        return false;
      }

      console.log("submit not allowed");
      return true;
    }),
    actions: {
      auctionSearch: function auctionSearch() {
        var _this = this;

        var term = event.target.value;
        this.set("auctionError", false);
        if (event.which !== 38 && event.which !== 40) {
          if (term.length >= 3) {
            new _dealerPortalAdaptersApplication["default"]().simple(this.get("ajax"), this.get("session"), this.get("userLog"), "auctions/floor_request_search", "GET", {
              term: term
            }).then(function (response) {
              _this.setProperties({
                auctionsList: response,
                showResults: true
              });
            });
          } else {
            this.set("showResults", false);
            this.set("currentScroll", -1);
          }
        }
        this.set("selection", null);
      },

      selectAuction: function selectAuction(auction) {
        event.preventDefault();
        this.setProperties({
          selection: auction.id,
          selectionName: auction.auction_name,
          showResults: false,
          currentScroll: -1
        });
        (0, _jquery["default"])(".auction_autocomplete input.ember-text-field").val(auction.auction_name);
        // window.$('#request_floor_submit').removeAttr('disabled');
      },

      itemSelected: function itemSelected(item) {
        event.preventDefault();
        this.set("selection", item.get("code"));
        // window.$('#request_floor_submit').removeAttr('disabled');
      },

      showAddFile: function showAddFile() {
        this.set("showAddFileForm", true);
        this.set("showSubmit", false);
      },

      addFile: function addFile(changeset) {
        var unitFiles = this.get("unitFiles");
        unitFiles.pushObject(changeset);
        this.set("showSubmit", true);
      },

      updateShowAuctions: function updateShowAuctions(event) {
        var checked = event.target.checked;
        this.set("showAuctions", checked);
        if (!checked) {
          this.setProperties({
            selection: null,
            selectionName: null
          });
        }
        (0, _jquery["default"])(".auction_autocomplete input.ember-text-field").attr("autocomplete", "off");
        // window.$('#request_floor_submit').attr('disabled', 'disabled');
      },
      submit: function submit() {
        var _this2 = this;

        this.set("showSubmit", false);
        var auctionPurchase = this.get("showAuctions");
        var auctionName = this.get("selectionName") || null;
        if (auctionPurchase && (auctionName == null || auctionName.length < 3)) {
          (0, _jquery["default"])(".auction_autocomplete input.ember-text-field").focus();
          this.set("auctionError", true);
          return false;
        } else {
          this.set("auctionError", false);
        }
        var changeset = this.get("changeset");
        var unit = {
          vin: changeset.get("vin"),
          mileage: changeset.get("mileage"),
          principal: changeset.get("principal"),
          auction_purchase: auctionPurchase,
          auction_name: auctionName
        };

        //const controller = this;

        new _dealerPortalAdaptersApplication["default"]().simple(this.get("ajax"), this.get("session"), this.get("userLog"), "dealer/request-floor", "POST", {
          request: unit
        }).then(function (response) {
          _this2.get("unitFiles").forEach(function (file) {
            _this2.uploadFile(response.data.id, file.get("name"), file.get("ufile"));
          });
          _this2.setProperties({
            showAuctions: false,
            showResults: false,
            auctionPurchase: null,
            selection: null,
            currentScroll: -1,
            selectionName: null
          });

          _this2.toast.info("Your request has been submitted");
          _this2.transitionToRoute("access-home");
          _this2.set("showAddBOSForm", true);
          _this2.set("showAddPhotoForm", true);
        })["catch"](function (data) {
          _this2.toast.error("VIN was invalid.");
          _this2.set("showSubmit", true);
          window.scrollTo(0, 0);
          (0, _jquery["default"])("#floor-request-inputs").find("input:first").focus();
        });
      }
    }
  });
});