define('dealer-portal/validations/app', ['exports', 'ember-changeset-validations/validators', 'dealer-portal/validators/aa-id'], function (exports, _emberChangesetValidationsValidators, _dealerPortalValidatorsAaId) {
  /* globals requirejs */
  requirejs.entries['ember-validations/messages'] = requirejs.entries['ember-changeset-validations/utils/messages'];

  exports['default'] = {
    first_name: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    last_name: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    dealership_name: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    email: [(0, _emberChangesetValidationsValidators.validateFormat)({ type: 'email' }), (0, _emberChangesetValidationsValidators.validatePresence)(true)],
    ssn: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 9 }), (0, _emberChangesetValidationsValidators.validateNumber)({ positive: true })],
    birthdate: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    // dba: validatePresence(true),
    // loan_request: validatePresence(true),
    // biz_years: validateNumber({positive: true}),
    // loc_years: validateNumber({positive: true}),
    // average_price: [
    //   validateNumber({positive: true})
    // ],
    // average_days: validateNumber({positive: true}),
    phone: [(0, _emberChangesetValidationsValidators.validateFormat)({ type: 'phone' }), (0, _emberChangesetValidationsValidators.validateLength)({ max: 10 }), (0, _emberChangesetValidationsValidators.validateNumber)({ position: true })],
    cell_phone: [(0, _emberChangesetValidationsValidators.validateFormat)({ type: 'phone' }), (0, _emberChangesetValidationsValidators.validateLength)({ max: 10 }), (0, _emberChangesetValidationsValidators.validateNumber)({ position: true })],
    address1: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    city: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    state: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    zip: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ max: 5 }), (0, _emberChangesetValidationsValidators.validateNumber)({ positive: true })],
    o1_zip: [(0, _emberChangesetValidationsValidators.validateLength)({ max: 5 }), (0, _emberChangesetValidationsValidators.validateNumber)({ positive: true })],
    aa_id: [
    // validateFormat({ allowBlank: true }),
    // validateFormat({ regex: /^[5]/, message: 'Must be a Valid Auction Access ID that starts with the number 5'})
    (0, _dealerPortalValidatorsAaId['default'])()],
    app_terms: (0, _emberChangesetValidationsValidators.validatePresence)(true)
    // password: [
    //   validateLength({min: 8})
    // ],
    // confirm_password: [
    //   validateLength({min: 8}),
    //   validateConfirmation({on: 'password'})
    // ],

    // //financial form
    // d_bank_phone: validateFormat({type: 'phone'}),

  };
});

// validateConfirmation,