define('dealer-portal/services/maps', ['exports', 'ember', 'dealer-portal/utils/google-maps'], function (exports, _ember, _dealerPortalUtilsGoogleMaps) {
  exports['default'] = _ember['default'].Service.extend({

    init: function init() {
      if (!this.get('mapUtil')) {
        this.set('mapUtil', _dealerPortalUtilsGoogleMaps['default'].create());
      }
    },

    getMapElement: function getMapElement(isIndex) {
      var element = '';
      if (!element) {
        element = this.createMapElement();
        this.get('mapUtil').createMap(element, isIndex);
      }
      return element;
    },

    createMapElement: function createMapElement() {
      var element = document.createElement('div');
      element.className = 'map';
      return element;
    },

    getBranch: function getBranch(address1, city, state, zip, branches) {
      var geocoded;
      return regeneratorRuntime.async(function getBranch$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get('mapUtil').checkInRegion(address1, city, state, zip, branches));

          case 2:
            geocoded = context$1$0.sent;

            // if(geocoded) {
            //   let marker = new window.google.maps.Marker({
            //     lot_num: 1,
            //     address1: geocoded.address1,
            //     city: geocoded.city,
            //     state: geocoded.state,
            //     zip: geocoded.zip,
            //     id: geocoded.id,
            //     lat: geocoded.latitude,
            //     lng: geocoded.longitude,
            //     position: new google.maps.LatLng(geocoded.latitude,
            //       geocoded.longitude),
            //     animation: google.maps.Animation.DROP,
            //     crossOnDrag: false,
            //     draggable: false,
            //   });
            //   markers.pushObject(marker);
            // }
            this.set('geocoded', geocoded);
            return context$1$0.abrupt('return', geocoded);

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    addDealerPlace: function addDealerPlace(place, address, lot_count, geocodedPlace) {
      var marker = this.get('mapUtil').addDealerPlace(place, address, lot_count, geocodedPlace);
    },

    placeMarkers: function placeMarkers() {
      var places = this.get('places');
      if (!places) {
        places = JSON.parse(localStorage.places);
      }
      this.get('mapUtil').placeMarkers(places);
    },

    auctionMarkers: function auctionMarkers(auctions) {
      this.get('mapUtil').auctionMarkers(auctions);
    },

    getMarkers: function getMarkers() {
      var markers = this.get('mapUtil').get('markers');
      return markers;
    },

    getPolygons: function getPolygons() {
      var polygons = this.get('mapUtil').get('polygons');
      return polygons;
    },

    createMarkers: function createMarkers(data, isIndex, isAppMap) {
      this.get('mapUtil').createMarkers(data, isIndex, isAppMap);
    },

    refreshMap: function refreshMap() {
      this.get('mapUtil').refreshMap();
    },

    geolocateByIp: function geolocateByIp() {
      var geolocation;
      return regeneratorRuntime.async(function geolocateByIp$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            geolocation = [];
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('mapUtil').geolocateByIp(geolocation));

          case 3:
            return context$1$0.abrupt('return', geolocation);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    auctionSearch: function auctionSearch(auctions) {
      this.get('mapUtil').auctionSearch(auctions);
    }
  });
});