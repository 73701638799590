define('dealer-portal/helpers/is-number', ['exports', 'ember-sort-filter-table/helpers/is-number'], function (exports, _emberSortFilterTableHelpersIsNumber) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersIsNumber['default'];
    }
  });
  Object.defineProperty(exports, 'isNumber', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersIsNumber.isNumber;
    }
  });
});