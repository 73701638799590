define('dealer-portal/controllers/app-simple', ['exports', 'ember', 'dealer-portal/validations/app', 'ember-changeset-validations', 'ember-changeset', 'ember-i18n'], function (exports, _ember, _dealerPortalValidationsApp, _emberChangesetValidations, _emberChangeset, _emberI18n) {
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = _ember['default'].Controller.extend({
    maps: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    branchName: null,
    branchFax: null,
    branchEmail: null,
    queryParams: ['appId', 'dealerId', 'showForm'],
    appId: null,
    dealerId: null,
    dealerFiles: [],
    showForm: true,
    newDealerFile: null,
    nameError: false,
    fileError: false,
    saving: false,
    typeError: false,
    saveError: false,
    saveSuccess: false,
    lastUpload: null,

    signature: _ember['default'].computed(function () {
      return _ember['default'].A();
    }),
    stringifiedSignature: _ember['default'].computed('signature.[]', function () {
      return JSON.stringify(this.get('signature'));
    }),
    savingText: _ember['default'].computed(function () {
      var saving = this.get('saving');
      var text = "t 'dealerFileUpload.uploadFile'";
      if (this.get('saving')) {
        text = "t 'dealerFileUpload.savingFile'";
      }
      return text;
    }),

    fileTypes: _ember['default'].computed('i18n.locale', function () {
      return [{ value: this.get('i18n').t('dealerFileUpload.bankStatement'), id: 'Bank Statement' }, { value: this.get('i18n').t('dealerFileUpload.dealerLicense'), id: 'Dealer License' }, { value: this.get('i18n').t('dealerFileUpload.dealerBond'), id: 'Dealer Bond' }, { value: this.get('i18n').t('dealerFileUpload.insurance'), id: 'Insurance' }, { value: this.get('i18n').t('dealerFileUpload.driversLicense'), id: 'Drivers License' }, { value: this.get('i18n').t('dealerFileUpload.accountsReceivable'), id: 'Accounts Receivable Detail' }, { value: this.get('i18n').t('dealerFileUpload.corporateDocumentation'), id: 'Corporate Documentation' }, { value: this.get('i18n').t('dealerFileUpload.salesReports'), id: 'Sales Reports' }, { value: this.get('i18n').t('dealerFileUpload.taxReturn'), id: 'Tax Return' }, { value: this.get('i18n').t('dealerFileUpload.financialStatement'), id: 'Financial Statement' }, { value: this.get('i18n').t('dealerFileUpload.other'), id: 'Other' }];
    }),

    actions: {
      save: function save(changeset) {
        var branch, self, branches, geocoded;
        return regeneratorRuntime.async(function save$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              branch = null;
              self = this;
              branches = _ember['default'].A(this.get('model.branches'));
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(this.get('maps').getBranch(changeset.get('address1'), changeset.get('city'), changeset.get('state'), changeset.get('zip'), branches));

            case 5:
              geocoded = context$1$0.sent;

              changeset.setProperties({ latitude: geocoded.lat, longitude: geocoded.lng, branch_id: geocoded.branchId });
              this.set('saving', true);
              context$1$0.next = 10;
              return regeneratorRuntime.awrap(changeset.save(function () {
                var timer = window.setTimeout(function () {
                  response.xhr.abort();
                  console.log('abort');
                }, 30000);

                return response.then(function (result) {
                  window.clearTimeout(timer);

                  return result;
                });
              })['catch'](function () {
                _this.set('saving', false);
                $('.app-continue-button').removeAttr("disabled").val('Submit');
                return false;
              }).then(function (app) {
                self.setProperties({ appId: app.get('id'), dealerId: app.get('dealer').get('id') });
                branch = app.get('dealer').get('branch');
                self.setProperties({ branchName: branch.get('name'), branchFax: branch.get('fax').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || '(405) 605-6998',
                  branchEmail: branch.get('id') == 17 || branch.get('email') == '' ? 'contracting@fpxus.com' : branch.get('email') });
                _this.set('saving', false);
                self.transitionToRoute('app-simple.show');
              })['catch'](function () {}));

            case 10:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
      },
      sig_reset: function sig_reset(index) {
        var sig = this.get('signature');
        _ember['default'].set(sig, 'signature', _ember['default'].A());
      },
      sig_undo: function sig_undo(index) {
        var sig = this.get('signature');
        var lastNewLine = undefined;
        sig.signature.forEach(function (item, index) {
          if (item[0] === 1) {
            lastNewLine = index;
          }
        });
        _ember['default'].set(sig, 'signature', _ember['default'].A(sig.signature.slice(0, lastNewLine)));
      },
      signature_capture: function signature_capture(person_id) {},
      expired: function expired() {},
      uploadDealerFile: function uploadDealerFile() {
        var self, upload;
        return regeneratorRuntime.async(function uploadDealerFile$(context$1$0) {
          var _this3 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              self = this;
              upload = this.get('model.newDealerFile');

              if (!(upload.get('name') && document.getElementById('file-field').files[0])) {
                context$1$0.next = 11;
                break;
              }

              if (!(window.grecaptcha.getResponse().length === 0)) {
                context$1$0.next = 7;
                break;
              }

              alert("Please provide a reCaptcha response.");
              context$1$0.next = 9;
              break;

            case 7:
              context$1$0.next = 9;
              return regeneratorRuntime.awrap((function callee$1$0() {
                var file, allowed, found;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  var _this2 = this;

                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      file = document.getElementById('file-field').files[0];
                      allowed = ['text/plain', 'application/pdf', 'image/png', 'image/jpeg'];
                      found = false;

                      allowed.forEach(function (type) {
                        if (file.type.match(type)) {
                          found = true;
                        }
                      });

                      if (!found) {
                        context$2$0.next = 15;
                        break;
                      }

                      upload.set('dfile', file);
                      upload.set('dealer_id', this.get('model.app.dealer').get('id'));
                      this.set('showForm', false);
                      this.set('showForm', true);
                      this.setProperties({ showForm: true, saving: true, typeError: false,
                        lastUpload: null, saveSuccess: false });
                      $('.file-upload-button').attr("disabled", "disabled").val('Saving...');
                      context$2$0.next = 13;
                      return regeneratorRuntime.awrap(upload.save().then(function (file) {
                        self.transitionToRoute('app-simple.file-submitted');
                        $('.file-upload-button').removeAttr("disabled").val('Upload File');
                        var filename = file.get('name');
                        var vowels = ['A', 'E', 'I', 'O', 'U'];
                        var vowelPresent = false;
                        for (var x = 0; x < vowels.length; x++) {
                          if (filename.startsWith(vowels[x])) {
                            vowelPresent = true;
                          }
                        }
                        if (vowelPresent) {
                          filename = 'an ' + filename;
                        } else {
                          filename = 'a ' + filename;
                        }
                        _this2.setProperties({ saving: false, saveError: false,
                          lastUpload: filename, saveSuccess: true });
                        var field = $('#file-field');
                        field.wrap('<form>').closest('form').get(0).reset();
                        field.unwrap();
                      })['catch'](function (adapterError) {
                        _this2.set('saveError', true);
                        _this2.set('saving', false);
                        $('.file-upload-button').removeAttr("disabled").val('Upload File');
                      }));

                    case 13:
                      context$2$0.next = 16;
                      break;

                    case 15:
                      this.set('typeError', true);

                    case 16:
                    case 'end':
                      return context$2$0.stop();
                  }
                }, null, _this3);
              })());

            case 9:
              context$1$0.next = 12;
              break;

            case 11:
              if (!upload.get('name')) {
                this.set('nameError', true);
              } else {
                this.set('fileError', true);
              }

            case 12:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});