define('dealer-portal/helpers/is-object', ['exports', 'ember-sort-filter-table/helpers/is-object'], function (exports, _emberSortFilterTableHelpersIsObject) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersIsObject['default'];
    }
  });
  Object.defineProperty(exports, 'isObject', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersIsObject.isObject;
    }
  });
});