define('dealer-portal/controllers/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    processing: false,
    queryParams: ['token'],

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('processing', true);

        var credentials = this.getProperties('identification', 'password'),
            authenticator = 'authenticator:jwt';

        this.get('session').authenticate(authenticator, credentials).then(function () {
          _this.get('session').set('data.expired', false);
          if (_this.get('session.data.authenticated.access')) {
            console.log(_this.get('session.data.authenticated.access'));
            _this.transitionToRoute('access-home.index');
            _this.toast.success('Login Successful');
            _this.set('processing', false);
          }
        }, function (reason) {
          if (reason && reason.errors && reason.errors.token && reason.errors.status === '302') {
            // auction user, need to redirect
            window.location.replace("https://auction.floorplanxpress.com/api_auth?token=" + reason.errors.token);
          } else {
            _this.set('processing', false);
            _this.toast.error('Login Failed. Please try again.');
          }
        });
      }
    }
  });
});