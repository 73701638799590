define('dealer-portal/controllers/access-home/reserves', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {

  var REQUEST_RESERVES_WITHDRAW = 'requestWithdraw';
  var REQUEST_RESERVES_FOR_PAYMENT = 'requestPayment';
  var ADD_TO_RESERVES = 'addToReserves';

  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    selectedAction: null,
    selectedAccount: null,
    selectedUnit: null,
    showErrors: false,
    errors: null,
    init: function init() {
      this._super();
      var self = this;
      _ember['default'].run.schedule("afterRender", this, function () {
        var accounts = this.get('model.bankAccounts');
        console.log(accounts);
        var accountId = null;
        accounts.forEach(function (account) {
          if (account.defaultAccount) {
            accountId = account.id;
          }
        });
        if (accountId == null) {
          console.log('here');
          accountId = accounts.objectAt(0).id;
        }
        self.set('selectedAccount', accountId);
      });
    },
    allowedWithdrawAmount: _ember['default'].computed(function () {
      return this.get('model.dealer.reservesWithdrawAllowed');
    }),
    actions: {
      checkReservesMax: function checkReservesMax() {
        var requestedAmount = this.get('requestedAmount');
        var isAddToReserves = this.get('selectedAction') === ADD_TO_RESERVES;
        var allowedWithdrawAmount = this.get('allowedWithdrawAmount');

        if (!isAddToReserves && requestedAmount > allowedWithdrawAmount) {
          alert("Your request is over the total amount in reserves.");
          this.set('requestedAmount', 0);
        }
      },
      setSelectedAccount: function setSelectedAccount(element) {
        this.set('selectedAccount', element.target.value);
      },
      setSelectedAction: function setSelectedAction(element) {
        var previouslySelectedValue = this.get('selectedAction');
        var currentlySelectedValue = element.target.value;

        switch (currentlySelectedValue) {
          case REQUEST_RESERVES_WITHDRAW:
            if (previouslySelectedValue === ADD_TO_RESERVES) {
              this.set('requestedAmount', 0);
            }
            this.set('selectedAction', REQUEST_RESERVES_WITHDRAW);
            this.set('showFromAccountDropdown', false);
            this.set('showToAccountDropdown', true);
            this.set('showUnitDropdown', false);
            // Do some stuff on the api for Requesting Reserves Withdrawal
            break;
          case REQUEST_RESERVES_FOR_PAYMENT:
            if (previouslySelectedValue === ADD_TO_RESERVES) {
              this.set('requestedAmount', 0);
            }
            this.set('selectedAction', REQUEST_RESERVES_FOR_PAYMENT);
            this.set('showToAccountDropdown', false);
            this.set('showFromAccountDropdown', false);
            this.set('showUnitDropdown', true);
            // Do some stuff on the api for Requesting Reserves Payment
            break;
          case ADD_TO_RESERVES:
            this.set('selectedAction', ADD_TO_RESERVES);
            this.set('showFromAccountDropdown', true);
            this.set('showToAccountDropdown', false);
            this.set('showUnitDropdown', false);
            // Do some stuff on the api for Adding to reserves
            break;
          default:
            this.set('showFromAccountDropdown', false);
            this.set('showToAccountDropdown', false);
            this.set('showUnitDropdown', false);
            this.setProperties({ selectedAction: null });
            return;
        }
      },
      setSelectedUnit: function setSelectedUnit(element) {
        if (element.target.value === 'Select Unit') {
          this.set('selectedUnit', null);
        } else {
          this.set('selectedUnit', element.target.value);
        }
      },
      submit: function submit() {
        var _this = this;

        var amount = this.get('requestedAmount') || null;
        var action = this.get('selectedAction');
        var account = this.get('selectedAccount');
        var unit = this.get('selectedUnit');
        var data = {};
        var errors = [];
        if (amount == null || amount == 0) {
          errors.push('Please enter an amount');
          this.set('errors', errors);
          this.set('showErrors', true);
          window.$('.reserves-amount').focus();
        } else {
          errors.filter(function (e) {
            return e !== 'Please enter an amount';
          });
        }
        switch (action) {
          case ADD_TO_RESERVES:
            data['type'] = 1;
            if (!account.length) {
              window.$('.reserves-bank-dropdown').focus();
              return false;
            }
            data['account'] = account;
            data['amount'] = amount;
            break;
          case REQUEST_RESERVES_WITHDRAW:
            data['type'] = 2;
            if (!account.length) {
              window.$('.reserves-bank-dropdown').focus();
              return false;
            }
            data['account'] = account;
            data['amount'] = amount;
            break;
          case REQUEST_RESERVES_FOR_PAYMENT:
            if (unit == null) {
              errors.push(' Please select a car for this payment');
              this.set('errors', errors);
              this.set('showErrors', true);
              window.$('.reserves-unit-dropdown').focus();
            } else {

              errors.filter(function (e) {
                return e !== ' Please select a car for this payment';
              });
            }
            data['type'] = 3;
            data['amount'] = amount;
            data['unit'] = unit;
            break;
          default:
            window.$('.reserve-choices').focus();
            return false;
        }
        if (errors.length == 0) {
          var _ret = (function () {
            _this.set('errors', []);
            _this.set('showErrors', false);
            var headers = {};
            _this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
              headers[headerName] = headerValue;
            });
            data['id'] = _this.get('model.dealer.id');
            var self = _this;
            self.toast.info('Sending request...');
            return {
              v: _this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('dealer/reserves-request'), {
                method: 'get',
                headers: headers,
                data: data
              }).then(function (response) {
                self.toast.info(response[0].message);
                _this.setProperties({ requestedAmount: 0 });
              })
            };
          })();

          if (typeof _ret === 'object') return _ret.v;
        }
      }
    }
  });
});