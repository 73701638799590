define("dealer-portal/models/unit", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
  exports["default"] = _emberData["default"].Model.extend({
    i18n: _ember["default"].inject.service(),

    vin: _emberData["default"].attr(),
    status: _emberData["default"].attr(),
    dueOn: _emberData["default"].attr(),
    currentPrincipal: _emberData["default"].attr(),
    currentFees: _emberData["default"].attr(),
    currentInterest: _emberData["default"].attr(),
    currentInsuranceFees: _emberData["default"].attr(),
    addToReserves: _emberData["default"].attr(),
    payoff: _emberData["default"].attr(),
    curtailment: _emberData["default"].attr(),
    curtail: _emberData["default"].attr(),
    curtailmentFee: _emberData["default"].attr(),
    lineType: _emberData["default"].attr(),
    paidFees: _emberData["default"].attr(),
    paidInterest: _emberData["default"].attr(),
    paidInsuranceFees: _emberData["default"].attr(),
    insuranceCoverageElected: _emberData["default"].attr(),
    paidPrincipal: _emberData["default"].attr(),
    paidReserves: _emberData["default"].attr(),
    payoffOn: _emberData["default"].attr(),
    fullName: _emberData["default"].attr(),
    stockNumber: _emberData["default"].attr(),
    name: _emberData["default"].attr(),
    make: _emberData["default"].attr(),
    model: _emberData["default"].attr(),
    year: _emberData["default"].attr(),
    color: _emberData["default"].attr(),
    originalPrincipal: _emberData["default"].attr(),
    firstFlooredOn: _emberData["default"].attr(),
    floorOn: _emberData["default"].attr(),
    daysFloored: _emberData["default"].attr(),
    pendingTransferInfo: _emberData["default"].attr(),
    totalDaysFloored: _emberData["default"].attr(),
    titleFileUrl: _emberData["default"].attr(),
    titleStatus: _emberData["default"].attr(),
    unitPhotoUrls: _emberData["default"].attr(),
    amountWrittenOff: _emberData["default"].attr(),
    canRefloor: _emberData["default"].attr(),
    sold: _emberData["default"].attr(),
    soldOn: _emberData["default"].attr(),
    reportedSoldOn: _emberData["default"].attr(),
    soldTo: _emberData["default"].attr(),
    historyTxns: _emberData["default"].attr(),
    activeFees: _emberData["default"].attr(),
    balanceLine: _emberData["default"].attr(),
    paidOff: _emberData["default"].attr(),
    achInitiated: _emberData["default"].attr(),
    pendingPaymentType: _emberData["default"].attr(),
    purchasedFrom: _emberData["default"].attr(),
    titleTrackingLink: _emberData["default"].attr(),
    bosFile: _emberData["default"].attr("file"),
    fundFile: _emberData["default"].attr("file"),
    refloorAllowed: _emberData["default"].attr(),
    photoUrls: _ember["default"].computed("unitPhotoUrls", function () {
      var unitPhotos = _ember["default"].A(this.get("unitPhotoUrls"));
      console.log(unitPhotos);
      return unitPhotos;
    }),

    serialNumber: _ember["default"].computed("vin", function () {
      return this.get("vin").substr(this.get("vin").length - 6, 6);
    }),

    paidAmount: _ember["default"].computed("paidPrincipal", "paidFees", "paidInsuranceFees", "paidReserves", "paidInterest", function () {
      return this.get("paidPrincipal") + this.get("paidFees") + this.get("paidReserves") + this.get("paidInterest") + this.get("paidInsuranceFees");
    }),

    translatedTitleStatus: _ember["default"].computed("titleStatus", function () {
      return this.get("i18n").t("unitTitleStatuses." + this.get("titleStatus"));
    })
  });
});