define('dealer-portal/components/ach-auth-form', ['exports', 'ember', 'ember-changeset-validations', 'ember-changeset'], function (exports, _ember, _emberChangesetValidations, _emberChangeset) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    height: 200,
    weight: 1,
    width: 600,

    signature: _ember['default'].computed(function () {
      return _ember['default'].A();
    }),
    stringifiedSignature: _ember['default'].computed('signature.[]', function () {
      return JSON.stringify(this.get('signature'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset['default'](this.get('model'));
    },

    actions: {
      submit: function submit() {
        var component = this;
        var changeset = this.get('changeset');
        // let accounts = changeset.get('bankAccounts');
        // if(this.get('signature').get('length') > 0) {
        //   let canvas = null;
        //   let dataURL = null;
        //   let sig = null;
        //   canvas = document.querySelector('#app_sig canvas');
        //   if(canvas) {
        //     dataURL = canvas.toDataURL();
        //     sig = this.get('signature');
        //     Ember.set(sig, 'dataURL', dataURL);
        //   }
        //   changeset.set('app_signature', sig.get('dataURL'));
        // }
        var termsSignature = changeset.get('termsSignature');
        if (termsSignature && this.get('unauthorized').get('length')) {
          this.attrs.save(termsSignature, component);
        } else {
          if (termsSignature) {
            this.toast.error('You must make a decision for all unauthorized ' + 'Bank Accounts');
          } else {
            this.toast.error('You must enter your name to agree to these terms.');
          }
        }
      },
      // sig_reset: function() {
      //   this.set('signature', Ember.A());
      // },
      // sig_undo: function() {
      //   let sig = this.get('signature');
      //   let lastNewLine;
      //   sig.forEach((item, index) => {
      //       if (item[0] === 1) {
      //           lastNewLine = index;
      //       }
      //   });
      //   this.set('signature', Ember.A(sig.slice(0, lastNewLine)));
      // },
      // signature_capture: function() {
      //   console.log('capture');
      // },
      agreeToTerms: function agreeToTerms(accountId, response) {
        // let changesetAccount =
        //   this.get('changeset').get('bankAccounts').find(id => accountId);
        // changesetAccount.set('termsAt', response)
        var account = {
          account_id: accountId,
          response: response
        };
        this.get('unauthorized').pushObject(account);
      }
    }
  });
});