define('dealer-portal/controllers/access-home/uploads', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    branchName: null,
    branchFax: null,
    branchEmail: null,
    queryParams: ['appId', 'dealerId', 'showForm'],
    appId: null,
    dealerId: null,
    dealerFiles: [],
    showForm: true,
    newDealerFile: null,
    nameError: false,
    fileError: false,
    saving: false,
    typeError: false,
    saveError: false,
    saveSuccess: false,
    lastUpload: null,

    savingText: _ember['default'].computed(function () {
      var saving = this.get('saving');
      var text = "t 'dealerFileUpload.uploadFile'";
      if (this.get('saving')) {
        text = "t 'dealerFileUpload.savingFile'";
      }
      return text;
    }),

    fileTypes: _ember['default'].computed('i18n.locale', function () {
      return [{ value: this.get('i18n').t('dealerFileUpload.bankStatement'), id: 'Bank Statement' }, { value: this.get('i18n').t('dealerFileUpload.dealerLicense'), id: 'Dealer License' }, { value: this.get('i18n').t('dealerFileUpload.dealerBond'), id: 'Dealer Bond' }, { value: this.get('i18n').t('dealerFileUpload.insurance'), id: 'Insurance' }, { value: this.get('i18n').t('dealerFileUpload.driversLicense'), id: 'Drivers License' }, { value: this.get('i18n').t('dealerFileUpload.accountsReceivable'), id: 'Accounts Receivable Detail' }, { value: this.get('i18n').t('dealerFileUpload.corporateDocumentation'), id: 'Corporate Documentation' }, { value: this.get('i18n').t('dealerFileUpload.salesReports'), id: 'Sales Reports' }, { value: this.get('i18n').t('dealerFileUpload.taxReturn'), id: 'Tax Return' }, { value: this.get('i18n').t('dealerFileUpload.financialStatement'), id: 'Financial Statement' }, { value: this.get('i18n').t('dealerFileUpload.other'), id: 'Other' }];
    }),

    actions: {
      rollback: function rollback(changeset) {
        changeset.rollback();
      },
      expired: function expired() {},
      uploadDealerFile: function uploadDealerFile() {
        var self, upload;
        return regeneratorRuntime.async(function uploadDealerFile$(context$1$0) {
          var _this2 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              self = this;
              upload = this.get('model.newDealerFile');

              if (!(upload.get('name') && document.getElementById('file-field').files[0])) {
                context$1$0.next = 11;
                break;
              }

              if (!(window.grecaptcha.getResponse().length === 0)) {
                context$1$0.next = 7;
                break;
              }

              alert("Please provide a reCaptcha response.");
              context$1$0.next = 9;
              break;

            case 7:
              context$1$0.next = 9;
              return regeneratorRuntime.awrap((function callee$1$0() {
                var file, allowed, found;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  var _this = this;

                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      file = document.getElementById('file-field').files[0];
                      allowed = ['text/plain', 'application/pdf', 'image/png', 'image/jpeg'];
                      found = false;

                      allowed.forEach(function (type) {
                        if (file.type.match(type)) {
                          found = true;
                        }
                      });

                      if (!found) {
                        context$2$0.next = 15;
                        break;
                      }

                      upload.set('dfile', file);
                      upload.set('dealer_id', this.get('session').get('data').authenticated.dealerId);
                      this.set('showForm', false);
                      this.set('showForm', true);
                      // this.set('saving', true);
                      // this.set('typeError', false);
                      this.setProperties({ showForm: true, saving: true, typeError: false,
                        lastUpload: null, saveSuccess: false });
                      $('.file-upload-button').attr("disabled", "disabled").val('Saving...');
                      context$2$0.next = 13;
                      return regeneratorRuntime.awrap(upload.save().then(function (file) {
                        self.transitionToRoute('access-home.file-submitted');
                        $('.file-upload-button').removeAttr("disabled").val('Upload File');
                        // this.set('saving', false);
                        // this.set('saveError', false);
                        // this.set('lastUpload', file.get('name'));
                        // this.set('saveSuccess', true);
                        // let name = null;
                        var filename = file.get('name');
                        var vowels = ['A', 'E', 'I', 'O', 'U'];
                        var vowelPresent = false;
                        for (var x = 0; x < vowels.length; x++) {
                          if (filename.startsWith(vowels[x])) {
                            vowelPresent = true;
                          }
                        }
                        if (vowelPresent) {
                          filename = 'an ' + filename;
                        } else {
                          filename = 'a ' + filename;
                        }
                        _this.setProperties({ saving: false, saveError: false,
                          lastUpload: filename, saveSuccess: true });
                        var field = $('#file-field');
                        field.wrap('<form>').closest('form').get(0).reset();
                        field.unwrap();
                      })['catch'](function (adapterError) {
                        _this.set('saveError', true);
                        _this.set('saving', false);
                        $('.file-upload-button').removeAttr("disabled").val('Upload File');
                      }));

                    case 13:
                      context$2$0.next = 16;
                      break;

                    case 15:
                      this.set('typeError', true);

                    case 16:
                    case 'end':
                      return context$2$0.stop();
                  }
                }, null, _this2);
              })());

            case 9:
              context$1$0.next = 12;
              break;

            case 11:
              if (!upload.get('name')) {
                this.set('nameError', true);
              } else {
                this.set('fileError', true);
              }

            case 12:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});