define('dealer-portal/routes/access-home/reports/txns', ['exports', 'ember', 'moment', 'accounting/format-money', 'ember-i18n'], function (exports, _ember, _moment, _accountingFormatMoney, _emberI18n) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    queryParams: {
      fromDate: {
        refreshModel: true
      }
    },
    fromDate: null,
    toDate: null,

    controllerName: 'access-home',
    model: function model(params) {
      var from = null;
      var to = null;
      if (params['fromDate'] == null) {
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear() - 1;
        from = yyyy + '/' + mm + '/' + dd;
        this.set('fromDate', from);
      } else {
        from = params['fromDate'];
      }
      if (params['toDate'] == null) {
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear();
        to = yyyy + '/' + mm + '/' + dd;
        this.set('toDate', to);
      } else {
        to = params['toDate'];
      }
      return _ember['default'].RSVP.hash({
        txns: this.store.query('txn', {
          filter: { scope: 'payment', fromDate: from, toDate: to }
        })
      });
    },
    setupController: function setupController(controller, model) {

      function styleAndFormatAsMoney(dollarAmount) {
        return '<span class="pull-right">' + (0, _accountingFormatMoney['default'])(dollarAmount) + '</span>';
      }
      var i18n = this.get('i18n');

      var previousInventoryHeadings = [{ key: 'postAt', display: this.get('i18n').t('reports.date').string }, { key: 'name', display: this.get('i18n').t('reports.for').string }, { key: 'type', display: this.get('i18n').t('reports.type').string }, { key: 'amount', display: this.get('i18n').t('reports.amount').string }, { key: '', display: this.get('i18n').t('reports.functions').string }];
      controller.set('inventoryHeadings', previousInventoryHeadings);
      var previousInventoryRows = [];

      model.txns.forEach(function (txn) {
        txn.get('paymentsUnitsForTxn').forEach(function (unit) {
          var unitHash = {
            postAt: "<span class='timestamp' style='display: none;'>" + (0, _moment['default'])(txn.get('postAt')).format('x') + "</span> " + (0, _moment['default'])(txn.get('postAt')).format('MM/DD/YYYY'),
            name: unit.name,
            type: txn.get('type'),
            amount: styleAndFormatAsMoney(unit.amount),
            actions: "<a href='/access-home/transactions/" + txn.get('id') + "'>" + "Deposit Detail</a>"
          };
          previousInventoryRows.pushObject(unitHash);
        });
      });
      controller.set('inventoryRows', previousInventoryRows);

      if (this.get('fromDate') != controller.get('fromDate')) {
        controller.set('fromDate', this.get('fromDate'));
      }
      if (this.get('toDate') != controller.get('toDate')) {
        controller.set('toDate', this.get('toDate'));
      }
      controller.set('transitionFrom', 'reports.txns');
    }
  });
});