define("dealer-portal/controllers/promotions", ["exports", "ember", "dealer-portal/adapters/application"], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports["default"] = _ember["default"].Controller.extend({
    ajax: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    userLog: _ember["default"].inject.service(),

    actions: {
      submit: function submit() {
        var _this = this;

        var headers = new _dealerPortalAdaptersApplication["default"]().headersWithAuth(this.get("session"), this.get("userLog"));
        this.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("coupon-redemptions"), {
          method: "POST",
          headers: headers,
          data: {
            promotion: true,
            dealer_id: this.get("session").get("data").authenticated.dealerId
          }
        }).then(function (response) {
          if (response.data.attributes["created-at"]) {
            _this.toast.info("Your December promotion is active");
            _this.transitionToRoute("access-home");
          }
        })["catch"](function (data) {
          _this.toast.error("You are already enrolled in the December promotion.");
          _this.transitionToRoute("access-home");
        });
      }
    }
  });
});