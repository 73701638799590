define('dealer-portal/routes/access-home/local-contact', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),

    controllerName: 'contact',

    model: function model() {
      // const dealer = this.store.findRecord('dealer',
      // this.get('session').get('data').authenticated.dealerId, {include: 'branches'});
      // console.log('dealer.branch: ', console.log(this.branches));
      // return dealer;
      return _ember['default'].RSVP.hash({
        dealer: this.store.findRecord('dealer', this.get('session').get('data').authenticated.dealerId, { includes: 'branches' }),

        branches: this.store.query('branch', {
          filter: { scope: 'open_corp' }
        }),
        contact: this.get('store').createRecord('contact')
      });
    },
    afterModel: function afterModel(model) {
      if (localStorage.places) {
        model.contact.set('branch_id', JSON.parse(localStorage.places).objectAt(0).branchId);
      }
    },

    actions: {
      submit: function submit(changeset) {
        console.log("Changeset: ", changeset);
        if (grecaptcha.getResponse() === "") {
          alert("Please provide a reCaptcha response.");
        } else {
          changeset.set('branch_id', this.controller.get('model.dealer.branch_id'));
          changeset.set('from', this.controller.get('model.dealer.name'));
          changeset.set('phone', this.controller.get('model.dealer.phone'));
          changeset.set('email', this.controller.get('model.dealer.email'));
          changeset.save();
          this.toast.info('Thank you for contacting us!');
          this.transitionTo('');
        }
      }
    }
  });
});