define('dealer-portal/routes/access-home/reserves', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),

    showAccountDropdown: false,
    requestedAmount: 0,

    beforeModel: function beforeModel(transition) {
      var userLevel = this.get('session').get('data').authenticated.level;
      if (userLevel === 'financial' || userLevel === 'sales') {
        this.transitionTo('access-home.index');
      }
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        dealer: this.store.find('dealer', this.get('session').get('data').authenticated.dealerId),
        units: this.get('store').query('unit', {
          filter: { scope: 'current' }
        }),
        bankAccounts: this.get('store').query('bank-account', {
          filter: { owner_id: this.get('dealerId') }
        }),
        dealerId: _ember['default'].computed(function () {
          return this.get('session').get('data').authenticated.dealerId;
        })
      });
    }
  });
});
// actions: {
//   checkReservesMax(event) {
//     const inputValue = event.target.value;
//     this.set('requestedAmount', inputValue);
//     console.log("Value: ", event.target.value);
//     console.log("allowedWithdrawAmount: ", this.get('allowedWithdrawAmount'));
//     if(inputValue > this.get('allowedWithdrawAmount')) {
//       console.log("Error you aked for too much bro!");
//     }
//   },
// }