define("dealer-portal/components/base-rate", ["exports", "ember", "dealer-portal/adapters/application"], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports["default"] = _ember["default"].Component.extend({
    fees: [],
    dealerName: "",
    ajax: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    userLog: _ember["default"].inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var component = this;

      var headers = new _dealerPortalAdaptersApplication["default"]().headersWithAuth(this.get("session"), this.get("userLog"));

      this.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("dealer/base-rate-fees?date=" + "8/13/21" + "dealer_id=" + this.get("session").get("data").authenticated.dealerId + ""), {
        method: "GET",
        headers: headers,
        data: {}
      }).then(function (response) {
        if (response) {
          _this.setProperties({ fees: response });
        } else {
          _this.toast.info("An error occured fetching the data");
        }
      });

      this.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("dealers/" + this.get("session").get("data").authenticated.dealerId), {
        method: "GET",
        headers: headers,
        data: {}
      }).then(function (response) {
        _this.setProperties({ dealerName: response.data.attributes.name });
      });
    }
  });
});