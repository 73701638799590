define('dealer-portal/controllers/forgot-password', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    userLog: _ember['default'].inject.service(),

    processing: false,

    actions: {
      forgotPassword: function forgotPassword() {
        var _this = this;

        this.set('processing', true);
        var component = this;

        new _dealerPortalAdaptersApplication['default']().simple(this.get('ajax'), this.get('session'), this.get('userLog'), 'auth/forgot-password', 'POST', { login: this.get('identification') }).then(function () {
          component.toast.info('Reset password email sent');
          _this.set('processing', false);
          _this.transitionToRoute('');
        });
      }
    }
  });
});