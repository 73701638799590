define('dealer-portal/components/main-carousel', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // This is where the data request happens.
      this.set('carouselItems', this.get('store').findAll('carousel-item'));
      console.log(this.get('carouselItems'));
    },

    didRender: function didRender() {
      var videos = document.getElementsByTagName('video');

      for (var i = 0; i < videos.length; i++) {
        videos[i].oncanplay = function () {
          this.muted = true;
          this.play();
        };
      }
    }
  });
});