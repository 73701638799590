define('dealer-portal/routes/branches/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    maps: _ember['default'].inject.service('maps'),

    model: function model(params) {

      var branchSlug = params['branch_slug'];
      // return this.get('store').queryRecord('branch', { slug: branchSlug });
      var branch = this.get('store').queryRecord('branch', { slug: branchSlug });
      return new _ember['default'].RSVP.Promise(function (resolve) {
        resolve(branch);
      });
    },
    afterModel: function afterModel(model) {
      _ember['default'].run.schedule('afterRender', this, function () {
        var maps = this.get('maps');
        var places = maps.get('places');
        var auctions = [];
        var auctionList = model.get('auctions');
        for (var x = 0; x < auctionList.get('length'); x++) {
          auctions.pushObject(auctionList.objectAt(x).get('data'));
        }
        // maps.auctionMarkers(auctions);
        if (!places) {
          places = JSON.parse(localStorage.places);
        }
        if (!_ember['default'].isBlank(places)) {
          maps.placeMarkers();
          // let geocoded = maps.geocoded;
        }
      });
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }

    // actions: {
    //   didTransition: function(places) {
    //     Ember.run.scheduleOnce('afterRender', this, function(){
    //       let maps = this.get('maps');
    //       let places = maps.places;
    //       console.log('in didTransition ' + places);
    //       if(places) {
    //         maps.placeMarkers();
    //       }
    //     });
    //   }
    // }
  });
});