define('dealer-portal/routes/careers', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    templateName: 'web-content',
    controllerName: 'web-content.careers',

    model: function model() {
      return this.get('store').findRecord('web-content', 'careers');
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var route = this;
      route.controller.set('careersPage', true);
      // this.get('ajax').request(
      //   new API().fullURL('web-contents/jobs'), {
      //     method: 'GET'
      //   }).then(response => {
      //     const jobs = response;
      //     route.controller.set('careersResults', Ember.A(jobs));
      //     route.controller.set('careersPage', true);
      //   }
      // );
    }
  });
});