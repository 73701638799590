define('dealer-portal/components/locale-link', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    tagName: 'li',

    actions: {
      setLocale: function setLocale(locale) {
        this.get('session').set('data.locale', locale);
        this.set('i18n.locale', locale);
      }
    }
  });
});