define('dealer-portal/components/app-header', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),

    didRender: function didRender() {
      this._super.apply(this, arguments);

      var component = this;
      if (component.$('.ember-smart-banner--inner').length) {
        component.$('#head-all').css('top', '76px');
        component.$('.banner-spacer').css('display', 'block');

        component.$('.ember-smart-banner').bind('DOMNodeRemoved', function (e) {
          component.$('#head-all').css('top', '0px');
          component.$('.banner-spacer').css('display', 'none');
        });
      }
    },

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
});