define('dealer-portal/validations/person', ['exports', 'ember-changeset-validations/validators'], function (exports, _emberChangesetValidationsValidators) {
  /* globals requirejs */
  requirejs.entries['ember-validations/messages'] = requirejs.entries['ember-changeset-validations/utils/messages'];

  exports['default'] = {
    first_name: [(0, _emberChangesetValidationsValidators.validatePresence)({ presence: true }), (0, _emberChangesetValidationsValidators.validateLength)({ min: 3 })],
    last_name: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ min: 3 })],
    phone: (0, _emberChangesetValidationsValidators.validateFormat)({ type: 'phone' }),
    email: (0, _emberChangesetValidationsValidators.validateFormat)({ type: 'email' })
  };
});