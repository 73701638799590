define('dealer-portal/models/bank-account', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    namePersonal: _emberData['default'].attr(''),
    nameBusiness: _emberData['default'].attr(''),
    bankName: _emberData['default'].attr(''),
    accountType: _emberData['default'].attr(''),
    dealerNickName: _emberData['default'].attr(''),
    routingNumber: _emberData['default'].attr(''),
    accountNumber: _emberData['default'].attr(''),
    defaultAccount: _emberData['default'].attr(''),
    needsAchAuth: _emberData['default'].attr(''),
    termsAt: _emberData['default'].attr(''),
    termsSignature: _emberData['default'].attr(''),
    dealer: _emberData['default'].belongsTo('dealer')
  });
});