define("dealer-portal/routes/access-home/ach-scheduling/confirm-payment", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    controllerName: "access-home.ach-scheduling",
    queryParams: {
      achId: {
        refreshModel: true
      }
    },
    achId: null,
    model: function model(params) {
      return _ember["default"].RSVP.hash({
        ach: this.get("store").find("transfer", params["achId"]),
        unit: this.modelFor("ach-scheduling.unit")
      });
    },

    actions: {
      confirmPayment: function confirmPayment() {}
    }
  });
});