define('dealer-portal/components/dealer-user-notifications-form', ['exports', 'ember', 'dealer-portal/validations/notification-settings', 'ember-changeset-validations', 'ember-changeset'], function (exports, _ember, _dealerPortalValidationsNotificationSettings, _emberChangesetValidations, _emberChangeset) {
  exports['default'] = _ember['default'].Component.extend({
    NotificationSettingsValidations: _dealerPortalValidationsNotificationSettings['default'],
    store: _ember['default'].inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset['default'](this.get('model'), (0, _emberChangesetValidations['default'])(_dealerPortalValidationsNotificationSettings['default']));
    },

    didRender: function didRender() {
      console.log('in didRender');
      $('[name="emailsFloor' + this.get('model.emailsFloor') + '"]').attr('checked', true);
      $('[name="emailsPayment' + this.get('model.emailsPayment') + '"]').attr('checked', true);
      $('[name="emailsLate' + this.get('model.emailsLateCurtail') + '"]').attr('checked', true);
      $('[name="emailsComingDue' + this.get('model.emailsComingDue') + '"]').attr('checked', true);
      $('[name="emailsAvail' + this.get('model.emailsAvail') + '"]').attr('checked', true);
    },

    actions: {
      emailsFloorChange: function emailsFloorChange(value) {
        for (var x = 1; x <= 4; x++) {
          if (x != value) {
            $('[name="emailsFloor' + x + '"]').attr('checked', false);
          }
        }
        this.set('changeset.emailsFloor', parseInt(value));
      },
      emailsPaymentChange: function emailsPaymentChange(value) {
        for (var x = 1; x <= 4; x++) {
          if (x != value) {
            $('[name="emailsPayment' + x + '"]').attr('checked', false);
          }
        }
        this.set('changeset.emailsPayment', parseInt(value));
      },
      emailsLateChange: function emailsLateChange(value) {
        for (var x = 1; x <= 4; x++) {
          if (x != value) {
            $('[name="emailsLate' + x + '"]').attr('checked', false);
          }
        }
        this.set('changeset.emailsLateCurtail', parseInt(value));
      },
      emailsComingDueChange: function emailsComingDueChange(value) {
        for (var x = 1; x <= 4; x++) {
          if (x != value) {
            $('[name="emailsComingDue' + x + '"]').attr('checked', false);
          }
        }
        this.set('changeset.emailsComingDue', parseInt(value));
      },
      emailsAvailChange: function emailsAvailChange(value) {
        for (var x = 1; x <= 4; x++) {
          if (x != value) {
            $('[name="emailsAvail' + x + '"]').attr('checked', false);
          }
        }
        this.set('changeset.emailsAvail', parseInt(value));
      },
      submit: function submit() {
        var component = this;
        var changeset = this.get('changeset');
        this.attrs.save(changeset);
      }
    }
  });
});