define('dealer-portal/controllers/app', ['exports', 'ember', 'dealer-portal/validations/app', 'ember-changeset-validations', 'ember-changeset'], function (exports, _ember, _dealerPortalValidationsApp, _emberChangesetValidations, _emberChangeset) {
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = _ember['default'].Controller.extend({
    AppValidations: _dealerPortalValidationsApp['default'],
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    dealerPlacesController: _ember['default'].inject.controller('app.dealer-places'),
    applicationController: _ember['default'].inject.controller('application'),

    showSignUp: true,
    dealership: true,
    places: true,
    placeEdit: true,
    financials: true,
    people: true,
    references: true,
    submit: true, //readdress this to
    auctionChecked: false,
    wholesalerChecked: false,
    otherChecked: false,
    current_page: 2,
    email: '',
    password: '',
    isAppMap: true,
    showAppSave: true,
    color: '#0000ff',
    height: 70,
    weight: 1,
    width: 600,
    readyToSubmit: false,
    submitText: 'Continue >>',
    submitButton: 'buttonClick',
    isSubmitted: false,
    signatures: _ember['default'].A(),
    // signatures: Ember.computed(function () {
    //   let people = this.get('model').app.get('dealer').get('people');
    //   console.log('people');
    //   console.log(people);
    //   console.log(people.length);
    //   let sig_array = Ember.A();
    //   for(let x = 0; x < people.length; x++) {
    //     sig_array.pushObject({id: people[x].id, signature: Ember.A()});
    //   }
    //   return sig_array;
    // }),
    stringifiedSignature: _ember['default'].computed('signature.[]', function () {
      return JSON.stringify(this.get('signature'));
    }),
    primaryPlaceId: _ember['default'].computed('dealer', function () {
      // return this.get('model').get('dealer').get('primaryPlaceId');
      return _ember['default'].get(this, 'model.dealer.primaryPlaceId');
    }),
    // isSubmitted: Ember.computed(function() {
    //   // let current = this.get('applicationController.currentRouteName');
    //   let model = this.get('model');
    //   console.log(model);
    //   let submitted = false;

    //   if(model.get('status') >= 2) {
    //     submitted = true;
    //   }
    //   console.log(submitted);
    //   return submitted;
    // }),
    // submitText: Ember.computed(function() {
    //   let text = 'Continue >>';
    //   if(this.get('applicationController.currentRouteName') == 'app.submit') {
    //     text = 'Submit';
    //   }
    //   return text;
    // }),
    // submitButton: Ember.computed(function() {
    //   let submit = 'buttonClick';
    //   console.log('current_route');
    //   console.log(this.get('applicationController.currentRouteName'));
    //   if(this.get('applicationController.currentRouteName') == 'app.submit') {
    //     submit = 'submitToCorporate';
    //   }
    //   return submit;
    // }),
    referenceLabels: [{ rCount: 'r1', title: 'Nearest Relative ', label: 'Relative\'s ' }, { rCount: 'r2', title: 'Dealer Reference #1', label: 'Reference\'s ' }, { rCount: 'r3', title: 'Dealer Reference #2', label: 'Reference\'s ' }, { rCount: 'r4', title: 'Dealer Reference #3', label: 'Reference\'s ' }],
    titleCount: 1,
    labelCount: 1,

    corp_types: [{ value: ' --Select-- ', id: 0 }, { value: 'Corporation', id: 1 }, { value: 'LLC', id: 2 }, { value: 'S-Corp', id: 3 }, { value: 'Partnership', id: 4 }, { value: 'Sole-Prop', id: 5 }],

    sales_types: [{ value: ' --Select-- ', id: 0 }, { value: 'Buy Here Pay Here', id: 1 }, { value: 'Cash', id: 2 }, { value: 'Wholesaler', id: 3 }, { value: 'Retail', id: 4 }],

    purchase_places: [{ label: 'Auctions', value: 1, isSelected: false }, { label: 'Wholesalers', value: 2, isSelected: false }, { label: 'Other', value: 3, isSelected: false }],

    insurance_coverage: [{ id: 0, value: ' --Select-- ' }, { id: 1, value: 'Full Coverage' }, { id: 2, value: 'Liability' }],

    app_terms: [{ id: 1, value: 'Yes, I Agree' }, { id: 0, value: 'No, I Do Not Agree' }],

    app_referral_types: [{ id: 0, value: ' --Select-- ' }, { id: 1, value: 'Auction' }, { id: 2, value: 'Floorplan Xpress Employee' }, { id: 3, value: 'Internet Search' }, { id: 4, value: 'Letter / Postcard in Mail' }, { id: 5, value: 'Friend / Another Dealer / Word of Mouth' }, { id: 6, value: 'State Dealer Association' }, { id: 7, value: 'Another Industry Professional' }, { id: 8, value: 'During A State Required Course' }, { id: 9, value: 'Social Media' }, { id: 10, value: 'Other' }],

    auctions: _ember['default'].computed('model.auctions', function () {
      return this.get('model').get('auctions');
    }),
    wholesalers: _ember['default'].computed('model.wholesalers', function () {
      return this.get('model').get('wholesalers');
    }),
    others: _ember['default'].computed('model.others', function () {
      return this.get('model').get('others');
    }),
    observeSession: (function () {
      this.send("sessionChanged");
    }).observes("session.isAuthenticated"),
    // observeRoute: function() {
    //   if(this.get('applicationController.currentRouteName') == 'app.submit') {
    //     this.set('submitText', 'Submit');
    //     this.set('submitButton', 'submitToCorporate');
    //   }
    // }.observes('applicationController.currentRouteName'),
    // find_current_page: Ember.computed('current_page', function() {
    //   alert('here ' + this.get('current_page'));
    //   var page = this.get('current_page');
    //   switch(page) {
    //     case 2:
    //       this.set('places', true);
    //       break;
    //     case 3:
    //       this.setProperties({'places': true, 'dealership': true});
    //       break;
    //     case 4:
    //       this.setProperties({
    //         'places': true, 'dealership': true, 'financials': true
    //       });
    //       break;
    //     case 5:
    //       this.setProperties({
    //         'places': true, 'dealership': true, 'financials': true, 'people': true
    //       });
    //       break;
    //     case 6:
    //       this.setProperties({
    //         'places': true, 'dealership': true, 'financials': true,
    //         'people': true, 'references': true
    //       });
    //       break;
    //     default:
    //       break;
    //   }
    // }),

    actions: {
      updateLotsSelect: function updateLotsSelect(value) {

        // this.set('selected_count', value);
        return true;
      },
      purchase_radio_click: function purchase_radio_click(value) {
        switch (value.value) {
          case 1:
            switch (value.isSelected) {
              case false:
                this.set('auctionChecked', true);
                break;
              case true:
                this.set('auctionChecked', false);
                break;
              default:
                break;
            }
            break;
          case 2:
            switch (value.isSelected) {
              case false:
                this.set('wholesalerChecked', true);
                break;
              case true:
                this.set('wholesalerChecked', false);
                break;
              default:
                break;
            }
            break;
          case 3:
            switch (value.isSelected) {
              case false:
                this.set('otherChecked', true);
                break;
              case true:
                this.set('otherChecked', false);
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      },
      add_auction: function add_auction(value) {
        this.get('auctions').pushObject(value);
      },
      // add_wholesaler: function(value) {
      //   this.get('wholesalers').pushObject(this.get('add_auction_2'));
      //   this.set('add_auction_2', '');

      // },
      // add_other: function(value) {
      //   this.get('others').pushObject(this.get('add_auction_3'));
      //   this.set('add_auction_3', '');

      // },
      // addPurchasePlace: function(value) {
      //   console.log(value);
      //   let auctions = this.get('auctions');
      //   console.log($('.purchase_places').val());
      //   auctions.pushObject($('.purchase_places').val());
      //   // let onfocus = "";
      //   // let newTextField = "<br><input class='purchase_places'>";
      //   // console.log(newTextField);
      //   // // let previous = $('.purchase_places')
      //   // $('.purchase_places_div').append(newTextField);
      //   // let current = $('.purchase_places_div .purchase_places').last();
      //   // console.log('before current');
      //   //       console.log(Ember.String.htmlSafe('{{action "addPurchasePlace"}}').toHTML());
      //   // current.on('focus', Ember.String.htmlSafe('{{action "addPurchasePlace"}}').toHTML());

      // },
      // getPurchasePlace: function(value) {
      //   console.log('in getPurchasePlace');
      //   let last = document.getElementsByClassName("purchase_places")[0];
      //   console.log('test ' + last.val());

      //   // let value = $('.purchase_places_div').
      // },
      save: function save(changeset, saveType) {
        var _this = this;

        var saveEvent = true;
        if (~event.srcElement.id.indexOf('loan_request') || ~event.srcElement.id.indexOf('password') || ~event.srcElement.id.indexOf('email') || ~event.srcElement.id.indexOf('dealership_name') || ~event.srcElement.id.indexOf('dba') || ~event.srcElement.id.indexOf('app_sig')) {
          saveEvent = false;
        }

        // document.getElementById('canvasImg').src = dataURL;
        // let changeset = this.get('changeset');
        // console.log('in  this app save');
        // console.log(changeset);
        // console.log(this.get('session.isAuthenticated'));
        // if(!this.get('session.isAuthenticated')) {
        //   let email = changeset.get('email');
        //   let password = changeset.get('password');
        //   let credentials = this.getProperties('identification', 'password'),
        //     authenticator = 'authenticator:jwt';
        //   credentials['identification'] = email;
        //   credentials['password'] = password;
        //   console.log('credentials');
        //   console.log(credentials);
        //   if(email && password) {
        //     console.log('before authentication');
        //     this.get('session').authenticate(authenticator,
        //       credentials).then(() => {
        //         console.log('in authenticator');
        //     // this.attrs.redirectAfterLogin;
        //     });
        //   }
        // }

        // let wholesaler_array = this.get('wholesalers');
        // let wholesaler_string = '';
        // for(let x = 0; x < wholesaler_array.length; x++) {
        //   wholesaler_string += wholesaler_array[x];
        //   if(x < wholesaler_array.length - 1) {
        //     wholesaler_string += ', ';
        //   }
        // }
        // let other_array = this.get('others');
        // let other_string = '';
        // for(let x = 0; x < other_array.length; x++) {
        //   other_string += other_array[x];
        //   if(x < other_array.length - 1) {
        //     other_string += ', ';
        //   }
        // }

        // changeset.set('app_signature', dataURL);

        // changeset.set('auction_2', wholesaler_string);
        // changeset.set('auction_3', other_string);
        if (saveEvent) {
          var signatures = this.get('signatures');
          var canvas = null;
          var dataURL = null;
          var sig = null;
          for (var x = 0; x < signatures.get('length'); x++) {
            canvas = document.querySelector('#app_sig' + signatures[x].id + ' canvas');
            if (canvas) {
              dataURL = canvas.toDataURL();
              sig = this.get('signatures').objectAt(x);
              _ember['default'].set(sig, 'dataURL', dataURL);
            }
          }

          var auction_array = this.get('auctions');
          var auction_string = '';
          for (var x = 0; x < auction_array.length; x++) {
            auction_string += auction_array[x];
            if (x < auction_array.length - 1) {
              auction_string += ', ';
            }
          }

          changeset.set('auction_1', auction_string);
          changeset.set('app_signatures', signatures);
          if (saveType == 'submitToCorporate') {
            changeset.set('submit_to_corporate', true);
          }
          console.log('in save');
          console.log(changeset.get('submit_to_corporate'));
          changeset.save()['catch'](function (error) {
            _this.set('errorMessage', error);
            console.log(_this.get('errorMessage'));
          }).then(function () {
            if (!_this.get('session.isAuthenticated')) {
              var email = changeset.get('email');
              var password = changeset.get('password');
              console.log(email);
              console.log(password);
              if (email && password) {
                var credentials = _this.getProperties('identification', 'password'),
                    authenticator = 'authenticator:jwt';
                credentials['identification'] = email;
                credentials['password'] = password;
                _this.get('session').authenticate(authenticator, credentials);
              }
            }
          }).then(function () {
            if (saveType == 'buttonClick') {
              _this.get('target.router').refresh();
              var next = _this.get('target').currentPath;
              switch (next) {
                case 'app.dealership':
                  next = 'app.dealer-places';
                  break;
                case 'app.dealer-places':
                  next = 'app.dealer-financials';
                  break;
                case 'app.dealer-financials':
                  next = 'app.people';
                  break;
                case 'app.people':
                  next = 'app.references';
                  break;
                case 'app.references':
                  next = 'app.submit';
                  break;
                default:
                  break;
              }
              _this.transitionToRoute(next);
            } else if (saveType == 'submitToCorporate') {
              var next = 'app.submitted';
            }
          });
        }
      },
      // redirectAfterLogin: function() {
      //   console.log('in redirectAfterLogin');
      //   console.log(Ember.getOwner(this).lookup('controller:application').currentPath);
      //   this.get('target.router').refresh()
      // },
      rollback: function rollback(changeset) {
        changeset.rollback();
      },
      sessionChanged: function sessionChanged() {
        console.log(this.get('target'));
        var dealerPlacesController = this.get('dealerPlacesController');
        // dealerPlacesController.set('refresh', true);
        console.log(this.get('target').router.refresh());
      },
      sig_reset: function sig_reset(index) {
        var sig = this.get('signatures').objectAt(index);
        _ember['default'].set(sig, 'signature', _ember['default'].A());
      },
      sig_undo: function sig_undo(index) {
        var sig = this.get('signatures').objectAt(index);
        var lastNewLine = undefined;
        sig.signature.forEach(function (item, index) {
          if (item[0] === 1) {
            lastNewLine = index;
          }
        });
        _ember['default'].set(sig, 'signature', _ember['default'].A(sig.signature.slice(0, lastNewLine)));
      },
      signature_capture: function signature_capture(person_id) {
        console.log(person_id);
      }
    }

  });
});