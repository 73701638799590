define('dealer-portal/helpers/data-translate', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Helper.extend({
    i18n: _ember['default'].inject.service(),

    compute: function compute(params) {
      var i18n = _ember['default'].get(this, 'i18n');
      var locale = i18n.get('locale');

      var _params = _slicedToArray(params, 2);

      var model = _params[0];
      var attribute = _params[1];

      var value = model.get(attribute + locale.capitalize());
      if (value) {
        return value;
      } else {
        return model.get(attribute);
      }
    },

    _recomputeOnLocaleChange: _ember['default'].observer('i18n.locale', function () {
      this.recompute();
    })
  });
});