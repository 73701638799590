define('dealer-portal/helpers/substr', ['exports', 'ember-string-helpers/helpers/substr'], function (exports, _emberStringHelpersHelpersSubstr) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberStringHelpersHelpersSubstr['default'];
    }
  });
  Object.defineProperty(exports, 'substr', {
    enumerable: true,
    get: function get() {
      return _emberStringHelpersHelpersSubstr.substr;
    }
  });
});