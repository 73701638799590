define("dealer-portal/templates/app/submit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 2
            },
            "end": {
              "line": 32,
              "column": 2
            }
          },
          "moduleName": "dealer-portal/templates/app/submit.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    Signature for ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "id", "canvasImg");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("Reset All");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("Undo Last");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [7]);
          var element2 = dom.childAt(fragment, [9]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[3] = dom.createElementMorph(element1);
          morphs[4] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["content", "person.first_name", ["loc", [null, [24, 21], [24, 42]]], 0, 0, 0, 0], ["content", "person.last_name", ["loc", [null, [24, 43], [24, 63]]], 0, 0, 0, 0], ["inline", "signature-pad", [], ["color", "black", "value", ["subexpr", "get", [["subexpr", "get-array-element", [["get", "signatures", ["loc", [null, [25, 64], [25, 74]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [25, 75], [25, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 45], [25, 81]]], 0, 0], "signature"], [], ["loc", [null, [25, 40], [26, 18]]], 0, 0], "height", ["subexpr", "@mut", [["get", "height", ["loc", [null, [26, 26], [26, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "app_sig", "id", ["subexpr", "concat", ["app_sig", ["get", "person.id", ["loc", [null, [26, 70], [26, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 52], [26, 80]]], 0, 0], "width", ["subexpr", "@mut", [["get", "width", ["loc", [null, [27, 12], [27, 17]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [25, 4], [27, 19]]], 0, 0], ["element", "action", ["sig_reset", ["get", "index", ["loc", [null, [29, 33], [29, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 12], [29, 40]]], 0, 0], ["element", "action", ["sig_undo", ["get", "index", ["loc", [null, [30, 32], [30, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 12], [30, 39]]], 0, 0]],
        locals: ["person", "index"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 6
          }
        },
        "moduleName": "dealer-portal/templates/app/submit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "submit_container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        dom.setAttribute(el2, "class", "submit-terms-conditions-title");
        var el3 = dom.createTextNode("Terms and Conditions:");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        dom.setAttribute(el2, "class", "submit-terms-conditions");
        var el3 = dom.createTextNode("\n    Everything that I have stated in this application is correct and true to the\n    best of my knowledge. I understand that you will retain this application\n    whether or not it is approved. I am informed that you or your agent may\n    request a consumer report (credit report) in connection with this application,\n    and that, if I ask you, you will inform me of the consumer reporting agency\n    that furnished the report. I am also informed that subsequent reports may be\n    requested, or used, in connection with any update, renewal or extension of the\n    credit applied for. You are authorized to check my credit investigation and\n    employment history as well as any other credit investigation you deem\n    necessary and to answer questions about you credit experience with me. I also\n    authorize you to contact any of my trade references/creditors for the purpose\n    of among other things, obtaining inter-creditor agreements.\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element3, 5, 5);
        morphs[1] = dom.createMorphAt(element3, 7, 7);
        morphs[2] = dom.createMorphAt(element3, 9, 9);
        return morphs;
      },
      statements: [["inline", "em-select", [], ["property", "app_terms", "id", "appTerms", "class", "app_input", "label", "Do You Agree To These Terms and Conditions:", "content", ["subexpr", "@mut", [["get", "app_terms", ["loc", [null, [19, 12], [19, 21]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 2], [19, 23]]], 0, 0], ["inline", "em-select", [], ["property", "app_referral_type", "id", "app_referral_type", "class", "app_input", "label", "Where Did You Hear About Us?:", "content", ["subexpr", "@mut", [["get", "app_referral_types", ["loc", [null, [22, 12], [22, 30]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 2], [22, 32]]], 0, 0], ["block", "each", [["get", "model.dealer.people", ["loc", [null, [23, 10], [23, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [23, 2], [32, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});