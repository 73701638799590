define('dealer-portal/routes/branches/index', ['exports', 'ember', 'dealer-portal/mixins/reset-scroll-position'], function (exports, _ember, _dealerPortalMixinsResetScrollPosition) {
  exports['default'] = _ember['default'].Route.extend({
    maps: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        branches: this.store.query('branch', {
          filter: { scope: 'open_branch' }
        }),
        corporate: this.store.query('branch', {
          filter: { scope: 'corporate' }
        }),
        isIndex: 'true',
        isBranchIndex: 'true'
      });
    },

    // geocoded: ''
    actions: {
      didTransition: function didTransition(transition) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          var map = this.get('maps').get('mapUtil').get('map');
          map.setZoom(4.495);
        });
      },
      redirectToShow: function redirectToShow(branchId, markers, branchName) {
        return regeneratorRuntime.async(function redirectToShow$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get('maps').placeMarkers());

            case 2:
              this.transitionTo('branches.show', branchId);
              // $('body').removeClass('modal-open');
              // $('.modal-backdrop').removeClass('in').addClass('out');

            case 3:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});

// let places = this.get('maps').places;
// console.log('test ' + places[0].address1);