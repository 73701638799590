define('dealer-portal/components/dealer-reference', ['exports', 'ember', 'dealer-portal/validations/reference', 'ember-changeset-validations', 'ember-changeset'], function (exports, _ember, _dealerPortalValidationsReference, _emberChangesetValidations, _emberChangeset) {
  exports['default'] = _ember['default'].Component.extend({
    // relative_title: 'Nearest Relative',
    // relative_label: 'Relative\'s',
    // reference_title: 'Dealer Reference',
    // reference_label: 'Reference\'s',
    // r_count: 'r' + count,
    // r_count_name: '',
    // r_count_address: '',
    // r_count_city: '',
    // r_count_state: '',
    // r_count_zip: '',
    // r_count_phone: '',
    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('model');
      this.changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_dealerPortalValidationsReference['default']));
    },

    states: [{ id: '', value: '' }, { id: 'AL', value: 'AL' }, { id: 'AK', value: 'AK' }, { id: 'AZ', value: 'AZ' }, { id: 'AR', value: 'AR' }, { id: 'CA', value: 'CA' }, { id: 'CO', value: 'CO' }, { id: 'CT', value: 'CT' }, { id: 'DE', value: 'DE' }, { id: 'DC', value: 'DC' }, { id: 'FL', value: 'FL' }, { id: 'GA', value: 'GA' }, { id: 'HI', value: 'HI' }, { id: 'ID', value: 'ID' }, { id: 'IL', value: 'IL' }, { id: 'IN', value: 'IN' }, { id: 'IA', value: 'IA' }, { id: 'KS', value: 'KS' }, { id: 'KY', value: 'KY' }, { id: 'LA', value: 'LA' }, { id: 'ME', value: 'ME' }, { id: 'MD', value: 'MD' }, { id: 'MA', value: 'MA' }, { id: 'MI', value: 'MI' }, { id: 'MN', value: 'MN' }, { id: 'MS', value: 'MS' }, { id: 'MO', value: 'MO' }, { id: 'MT', value: 'MT' }, { id: 'NE', value: 'NE' }, { id: 'NV', value: 'NV' }, { id: 'NH', value: 'NH' }, { id: 'NJ', value: 'NJ' }, { id: 'NM', value: 'NM' }, { id: 'NY', value: 'NY' }, { id: 'NC', value: 'NC' }, { id: 'ND', value: 'ND' }, { id: 'OH', value: 'OH' }, { id: 'OK', value: 'OK' }, { id: 'OR', value: 'OR' }, { id: 'PA', value: 'PA' }, { id: 'RI', value: 'RI' }, { id: 'SC', value: 'SC' }, { id: 'SD', value: 'SD' }, { id: 'TN', value: 'TN' }, { id: 'TX', value: 'TX' }, { id: 'US', value: 'US' }, { id: 'UT', value: 'UT' }, { id: 'VT', value: 'VT' }, { id: 'VA', value: 'VA' }, { id: 'WA', value: 'WA' }, { id: 'WV', value: 'WV' }, { id: 'WI', value: 'WI' }, { id: 'WY', value: 'WY' }, { id: 'OT', value: 'OT' }]

  });
});