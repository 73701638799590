define('dealer-portal/controllers/access-home/user-management', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    userLog: _ember['default'].inject.service(),
    showUserLock: true,

    headers: _ember['default'].computed(function () {
      var headers = {};
      this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      return headers;
    }),

    actions: {
      sendUserInvite: function sendUserInvite(changeset) {
        console.log('controller');
        console.log(changeset);
        var self = this;
        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('dealer-users/invite-new-user'), {
          method: 'post',
          headers: self.get('headers'),
          data: {
            email: changeset.get('email'),
            level: changeset.get('level') || 'sales',
            dealer_id: this.get('session').get('data').authenticated.dealerId
          }
        }).then(function (response) {});
      },

      lockAccount: function lockAccount(dealer_user_id, status) {
        var _this = this;

        var self = this;
        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('dealer-users/lock'), {
          method: 'put',
          headers: self.get('headers'),
          data: {
            id: dealer_user_id,
            status: status
          }
        }).then(function (response) {
          _this.toast.success('User Access Updated');
          _this.transitionToRoute('access-home.user-management-update');
          // this.set('showUserLock', true);
          // console.log('status')
          // console.log(status)
          // console.log(parseInt(response['data']['attributes']['status']))
        });
      },

      resetPassword: function resetPassword(dealerId) {
        var component = this;

        new _dealerPortalAdaptersApplication['default']().simple(this.get('ajax'), this.get('session'), this.get('userLog'), 'dealer-users/' + dealerId + '/reset_password', 'POST').then(function () {
          component.toast.info('Reset password email sent');
        });
      },

      deleteUser: function deleteUser(event) {
        var _this2 = this;

        var self = this;
        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('dealer-users/delete'), {
          method: 'put',
          headers: self.get('headers'),
          data: {
            id: $('[name="' + event.target.name + '"]').attr('dealerUserId')
          }
        }).then(function (response) {
          _this2.toast.success('User Access Updated');
          _this2.transitionToRoute('access-home.user-management-update');
        });
      }
    }
  });
});