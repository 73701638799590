define("dealer-portal/templates/components/previous-inventory-list", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "dealer-portal/templates/components/previous-inventory-list.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("thead");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "colspan", "7");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("form");
          dom.setAttribute(el4, "class", "previous_inventory_date_range");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("input");
          dom.setAttribute(el5, "type", "date");
          dom.setAttribute(el5, "id", "from-date");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("input");
          dom.setAttribute(el5, "type", "date");
          dom.setAttribute(el5, "id", "to-date");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("input");
          dom.setAttribute(el5, "type", "submit");
          dom.setAttribute(el5, "class", "date-search-button");
          dom.setAttribute(el5, "value", "Search Dates");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("td");
          dom.setAttribute(el3, "colspan", "2");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          dom.setAttribute(el2, "class", "current-floored-list-header");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tbody");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1, 1]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(element2, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[2] = dom.createAttrMorph(element3, 'value');
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
          morphs[4] = dom.createAttrMorph(element4, 'value');
          morphs[5] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
          morphs[6] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
          morphs[7] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["searchDates"], ["on", "submit"], ["loc", [null, [5, 52], [6, 23]]], 0, 0], ["inline", "t", ["reports.from"], [], ["loc", [null, [7, 17], [7, 37]]], 0, 0], ["attribute", "value", ["get", "fromDate", ["loc", [null, [8, 37], [8, 45]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["reports.to"], [], ["loc", [null, [9, 17], [9, 35]]], 0, 0], ["attribute", "value", ["get", "toDate", ["loc", [null, [10, 37], [10, 43]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "sf.filter", [], ["placeholder", ["subexpr", "t", ["currentUnitPage.search"], [], ["loc", [null, [15, 32], [15, 60]]], 0, 0], "withClearField", true], ["loc", [null, [15, 8], [15, 82]]], 0, 0], ["inline", "sf.headings", [], ["headings", ["subexpr", "@mut", [["get", "inventoryHeadings", ["loc", [null, [19, 29], [19, 46]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 6], [19, 48]]], 0, 0], ["inline", "sf.rows", [], ["rows", ["subexpr", "@mut", [["get", "inventoryRows", ["loc", [null, [23, 19], [23, 32]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [23, 4], [23, 34]]], 0, 0]],
        locals: ["sf"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 13
          }
        },
        "moduleName": "dealer-portal/templates/components/previous-inventory-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "sf-table", [], ["id", "current-floored-list"], 0, null, ["loc", [null, [1, 0], [25, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});