define('dealer-portal/controllers/units', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),

    actions: {
      amountsDueOn: function amountsDueOn() {
        return true;
      }
    }
  });
});