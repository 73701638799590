define('dealer-portal/components/ember-datepicker', ['exports', 'ember-datepicker/components/ember-datepicker'], function (exports, _emberDatepickerComponentsEmberDatepicker) {
  exports['default'] = _emberDatepickerComponentsEmberDatepicker['default'].extend({
    didInsertElement: function didInsertElement() {
      this._super();

      var picker = this.$();

      picker.parents('form').submit(function (event) {
        if ('true' === picker.data('invalid')) {
          event.preventDefault();
          alert('Date is invalid. Please fix to submit.');
          return false;
        }
      });
    },

    validate: function validate() {
      var picker = this.$();

      var str = picker.val();

      if (str.match(/(\d{2})(\d{2})(\d+)/)) {
        picker.val(str.replace(/(\d{2})(\d{2})(\d+)/, '$1/$2/$3'));
      } else if (str.match(/^(\d{2})(\d{2})$/)) {
        picker.val(str.replace(/(\d{2})(\d{2})/, '$1/$2/' + new Date().getFullYear()));
      } else if (str.match(/^(\d{1,2})\/(\d{1,2})\s*$/)) {
        picker.val(str + '/' + new Date().getFullYear());
      }

      try {
        picker.data('invalid', 'false');
        $.datepicker.parseDate('mm/dd/yy', picker.val());
        picker.datepicker('option', 'dateFormat', 'mm/dd/yy');
        picker.css({ backgroundColor: '' });
      } catch (e) {
        picker.data('invalid', 'true');
        picker.css({ backgroundColor: 'red' });
      }
    },

    change: function change() {
      this.validate();
    },

    selectDate: function selectDate(date) {
      this._super(date);
      this.validate();
    }
  });
});