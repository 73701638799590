define('dealer-portal/helpers/radio-checked', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    session: _ember['default'].inject.service(),

    compute: function compute(params) {
      var radioValue = params[0];
      var currentSelection = params[1];
      if (radioValue === currentSelection) {
        return true;
      } else {
        return false;
      }
    }
  });
});