define('dealer-portal/controllers/access-home/notification-settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),

    actions: {
      updateSettings: function updateSettings(changeset) {
        var _this = this;

        console.log('in contr ');
        console.log(changeset);
        changeset.save().then(function (dealerUser) {
          _this.toast.info('Notifications Settings Updated.');
        });
      }
    }
  });
});