define('dealer-portal/routes/access-home/reports/reserves', ['exports', 'ember', 'moment', 'accounting/format-money', 'ember-i18n'], function (exports, _ember, _moment, _accountingFormatMoney, _emberI18n) {
  exports['default'] = _ember['default'].Route.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    moment: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    controllerName: 'access-home',
    model: function model() {
      return _ember['default'].RSVP.hash({
        dealer: this.get('store').queryRecord('dealer', {
          id: this.get('session').get('data').authenticated.dealerId,
          filter: { scope: 'reserves' }
        })
      });
    },
    setupController: function setupController(controller, model) {
      var i18n = this.get('i18n');
      this._super.apply(this, arguments);
      var reservesLinesHeadings = [{ key: 'date', display: '<span class="first-heading">' + this.get('i18n').t('reports.date').string + '</span>' }, { key: 'type', display: this.get('i18n').t('reports.type').string }, { key: 'amount', display: this.get('i18n').t('reports.amount').string }, { key: 'balance', display: this.get('i18n').t('unitShowPage.balance').string }];
      controller.set('inventoryHeadings', reservesLinesHeadings);
      var reservesLinesRows = [];

      function styleAndFormatAsMoney(dollarAmount) {
        return '<span class="pull-right">' + (0, _accountingFormatMoney['default'])(dollarAmount) + '</span>';
      }
      model.dealer.get('reservesReportLines').forEach(function (line) {
        var reservesHash = {
          _id: line.id,
          date: "<span class='timestamp' style='display: none;'>" + (0, _moment['default'])(line.date).format('x') + "</span> " + (0, _moment['default'])(line.date).format('MM/DD/YYYY'),
          type: line.type,
          amount: styleAndFormatAsMoney(line.amount),
          balance: styleAndFormatAsMoney(line.balance)
        };
        reservesLinesRows.pushObject(reservesHash);
      });
      controller.set('inventoryRows', reservesLinesRows);
    }
  });
});