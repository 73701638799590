define('dealer-portal/components/app-auction-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showSecondInput: false,
    actions: {
      show_second_input: function show_second_input() {
        this.set('showSecondInput', true);
      },
      add_auction: function add_auction() {
        this.sendAction('add_auction', this.get('add_auction_1'));
        this.set('showSecondInput', false);
        this.set('add_auction_1', '');
      }
    }
  });
});