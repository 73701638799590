define('dealer-portal/components/dealer-user-lock', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    init: function init() {
      console.log('here');
      console.log(this.get('user.status'));
      this._super.apply(this, arguments);
      if (this.get('user.status') == 2) {
        window.$('#accountlocked' + this.get('dealer_user_id')).addClass('user-locked');
        window.$('#accountlocked' + this.get('dealer_user_id')).removeClass('user-not-locked');
      } else {
        window.$('#accountlocked' + this.get('dealer_user_id')).removeClass('user-locked');
        window.$('#accountlocked' + this.get('dealer_user_id')).addClass('user-not-locked');
      }
    },

    actions: {
      lockAccount: function lockAccount(status) {
        console.log('in comp');
        console.log(status);
        // this.set('showUserLock', false);
        var dealer_user_id = this.get('user.id');
        this.attrs.save(dealer_user_id, status);
      }
    }
  });
});