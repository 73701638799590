define('dealer-portal/components/mark-unsold-form', ['exports', 'ember', 'ember-changeset', 'ember-i18n', 'moment'], function (exports, _ember, _emberChangeset, _emberI18n, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('model');
      this.changeset = new _emberChangeset['default'](model);
    },

    actions: {
      submit: function submit() {
        var self = this;
        var unit = this.get('changeset');
        this.attrs.save(unit);
      },
      cancel: function cancel() {
        this.set('unsoldForm', false);
      }
    }
  });
});