define("dealer-portal/templates/access-home/bank-accounts/ach-authorization", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 6
          }
        },
        "moduleName": "dealer-portal/templates/access-home/bank-accounts/ach-authorization.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-fluid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ach-auth-details");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        var el6 = dom.createTextNode("ACH Account Authorization");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "style", "font-weight: bold;");
        var el6 = dom.createTextNode("\n          We are updating the ACH bank account information in our files. To continue\n          using Pay Now functionality, please review the bank accounts below and\n          the Pay Now terms and conditions. To use a bank account for Pay Now, you\n          must sign your name below to agree to these terms.\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        var el6 = dom.createTextNode("Bank Accounts");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0, 5, 5);
        morphs[1] = dom.createMorphAt(element0, 9, 9);
        return morphs;
      },
      statements: [["content", "ach-terms", ["loc", [null, [12, 8], [12, 21]]], 0, 0, 0, 0], ["inline", "ach-auth-form", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [14, 30], [14, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "unauthorized", ["subexpr", "@mut", [["get", "unauthorized", ["loc", [null, [14, 49], [14, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "allAuthorized", ["subexpr", "@mut", [["get", "allAuthorized", ["loc", [null, [14, 76], [14, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "save", ["subexpr", "action", ["achAuthorization"], [], ["loc", [null, [15, 15], [15, 42]]], 0, 0]], ["loc", [null, [14, 8], [15, 44]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});