define('dealer-portal/components/dealer-place', ['exports', 'ember', 'dealer-portal/validations/place', 'ember-changeset-validations', 'ember-changeset'], function (exports, _ember, _dealerPortalValidationsPlace, _emberChangesetValidations, _emberChangeset) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    maps: _ember['default'].inject.service(),
    PlaceValidations: _dealerPortalValidationsPlace['default'],

    tagName: 'div',
    classNames: ['dealer-place'],
    lat: 39.09024,
    lng: -95.912891,
    zoom: 4,
    current_marker_index: null,
    associated_marker: null,
    current_place: null,
    // markerDropped: false,
    geocodedPlace: _ember['default'].computed('geocodedPlace', function () {
      if (this.get('geolocatedPlaces') && !this.get('primaryPlaced')) {
        console.log('wtf');
        console.log(this.get('geolocatedPlaces')[0]);
        return this.get('geolocatedPlaces')[0];
      } else {
        return null;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('model');
      this.changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_dealerPortalValidationsPlace['default']));
      console.log('model length');
      console.log(model.get('length'));
      if (this.get('geocodedPlace') && !this.get('primaryPlaced')) {
        var geocoded = this.get('geocodedPlace');
        var state = geocoded.state.toUpperCase();
        this.changeset.setProperties({ address1: geocoded.address1,
          city: geocoded.city, state: state, zip: geocoded.zip });
        window.$('#stateSelect').val(state).prop('selected', true);
        this.send('check_address');
      }
    },

    // didRender() {
    //   this._super(...arguments);
    //   alert('in didRender');
    //   this.send('check_address');
    // },

    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
    county: '',
    status: '',
    primary: '',

    states: [{ id: 'AL', value: 'AL' }, { id: 'AK', value: 'AK' }, { id: 'AZ', value: 'AZ' }, { id: 'AR', value: 'AR' }, { id: 'CA', value: 'CA' }, { id: 'CO', value: 'CO' }, { id: 'CT', value: 'CT' }, { id: 'DE', value: 'DE' }, { id: 'DC', value: 'DC' }, { id: 'FL', value: 'FL' }, { id: 'GA', value: 'GA' }, { id: 'HI', value: 'HI' }, { id: 'ID', value: 'ID' }, { id: 'IL', value: 'IL' }, { id: 'IN', value: 'IN' }, { id: 'IA', value: 'IA' }, { id: 'KS', value: 'KS' }, { id: 'KY', value: 'KY' }, { id: 'LA', value: 'LA' }, { id: 'ME', value: 'ME' }, { id: 'MD', value: 'MD' }, { id: 'MA', value: 'MA' }, { id: 'MI', value: 'MI' }, { id: 'MN', value: 'MN' }, { id: 'MS', value: 'MS' }, { id: 'MO', value: 'MO' }, { id: 'MT', value: 'MT' }, { id: 'NE', value: 'NE' }, { id: 'NV', value: 'NV' }, { id: 'NH', value: 'NH' }, { id: 'NJ', value: 'NJ' }, { id: 'NM', value: 'NM' }, { id: 'NY', value: 'NY' }, { id: 'NC', value: 'NC' }, { id: 'ND', value: 'ND' }, { id: 'OH', value: 'OH' }, { id: 'OK', value: 'OK' }, { id: 'OR', value: 'OR' }, { id: 'PA', value: 'PA' }, { id: 'RI', value: 'RI' }, { id: 'SC', value: 'SC' }, { id: 'SD', value: 'SD' }, { id: 'TN', value: 'TN' }, { id: 'TX', value: 'TX' }, { id: 'US', value: 'US' }, { id: 'UT', value: 'UT' }, { id: 'VT', value: 'VT' }, { id: 'VA', value: 'VA' }, { id: 'WA', value: 'WA' }, { id: 'WV', value: 'WV' }, { id: 'WI', value: 'WI' }, { id: 'WY', value: 'WY' }, { id: 'OT', value: 'OT' }],

    property_status: [{ value: 'Own - Paid Off', id: 1 }, { value: 'Own - Under Mortgage', id: 2 }, { value: 'Rental / Lease', id: 3 }],

    property_type: [{ value: 'Dealer Lot', id: 1 }, { value: 'Dealer Home', id: 2 }, { value: 'Repair Facility', id: 3 }, { value: 'Auction', id: 4 }, { value: 'Other', id: 5 }, { value: 'Mailing', id: 6 }, { value: 'Additional Phone', id: 7 }],

    actions: {
      updateStatesSelect: function updateStatesSelect(value) {
        var place = this.get('changeset');
        var selected = value.target.value;
        _ember['default'].set(this, 'value', selected);
        _ember['default'].set(place, 'state', selected);
        this.send('check_address');
      },
      updatePropertySelect: function updatePropertySelect(value) {
        var place = this.get('place');
        _ember['default'].set(this, 'value', value);
        _ember['default'].set(place, 'status', value);
      },
      check_address: function check_address() {
        console.log('hitting check_address');
        if (!this.get('markerDropped')) {
          var place = this.get('changeset');
          var address1 = place.get('address1');
          var city = place.get('city');
          var state = place.get('state');
          var zip = place.get('zip');
          if (place.get('address1') && place.get('city') && place.get('state') && place.get('zip')) {
            var address = address1 + ', ' + city + ', ' + state + ', ' + zip;
            this.set('markerDropped', true);
            this.send('add_marker', address);
          }
        }
      },
      dataEntered: function dataEntered() {
        this.sendAction('dataEntered');
      },
      add_marker: function add_marker(address) {
        var place = this.get('changeset');
        console.log('place');
        console.log(place);
        // console.log('changeset ');
        // console.log(changeset);
        // let markers = this.get('maps').get('markers');
        var lot_count = this.get('lot_count');
        var geocodedPlace = null;
        if (!this.get('geocodedPlace') || this.get('geocodedPlace') && !this.get('primaryPlaced')) {
          geocodedPlace = this.get('geocodedPlace');
        }
        var marker = this.get('maps').addDealerPlace(place, address, lot_count, geocodedPlace);
        // this.set('current_marker_index', markers.indexOf(marker));
        // place.set('associated_marker', markers.indexOf(marker));
        // markers.pushObject(marker);
      },
      submit: function submit() {
        var place = this.get('changeset');
        // let self = this;
        if (!this.get('geocodedPlace')) {
          this.get('maps').getBranch(place.get('address1'), place.get('city'), place.get('state'), place.get('zip'), this.get('store').findAll('branch'));
        }
        if (this.get('geocodedPlace')) {
          place.set('branch', this.get('geocodedPlace').branchId);
        }
        place.set('dealerId', this.get('session').get('data').authenticated.dealerId);
        this.attrs.save(place);
        // let markers = this.get('maps').get('markers');
        // this.set('number_of_lots', markers.length + 1);
        // this.set('lot_count', markers.length + 1);
        if (this.get('lot_count') > this.get('selected_count')) {
          this.set('selected_count', this.get('lot_count'));
        }
        // Ember.$('#number_of_lots').val(this.get('selected_count'));
        // Ember.$('.primaryCheckbox').prop('checked', false);
        // Ember.$('.place_phone input').val('');
        // Ember.$('.place_fax input').val('');
      },
      rollback: function rollback() {
        var changeset = this.get('changeset');
        this.attrs.rollback(changeset);
      },
      activeMarker: function activeMarker(marker) {
        this.sendAction('activeMarker', marker);
      }
    }
  });
});