define('dealer-portal/helpers/media', ['exports', 'ember-responsive/helpers/media'], function (exports, _emberResponsiveHelpersMedia) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberResponsiveHelpersMedia['default'];
    }
  });
  Object.defineProperty(exports, 'media', {
    enumerable: true,
    get: function get() {
      return _emberResponsiveHelpersMedia.media;
    }
  });
});