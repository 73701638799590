define("dealer-portal/templates/access-home/ach-scheduling/confirm-payment", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 2
            },
            "end": {
              "line": 42,
              "column": 2
            }
          },
          "moduleName": "dealer-portal/templates/access-home/ach-scheduling/confirm-payment.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "valign", "top");
          var el3 = dom.createTextNode("\n        Coupon Applied:\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "valign", "top");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
          return morphs;
        },
        statements: [["content", "model.ach.couponRedemption.coupon_name", ["loc", [null, [39, 8], [39, 50]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 68,
            "column": 0
          }
        },
        "moduleName": "dealer-portal/templates/access-home/ach-scheduling/confirm-payment.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "accessbox");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("table");
        dom.setAttribute(el2, "border", "0");
        dom.setAttribute(el2, "cellpadding", "2");
        dom.setAttribute(el2, "cellspacing", "2");
        dom.setAttribute(el2, "width", "1000px");
        dom.setAttribute(el2, "bgcolor", "white");
        dom.setAttribute(el2, "align", "center");
        var el3 = dom.createElement("tr");
        var el4 = dom.createElement("td");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Confirm Your Payment");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("font");
        dom.setAttribute(el5, "color", "red");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("YOU MUST CONFIRM YOUR PAYMENT BELOW.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("table");
        dom.setAttribute(el5, "class", "apptext");
        dom.setAttribute(el5, "width", "100%");
        dom.setAttribute(el5, "border", "0");
        dom.setAttribute(el5, "cellpadding", "2");
        dom.setAttribute(el5, "cellspacing", "2");
        dom.setAttribute(el5, "align", "center");
        var el6 = dom.createTextNode("\n  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "valign", "top");
        var el8 = dom.createTextNode("\n      Payment Date:\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "valign", "top");
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "valign", "top");
        var el8 = dom.createTextNode("\n      Your Payment Will Be For:\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "valign", "top");
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "valign", "top");
        var el8 = dom.createTextNode("\n      To Be Paid From:\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      Routing Number: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("br");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n      Account Number: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "colspan", "2");
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8, "class", "form-error");
        var el9 = dom.createTextNode("\n        Please do not use the browser back button. If you wish to change details of this payment,\n        please choose 'Cancel This Payment' and set up a new payment.");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n        If you use the back button, you will not be able to submit the payment.\n      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("button");
        dom.setAttribute(el9, "type", "submit");
        var el10 = dom.createTextNode("Process This Payment");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("button");
        dom.setAttribute(el9, "type", "submit");
        var el10 = dom.createTextNode("Cancel This Payment");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 0, 0, 5]);
        var element1 = dom.childAt(element0, [5, 3]);
        var element2 = dom.childAt(element0, [11]);
        var element3 = dom.childAt(element2, [1, 1, 1]);
        var element4 = dom.childAt(element2, [3, 1, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]), 1, 1);
        morphs[1] = dom.createUnsafeMorphAt(dom.childAt(element0, [3, 3]), 1, 1);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 4, 4);
        morphs[4] = dom.createMorphAt(element1, 7, 7);
        morphs[5] = dom.createMorphAt(element0, 7, 7);
        morphs[6] = dom.createElementMorph(element3);
        morphs[7] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["inline", "moment-format", [["get", "model.ach.actualTransferDate", ["loc", [null, [12, 22], [12, 50]]], 0, 0, 0, 0], "l"], [], ["loc", [null, [12, 6], [12, 56]]], 0, 0], ["content", "model.ach.paymentFor", ["loc", [null, [20, 6], [20, 32]]], 0, 0, 0, 0], ["content", "model.ach.accountNameForDealer", ["loc", [null, [28, 6], [28, 40]]], 0, 0, 0, 0], ["content", "model.ach.safeRt", ["loc", [null, [29, 22], [29, 42]]], 0, 0, 0, 0], ["content", "model.ach.safeAct", ["loc", [null, [30, 22], [30, 43]]], 0, 0, 0, 0], ["block", "if", [["get", "model.ach.couponRedemption", ["loc", [null, [33, 8], [33, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [33, 2], [42, 9]]]], ["element", "action", ["processPayment", ["get", "model.ach.id", ["loc", [null, [55, 56], [55, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 30], [55, 70]]], 0, 0], ["element", "action", ["cancelPayment", ["get", "model.ach.id", ["loc", [null, [60, 55], [60, 67]]], 0, 0, 0, 0]], [], ["loc", [null, [60, 30], [60, 69]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});