define('dealer-portal/routes/access-home/reports/paid', ['exports', 'ember', 'moment', 'accounting/format-money', 'ember-i18n'], function (exports, _ember, _moment, _accountingFormatMoney, _emberI18n) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    queryParams: {
      fromDate: {
        refreshModel: false
      }
    },
    fromDate: null,
    toDate: null,

    controllerName: 'access-home',
    model: function model(params) {
      var from = null;
      var to = null;
      this.setProperties({ fromDate: params['fromDate'], toDate: params['toDate'] });
      if (params['fromDate'] == null) {
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear() - 1;
        from = mm + '/' + dd + '/' + yyyy;
        this.set('fromDate', (0, _moment['default'])(from).format('YYYY-MM-DD'));
      }
      if (params['toDate'] == null) {
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear();
        to = mm + '/' + dd + '/' + yyyy;
        this.set('toDate', (0, _moment['default'])(to).format('YYYY-MM-DD'));
      }
      return _ember['default'].RSVP.hash({
        units: this.store.query('unit', {
          filter: { scope: 'paid_off', fromDate: this.get('fromDate'), toDate: this.get('toDate') }
        }),
        dealer: this.store.find('dealer', this.get('session').get('data').authenticated.dealerId)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      function styleAndFormatAsMoney(dollarAmount) {
        return (0, _accountingFormatMoney['default'])(dollarAmount);
      }

      var i18n = this.get('i18n');

      var previousInventoryHeadings = [{ key: 'serialNumber', display: this.get('i18n').t('reports.serialNumber').string }, { key: 'stockNumber', display: this.get('i18n').t('reports.stockNumber').string }, { key: 'lineType', display: this.get('i18n').t('reports.lineType').string }, { key: 'name', display: this.get('i18n').t('reports.car').string }, { key: 'payoffOn', display: this.get('i18n').t('currentUnitPage.paidOffOn').string }, { key: 'paidAmount', display: this.get('i18n').t('reports.paidAmount').string }, { key: 'paidReserves', display: this.get('i18n').t('currentUnitPage.addedToReserves').string }];

      controller.set('inventoryHeadings', previousInventoryHeadings);
      var previousInventoryRows = [];
      model.units.forEach(function (unit) {
        var unitHash = {
          serialNumber: unit.get('serialNumber'),
          stockNumber: unit.get('stockNumber'),
          lineType: unit.get('lineType'),
          name: "<a href='/access-home/" + unit.get('id') + "'>" + unit.get('name') + "</a>",
          payoffOn: "<span class='timestamp' style='display: none;'>" + (0, _moment['default'])(unit.get('payoffOn')).format('x') + "</span> " + (0, _moment['default'])(unit.get('payoffOn')).format('MM/DD/YYYY'),
          paidAmount: styleAndFormatAsMoney(unit.get('paidAmount')),
          paidReserves: styleAndFormatAsMoney(unit.get('paidReserves'))
        };
        previousInventoryRows.pushObject(unitHash);
      });
      controller.set('inventoryRows', previousInventoryRows);
      if (this.get('fromDate') != controller.get('fromDate')) {
        controller.set('fromDate', this.get('fromDate'));
      }
      if (this.get('toDate') != controller.get('toDate')) {
        controller.set('toDate', this.get('toDate'));
      }
      controller.set('transitionFrom', 'reports.paid');
    }
  });
});