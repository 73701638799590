define('dealer-portal/routes/units', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'dealer-portal/adapters/application'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    model: function model() {
      return this.get('store').findAll('units');
    },

    actions: {
      amountsDueOn: function amountsDueOn() {
        var headers = {};
        this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });

        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('units/7106130/amounts_due_on'), {
          headers: headers,
          method: 'GET',
          data: {
            dealer_id: 10220,
            id: 7106130,
            amounts_due_on_date: '2018/03/01'
          }
        }).then(function (response) {
          console.log('response ' + response);
        });
      }
    }
  });
});