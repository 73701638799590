define('dealer-portal/controllers/auctions/index', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Controller.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    maps: _ember['default'].inject.service(),

    city: '',
    state: '',
    zip: '',
    radius: 25,
    auctionResults: [],
    searchResults: false,

    statesSelect: [{ id: 'AL', value: 'Alabama' }, { id: 'AK', value: 'Alaska' }, { id: 'AZ', value: 'Arizona' }, { id: 'AR', value: 'Arkansas' }, { id: 'CA', value: 'California' }, { id: 'CO', value: 'Colorado' }, { id: 'CT', value: 'Connecticut' }, { id: 'DE', value: 'Delaware' }, { id: 'DC', value: 'District of Columbia' }, { id: 'FL', value: 'Florida' }, { id: 'GA', value: 'Georgia' }, { id: 'HI', value: 'Hawaii' }, { id: 'ID', value: 'Idaho' }, { id: 'IL', value: 'Illinois' }, { id: 'IN', value: 'Indiana' }, { id: 'IA', value: 'Iowa' }, { id: 'KS', value: 'Kansas' }, { id: 'KY', value: 'Kentucky' }, { id: 'LA', value: 'Louisiana' }, { id: 'ME', value: 'Maine' }, { id: 'MD', value: 'Maryland' }, { id: 'MA', value: 'Massachusetts' }, { id: 'MI', value: 'Michigan' }, { id: 'MN', value: 'Minnesota' }, { id: 'MS', value: 'Mississippi' }, { id: 'MO', value: 'Missouri' }, { id: 'MT', value: 'Montana' }, { id: 'NE', value: 'Nebraska' }, { id: 'NV', value: 'Nevada' }, { id: 'NH', value: 'New Hampshire' }, { id: 'NJ', value: 'New Jersey' }, { id: 'NM', value: 'New Mexico' }, { id: 'NY', value: 'New York' }, { id: 'NC', value: 'North Carolina' }, { id: 'ND', value: 'North Dakota' }, { id: 'OH', value: 'Ohio' }, { id: 'OK', value: 'Oklahoma' }, { id: 'OR', value: 'Oregon' }, { id: 'PA', value: 'Pennsylvania' }, { id: 'RI', value: 'Rhode Island' }, { id: 'SC', value: 'South Carolina' }, { id: 'SD', value: 'South Dakota' }, { id: 'TN', value: 'Tennessee' }, { id: 'TX', value: 'Texas' }, { id: 'UT', value: 'Utah' }, { id: 'VT', value: 'Vermont' }, { id: 'VA', value: 'Virginia' }, { id: 'WA', value: 'Washington' }, { id: 'WV', value: 'West Virginia' }, { id: 'WI', value: 'Wisconsin' }, { id: 'WY', value: 'Wyoming' }],

    actions: {
      updateAuctionSelect: function updateAuctionSelect(value) {
        var selected = value.target.value;
        _ember['default'].set(this, 'value', selected);
      },
      search: function search(changeset, searchByAddress) {
        var _this = this;

        this.set('searchResults', false);
        var city = changeset.get('city') || '';
        var state = changeset.get('state') || '';
        var zip = changeset.get('zip') || '';
        // let radius = changeset.get('radius') ? changeset.get('radius') : 25;
        var data = {};
        data['radius'] = changeset.get('radius') ? changeset.get('radius') : 50;
        if (city != null) {
          data['city'] = city;
        } else {
          data['city'] = '';
        }
        if (state != null) {
          data['state'] = state;
        } else {
          data['state'] = '';
        }
        if (zip != null) {
          data['zip'] = zip;
        } else {
          data['zip'] = '';
        }
        if (!searchByAddress) {
          var maps = this.get('maps');
          var result = maps.geolocateByIp();
          data['address1'] = result.address1;
          data['city'] = result.city;
          data['state'] = result.state;
          data['zip'] = result.zip;
        }
        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('auctions/search'), {
          method: 'GET',
          data: { data: data }
        }).then(function (response) {
          var auctions = response.data;
          // let address = city + ' ' + state + ' ' + zip;
          _this.get('maps').auctionSearch(auctions);
          // let model = this.get('model');

          _this.set('auctionResults', _ember['default'].A(auctions));
          _this.set('searchResults', true);
        });
      }
    }
  });
});