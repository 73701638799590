define('dealer-portal/components/app-simple-form', ['exports', 'ember', 'dealer-portal/validations/app', 'ember-changeset-validations', 'ember-changeset', 'moment', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalValidationsApp, _emberChangesetValidations, _emberChangeset, _moment, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    AppValidations: _dealerPortalValidationsApp['default'],
    showTerms: false,
    showDateError: false,
    agreeConditions: null,
    licensedDealer: false,
    signatureMissingError: false,
    insuranceCoverageElected: true,
    sigPresent: false,
    height: 200,
    weight: 1,
    width: 600,
    setBirthdate: null,
    showConditionsError: _ember['default'].computed('agreeConditions', function () {
      if (this.get('agreeConditions') != null) {
        return this.get('agreeConditions') ? false : true;
      }
    }),

    headers: _ember['default'].computed(function () {
      var headers = {};
      this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      return headers;
    }),

    signature: _ember['default'].computed(function () {
      return _ember['default'].A();
    }),
    stringifiedSignature: _ember['default'].computed('signature.[]', function () {
      return JSON.stringify(this.get('signature'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('model');
      this.changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_dealerPortalValidationsApp['default']), _dealerPortalValidationsApp['default']);
      window.$('simple-app-submit').attr('disabled', 'disabled');
    },

    statesSelect: [{ id: '', value: '' }, { id: 'AL', value: 'AL' }, { id: 'AK', value: 'AK' }, { id: 'AZ', value: 'AZ' }, { id: 'AR', value: 'AR' }, { id: 'CA', value: 'CA' }, { id: 'CO', value: 'CO' }, { id: 'CT', value: 'CT' }, { id: 'DE', value: 'DE' }, { id: 'DC', value: 'DC' }, { id: 'FL', value: 'FL' }, { id: 'GA', value: 'GA' }, { id: 'HI', value: 'HI' }, { id: 'ID', value: 'ID' }, { id: 'IL', value: 'IL' }, { id: 'IN', value: 'IN' }, { id: 'IA', value: 'IA' }, { id: 'KS', value: 'KS' }, { id: 'KY', value: 'KY' }, { id: 'LA', value: 'LA' }, { id: 'ME', value: 'ME' }, { id: 'MD', value: 'MD' }, { id: 'MA', value: 'MA' }, { id: 'MI', value: 'MI' }, { id: 'MN', value: 'MN' }, { id: 'MS', value: 'MS' }, { id: 'MO', value: 'MO' }, { id: 'MT', value: 'MT' }, { id: 'NE', value: 'NE' }, { id: 'NV', value: 'NV' }, { id: 'NH', value: 'NH' }, { id: 'NJ', value: 'NJ' }, { id: 'NM', value: 'NM' }, { id: 'NY', value: 'NY' }, { id: 'NC', value: 'NC' }, { id: 'ND', value: 'ND' }, { id: 'OH', value: 'OH' }, { id: 'OK', value: 'OK' }, { id: 'OR', value: 'OR' }, { id: 'PA', value: 'PA' }, { id: 'RI', value: 'RI' }, { id: 'SC', value: 'SC' }, { id: 'SD', value: 'SD' }, { id: 'TN', value: 'TN' }, { id: 'TX', value: 'TX' }, { id: 'US', value: 'US' }, { id: 'UT', value: 'UT' }, { id: 'VT', value: 'VT' }, { id: 'VA', value: 'VA' }, { id: 'WA', value: 'WA' }, { id: 'WV', value: 'WV' }, { id: 'WI', value: 'WI' }, { id: 'WY', value: 'WY' }, { id: 'OT', value: 'OT' }],
    // app_terms: [
    //   { id: 1, value: 'Yes, I Agree' },
    //   { id: 0, value: 'No, I Do Not Agree' }
    // ],

    app_referral_types: [{ id: 0, value: ' --Select-- ' }, { id: 1, value: 'Auction' }, { id: 2, value: 'Floorplan Xpress Employee' }, { id: 3, value: 'Internet Search' }, { id: 4, value: 'Letter / Postcard in Mail' }, { id: 5, value: 'Friend / Another Dealer / Word of Mouth' }, { id: 6, value: 'State Dealer Association' }, { id: 7, value: 'Another Industry Professional' }, { id: 8, value: 'During A State Required Course' }, { id: 9, value: 'Social Media' }, { id: 10, value: 'Other' }],

    actions: {
      validateDate: function validateDate() {
        var date = window.$('#birth-date').val();
        if ((0, _moment['default'])(date, 'MM/DD/YYYY', true).isValid()) {
          window.$('.app-continue-button').attr('disabled', false);
          this.set('showDateError', false);
          this.set('birthdate', date);
        } else {
          window.$('.app-continue-button').attr('disabled', true);
          this.set('showDateError', true);
          this.set('birthdate', null);
        }
      },
      licensedDealer: function licensedDealer(value) {
        _ember['default'].set(this, 'licensedDealer', value === 'yes' ? true : false);
      },
      insuranceCoverageElected: function insuranceCoverageElected(value) {
        _ember['default'].set(this, 'insuranceCoverageElected', value === 'yes' ? true : false);
      },
      agreeTermsConditions: function agreeTermsConditions(value) {
        // console.log('in agreeConditions');
        // console.log(value);
        // let agree = this.get('agreeConditions');
        // this.set('showConditionsError', agree === true ? false : true);
        // Ember.set(this, 'agreeConditions', value === 'yes' ? true : false);
        // this.get('changeset').set('app_terms', value === 'yes' ? 1 : 0);
      },
      showTerms: function showTerms() {
        var showTerms = this.get('showTerms');
        this.set('showTerms', showTerms === false ? true : false);
      },
      expired: function expired() {},
      save: function save() {
        var changeset = this.get('changeset');
        var date = window.$('#birth-date').val();
        var agreeConditions = this.get('agreeConditions');

        if (date.length && (0, _moment['default'])(date, 'MM/DD/YYYY', true).isValid()) {
          changeset.setProperties({ app_terms: agreeConditions === true ? 1 : 2,
            birthdate: (0, _moment['default'])(date).format('YYYY/MM/DD') });
          this.set('showDateError', false);
        } else {
          this.set('showDateError', true);
          window.$('#birth-date').focus();
          return false;
        }
        if (!agreeConditions) {
          if (agreeConditions === null) {
            this.set('agreeConditions', false);
          }
          window.$('termCondCheck').focus();
          window.$('simple-app-submit').attr('disabled', 'disabled');
          return false;
        } else {
          window.$('simple-app-submit').removeAttr('disabled');
        }
        if (this.get('signature').get('length') > 0 && this.get('sigPresent')) {
          var canvas = null;
          var dataURL = null;
          var sig = null;
          canvas = document.querySelector('#app_sig canvas');
          var headers = this.get('headers');
          if (canvas) {
            dataURL = canvas.toDataURL();
            sig = this.get('signature');
            _ember['default'].set(sig, 'dataURL', dataURL);
            this.set('signatureMissingError', false);
          }
          changeset.set('app_signature', sig.get('dataURL'));
        } else {
          window.$('simple-app-submit').attr('disabled', 'disabled');
          this.set('signatureMissingError', true);
          return false;
        }
        if (changeset.get('isValid')) {
          if (grecaptcha.getResponse() === "") {
            alert("Please provide a reCaptcha response.");
          } else {
            if (changeset.get('changes').length > 1) {
              _ember['default'].$('.app-continue-button').attr("disabled", "disabled").val('Saving...');
              this.attrs.save(changeset);
            }
          }
        } else {
          var firstError = changeset.get('errors')[0]['key'];
          _ember['default'].$('[id*="' + firstError + '"]').focus();
        }
      },
      rollback: function rollback() {
        var changeset = this.get('changeset');
        this.attrs.rollback(changeset);
      },
      sig_reset: function sig_reset() {
        this.set('signature', _ember['default'].A());
      },
      sig_undo: function sig_undo() {
        var sig = this.get('signature');
        var lastNewLine = undefined;
        sig.forEach(function (item, index) {
          if (item[0] === 1) {
            lastNewLine = index;
          }
        });
        this.set('signature', _ember['default'].A(sig.slice(0, lastNewLine)));
      },
      signature_capture: function signature_capture() {
        console.log('capture');
      },
      checkSigPresent: function checkSigPresent() {
        var _this = this;

        if (this.get('signature').get('length') > 0) {
          var canvas = null;
          var dataURL = null;
          var sig = null;
          canvas = document.querySelector('#app_sig canvas');
          var headers = this.get('headers');
          if (canvas) {
            dataURL = canvas.toDataURL();
            this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('apps/check-app-signature'), {
              method: 'POST',
              headers: headers,
              data: {
                sig: dataURL
              }
            }).then(function (response) {
              if (response[0].present) {
                _this.set('signatureMissingError', false);
                _this.set('sigPresent', true);
                _this.send('save');
              } else {
                window.$('simple-app-submit').attr('disabled', 'disabled');
                _this.set('signatureMissingError', true);
                _this.send('sig_reset');
                _this.set('sigPresent', false);
                return false;
              }
            });
          }
        }
      }
    }

  });
});