define('dealer-portal/controllers/app/people', ['exports', 'ember', 'dealer-portal/validations/person'], function (exports, _ember, _dealerPortalValidationsPerson) {
  exports['default'] = _ember['default'].Controller.extend({
    PersonValidations: _dealerPortalValidationsPerson['default'],
    married: false,
    inputForm: false,
    appController: _ember['default'].inject.controller('app'),
    refresh: false,
    currentPersonId: null,
    showDataEnteredWarning: false,
    // noPeople: Ember.computed('')
    allowTransition: true,
    bancruptcy: [{ label: 'No', value: 0 }, { label: 'Yes', value: 1 }],
    observeSession: (function () {
      console.log('in dealer-place controller');
      this.send('sessionChanged');
    }).observes('session.isAuthenticated'),

    actions: {
      setMarried: (function () {
        this.set('married', true);
      }).observes('married'),
      addNewPerson: function addNewPerson(event, value) {
        var spouseId = null;
        console.log('event');
        console.log(event);
        if (event && event.type === 'change') {
          spouseId = parseInt(event.target.id);
        }
        if (value == true) {
          this.set('married', true);
          this.set('currentPersonId', spouseId);
        }
        console.log(this.get('currentPersonId'));
        this.get('appController').set('showAppSave', false);
        this.transitionToRoute('app.people.person', 'new');
      },
      sessionChanged: function sessionChanged() {
        return true;
      },
      dataEntered: function dataEntered() {
        this.set('allowTransition', false);
      },
      save: function save(changeset) {
        var _this = this;

        // let new_person = changeset;
        console.log(changeset);
        changeset.save().then(function () {
          _this.set('inputForm', false);
          _this.get('appController').set('showAppSave', true);
          _this.set('allowTransition', true);
          _this.set('dataEnteredWarning', false);
          _this.transitionToRoute('app.people');
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.set('inputForm', false);
        this.get('appController').set('showAppSave', true);
        this.set('allowTransition', true);
        this.set('dataEnteredWarning', false);
        this.transitionToRoute('app.people');
      }
    }
  });
});