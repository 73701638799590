define('dealer-portal/controllers/accept-invitation', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    userLog: _ember['default'].inject.service(),

    queryParams: ['invitation_token'],
    processing: false,
    invitation_token: undefined,

    actions: {
      acceptInvitation: function acceptInvitation() {
        var _this = this;

        new _dealerPortalAdaptersApplication['default']().simple(this.get('ajax'), this.get('session'), this.get('userLog'), 'auth/accept-invitation', 'PUT', {
          invitation_token: this.get('invitation_token'),
          password: this.get('password'),
          password_confirmation: this.get('passwordConfirmation')
        }).then(function () {
          _this.toast.info('Account Activated!');
          _this.transitionToRoute('login');
        })['catch'](function (data) {
          _this.toast.error('Account activation failed. Try again.');
        });
      }
    }
  });
});