define('dealer-portal/routes/app/dealer-places/dealer-place', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    maps: _ember['default'].inject.service(),
    dealerPlaceSlug: _ember['default'].computed('id', function () {
      return this.get('id');
    }),
    controllerName: 'app.dealer-places',
    model: function model(params) {
      var placesArray = _ember['default'].A();
      var currentPlace = null;
      if (params.id === 'new') {
        var newPlace = this.get('store').createRecord('dealer-place');
        placesArray.pushObject(newPlace);
      } else {
        var places = _ember['default'].A();
        places = this.modelFor('app.dealer-places');
        currentPlace = places.findBy('id', params.id);
        placesArray.pushObject(currentPlace);
      }
      return new _ember['default'].RSVP.Promise(function (resolve) {
        resolve(placesArray);
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        // if (this.controller.get('userHasEnteredData') &&
        //     !confirm('You have unsaved changes that will be lost if you reload. Continue?')) {
        //   transition.abort();
        // } else {
        //   // Bubble the `willTransition` action so that
        //   // parent routes can decide whether or not to abort.
        //   return true;
        // }
        var self = this;
        var controller = this.get('controller');
        if (!controller.get('allowTransition')) {
          console.log('transition abort');
          controller.set('dataEnteredWarning', true);
          transition.abort();
        }
      },
      didTransition: function didTransition() {
        var controller = this.get('controller');
        _ember['default'].run.schedule('afterRender', this, function () {
          this.get('maps').refreshMap();
        });
        if (controller.get('refresh')) {
          this.refresh();
          controller.set('refresh', false);
        }
        controller.set('refresh', true);
        controller.set('inputForm', true);
      },
      sessionChanged: function sessionChanged() {
        this.refresh();
      }
    }
  });
});