define('dealer-portal/helpers/hash-contains', ['exports', 'ember-sort-filter-table/helpers/hash-contains'], function (exports, _emberSortFilterTableHelpersHashContains) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersHashContains['default'];
    }
  });
  Object.defineProperty(exports, 'hashContains', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersHashContains.hashContains;
    }
  });
});