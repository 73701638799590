define('dealer-portal/routes/app/submit', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),

    model: function model() {
      // return this.store.findRecord('app', 149773);
      return this.modelFor('app');
    },

    controllerName: 'app',

    // observeSession: function() {
    //   this.send('sessionChanged');
    // }.observes('session.isAuthenticated'),

    actions: {
      willTransition: function willTransition() {
        var controller = this.get('controller');
        controller.set('submitText', 'Continue >>');
        controller.set('submitButton', 'buttonClicked');
      },
      didTransition: function didTransition() {
        var controller = this.get('controller');
        controller.set('submitText', (0, _emberI18n.translationMacro)('application.submit'));
        controller.set('submitButton', 'submitToCorporate');
        // Ember.run.scheduleOnce('afterRender', this, function(){
        //   if ($('#dealership_container').length) {
        //     var height = $(window).height();
        //     $('#dealership_container').css('height', height);

        //     $('html, body').animate({scrollTop: $('#dealership_container').position().top}, 1000);
        //     $('#nav_container').addClass('nav_container_fixed');
        //   }
        // });
      }
    }
  });
});
// sessionChanged: function() {
//   // this.get('maps').set('markers', Ember.A());
//   console.log('in dealership route');
//   this.refresh();
//   // let authenticated = this.get('session').get('isAuthenticated');
//   // this.get('maps').refreshMap(authenticated);
// }