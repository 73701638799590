define('dealer-portal/routes/app-simple/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    controllerName: 'app-simple',
    templateName: 'app-simple/index',

    model: function model() {
      return _ember['default'].RSVP.hash({
        app: this.get('store').createRecord('app'),
        branches: this.store.query('branch', {
          filter: { scope: 'open_corp' }
        })
      });
    }
  });
});