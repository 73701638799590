define('dealer-portal/initializers/csv', ['exports', 'ember-cli-data-export/initializers/csv'], function (exports, _emberCliDataExportInitializersCsv) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliDataExportInitializersCsv['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberCliDataExportInitializersCsv.initialize;
    }
  });
});