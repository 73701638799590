define('dealer-portal/controllers/application', ['exports', 'ember', 'dealer-portal/config/environment', 'dealer-portal/validations/geolocate'], function (exports, _ember, _dealerPortalConfigEnvironment, _dealerPortalValidationsGeolocate) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    maps: _ember['default'].inject.service(),
    GeolocateValidations: _dealerPortalValidationsGeolocate['default'],

    isBranchRoute: _ember['default'].computed.equal('currentRouteName', 'branches.index'),

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },

      clearGeolocate: function clearGeolocate() {
        window.localStorage.clear();
        this.transitionTo('branches.index');
      },

      redirectToApp: function redirectToApp() {
        this.transitionToRoute('app.dealership');
      }
    }
  });
});