define('dealer-portal/routes/access-home/update-search', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    controllerName: 'access-home',
    redirect: function redirect(params) {
      this.transitionTo('access-home.' + params['transitionFrom']);
    },
    actions: {
      resetForm: function resetForm() {}
    }
  });
});