define("dealer-portal/models/dealer", ["exports", "ember-data", "ember", "ember-i18n"], function (exports, _emberData, _ember, _emberI18n) {
  exports["default"] = _emberData["default"].Model.extend({
    i18n: _ember["default"].inject.service(),
    name: _emberData["default"].attr(),
    email: _emberData["default"].attr(),
    phone: _emberData["default"].attr(),
    activeStatus: _emberData["default"].attr(),
    activeNsf: _emberData["default"].attr(),
    totalCredit: _emberData["default"].attr(),
    totalCreditUsed: _emberData["default"].attr(),
    totalCreditRemaining: _emberData["default"].attr(),
    totalUtilization: _emberData["default"].attr(),
    currentFloorCount: _emberData["default"].attr(),
    currentCredit: _emberData["default"].attr(),
    currentReserves: _emberData["default"].attr(),
    reservesMinimum: _emberData["default"].attr(),
    reserveUnitAdd: _emberData["default"].attr(),
    primaryPlaceId: _emberData["default"].attr(),
    branch_id: _emberData["default"].attr(),
    utilization: _emberData["default"].attr(),
    hasTaxLine: _emberData["default"].attr(),
    reservesWithdrawAllowed: _emberData["default"].attr(),
    reservesReportLines: _emberData["default"].attr(),
    insuranceCoverageElected: _emberData["default"].attr(),
    couponRedemptions: _emberData["default"].attr(),
    people: _emberData["default"].hasMany("person"),
    apps: _emberData["default"].hasMany("app"),
    branch: _emberData["default"].belongsTo("branch"),
    clampUtilization: function clampUtilization(number, min, max) {
      return Math.min(Math.max(number, min), max);
    },

    // creditUsed: Ember.computed('currentCredit', 'utilization', function() {
    //   var totalCreditGranted = this.get('currentCredit');
    //   var utilization = this.get('utilization');
    //   return totalCreditGranted * utilization;
    // }),

    creditUsed: _ember["default"].computed("currentCredit", "utilization", function () {
      var totalCreditGranted = this.get("currentCredit");
      var utilization = this.get("utilization");
      return totalCreditGranted * utilization;
    }),

    // remainingCredit: Ember.computed('currentCredit', function() {
    //   var totalCreditGranted = this.get('currentCredit');
    //   return totalCreditGranted - this.creditUsed;
    // }),

    utilizationPercentage: _ember["default"].computed("totalUtilization", function () {
      var totalUtilization = this.get("totalUtilization");
      var clampedUtilization = this.clampUtilization(totalUtilization, 0, 100);

      return clampedUtilization + "%";
    }),

    chartData: _ember["default"].computed("currentCredit", "utilization", "i18n.locale", function () {
      var i18n = this.get("i18n");
      return [["Credit", "Amount"], [i18n.t("currentUnitPage.used").string, this.get("totalCreditUsed")], [i18n.t("currentUnitPage.remaining").string, Math.max(this.get("totalCreditRemaining"), 0)]];
    }),

    refloorCoupons: _ember["default"].computed("couponRedemptions", function () {
      return this.get("couponRedemptions").filter(function (coupon) {
        return coupon.transfer_type === 1;
      });
    }),

    chartOptions: {
      title: (0, _emberI18n.translationMacro)("currentUnitPage.creditLineTitle").string,
      titleTextStyle: { fontSize: 14, bold: true },
      height: 280,
      width: 320,
      pieHole: 0.4,
      slices: [{ color: "blue" }, { color: "gray" }],
      legend: "bottom",

      animation: {
        startup: true,
        easing: "inAndOut"
      }
    },

    miniChartOptions: {
      title: (0, _emberI18n.translationMacro)("currentUnitPage.creditLineTitle").string,
      titleTextStyle: { fontSize: 14, bold: true },
      height: 180,
      width: 180,
      pieHole: 0.4,
      slices: [{ color: "blue" }, { color: "gray" }],
      legend: "bottom",

      animation: {
        startup: true,
        easing: "inAndOut"
      }
    }
  });
});