define('dealer-portal/routes/access-home/file-submitted', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    // controllerName: 'app-simple',
    redirect: function redirect() {
      this.transitionTo('access-home.uploads');
    },
    actions: {
      resetForm: function resetForm() {}
    }
  });
});