define('dealer-portal/models/person', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    first_name: _emberData['default'].attr(),
    last_name: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    birthdate: _emberData['default'].attr(),
    home_address: _emberData['default'].attr(),
    city: _emberData['default'].attr(),
    state: _emberData['default'].attr(),
    zip: _emberData['default'].attr(),
    dl_number: _emberData['default'].attr(),
    dl_state: _emberData['default'].attr(),
    dl_exp_on: _emberData['default'].attr(),
    ssn: _emberData['default'].attr(),
    title: _emberData['default'].attr(),
    contract_g: _emberData['default'].attr(),
    contract_o: _emberData['default'].attr(),
    contract_rtb: _emberData['default'].attr(),
    percent_ownership: _emberData['default'].attr(),
    contract_rtb_signer: _emberData['default'].attr(),
    llc_term: _emberData['default'].attr(),
    ach_signer: _emberData['default'].attr(),
    is_company: _emberData['default'].attr(),
    app_signature: _emberData['default'].attr(),
    own_or_rent_home: _emberData['default'].attr(),
    years_at_residence: _emberData['default'].attr(),
    declared_bankruptcy: _emberData['default'].attr(),
    us_citizen: _emberData['default'].attr(),
    ml_holder: _emberData['default'].attr(),
    ml_holder_phone: _emberData['default'].attr(),
    ml_payment: _emberData['default'].attr(),
    ml_purchase_price: _emberData['default'].attr(),
    ml_value: _emberData['default'].attr(),
    ml_loan_balance: _emberData['default'].attr(),
    app_signature_ip: _emberData['default'].attr(),
    home_phone: _emberData['default'].attr(),
    cell_phone: _emberData['default'].attr(),
    middle_name: _emberData['default'].attr(),
    suffix: _emberData['default'].attr(),
    dealerId: _emberData['default'].attr(),
    dealer: _emberData['default'].belongsTo('dealer'),
    spouse_id: _emberData['default'].attr()
  });
});