define('dealer-portal/router', ['exports', 'ember', 'dealer-portal/config/environment'], function (exports, _ember, _dealerPortalConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _dealerPortalConfigEnvironment['default'].locationType,
    rootURL: _dealerPortalConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('branches', function () {
      this.route('index');
      this.route('show', { path: ':branch_slug' });
    });
    this.route('auctions', function () {
      this.route('index');
      this.route('show');
    });
    this.route('login');
    this.route('units');
    this.route('contact');
    this.route('about-us');
    this.route('careers');
    this.route('terms');
    this.route('flexible-floor-plans', { anchor: ':anchor' });
    // this.route('app', function() {
    //   this.route('dealer-places', function() {
    //     this.route('dealer-place', { path: ':id' });
    //   });
    //   this.route('dealership');
    //   this.route('dealer-financials');
    //   this.route('people', function() {
    //     this.route('person', { path: ':id' });
    //   });
    //   this.route('references');
    //   this.route('submit');
    //   this.route('submitted');
    // });
    this.route('promotions');
    this.route('app-simple', { path: 'apply-now' }, function () {
      this.route('show', { path: 'submitted' });
      this.route('file-submitted');
    });
    this.route('web-content', { path: 'fx/:web_content_id' });
    this.route('access-home', function () {
      this.route('show', { path: ':id' });
      this.route('bank-accounts', function () {
        this.route('ach-authorization');
      });
      this.route('bank-account-added');
      this.route('notification-settings');
      this.route('user-management');
      this.route('user-management-update');
      this.route('uploads');
      this.route('ach-scheduling', { path: 'ach-scheduling/:id' }, function () {
        this.route('confirm-payment');
      });
      this.route('customer-service', function () {
        this.route('help');
        this.route('base');
      });
      this.route('faq-list');
      this.route('customer-terms');
      //this.route('dealer-status');
      this.route('txns', { path: 'transactions' }, function () {
        this.route('advances');
        this.route('payments');
      });

      this.route('txn-detail', { path: 'transactions/:id' });
      this.route('reserves');
      this.route('pending-payments');
      this.route('prior-units');
      this.route('inventory-analytics');
      this.route('request-floor');

      this.route('local-contact');
      this.route('file-submitted');
      this.route('payoff-calculator', { path: 'payoff-calculator/:id' });
      this.route('update-search', { path: 'search/:transitionFrom' });
      this.route('reports', function () {
        this.route('coming-due');
        this.route('txns', { path: 'payments' }, function () {
          // this.route('show', {path: ':id'});
        });
        this.route('sold');
        this.route('reserves');
        this.route('paid');
      });
      this.route('reset-route');
      this.route('reset-password');
      this.route('accept-invitation');
    });
    this.route('account-unavailable');
    this.route('reset-password');
    this.route('accept-invitation');
    this.route('forgot-password');
    this.route('privacy-policy');
    this.route('covid19-response', { path: 'fx/COVID19' });
  });
  exports['default'] = Router;
});