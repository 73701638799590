define('dealer-portal/utils/google-maps', ['exports', 'ember', 'dealer-portal/config/environment'], function (exports, _ember, _dealerPortalConfigEnvironment) {
  exports['default'] = _ember['default'].Object.extend({

    createMapAfterMapsLoaded: function createMapAfterMapsLoaded(element, isIndex) {
      var google = window.google;
      var position = { lat: 39.09024, lng: -96.912891 };
      var options = { style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR };
      var zoom = isIndex ? 4.2 : 4.495;
      var map = new google.maps.Map(element, { zoom: zoom,
        mapTypeControlOptions: options, fullscreenControl: false });
      this.set('map', map);
      // this.set('markers', markers);
      var markers = this.get('markers');
      // let paths = this.get('paths');
      // let polygons = this.get('polygons');
      map.setCenter(position);
      if (!_ember['default'].isEmpty(markers)) {
        this.addMarkers(map, markers, isIndex);
        // this.set('markers', markers);
      }
      // if(!Ember.isEmpty(paths)) {
      //   this.addPolylines(map, paths);
      // }
      // if(!Ember.isEmpty(polygons)) {
      //   this.addPolygons(map, polygons);
      // }
      return map;
    },

    createMap: function createMap(element, isIndex) {
      if (window.google) {
        this.createMapAfterMapsLoaded(element, isIndex);
      } else {
        var self = this;

        _ember['default'].$.getScript(googleMapsScript).done(function () {
          self.createMapAfterMapsLoaded(element, isIndex);
        });
      }
    },

    addMarkers: function addMarkers(map, markers, isIndex) {
      var _this = this;

      var google = window.google;

      var _loop = function (x) {
        var link = isIndex ? "<a href='" + markers[x].get('slug') + "'>Click here to learn more</a>" : '';
        google.maps.event.addListener(markers[x], 'click', function () {
          var infoWindow = new google.maps.InfoWindow({
            content: '<p>' + markers[x].name + '</p>' + '<p>' + markers[x].address1 + '<br>' + markers[x].city + ', ' + markers[x].state + ', ' + markers[x].zip + '<br>' + markers[x].phone + '</p>' + link, visible: false });
          infoWindow.open(map, markers[x]);
        });
        var current_map = _this.get('map');
        markers[x].setMap(current_map);
        var bounds = new google.maps.LatLngBounds();
        if (markers.length > 1) {
          for (var y = 0; y < markers.length; y++) {
            bounds.extend(markers[y].position);
          }
          current_map.setCenter(bounds.getCenter());
          current_map.fitBounds(bounds);
        } else {
          current_map.setZoom(14);
          current_map.setCenter(markers[x].getPosition());
        }
      };

      for (var x = 0; x < markers.get('length'); x++) {
        _loop(x);
      }
    },

    addPolylines: function addPolylines(map, paths) {
      var _this2 = this;

      var google = window.google;

      paths.forEach(function (path) {
        var positions = path.get('points').map(function (point) {
          return _this2.positionFromPoint(point);
        });

        var polyline = new google.maps.Polyline({
          path: positions,
          strokeWeight: 2,
          strokeOpacity: 0.7,
          strokeColor: path.get('pathColor'),
          geodesic: true
        });

        var infoWindow = new google.maps.InfoWindow({ content: path.get('title') });

        google.maps.event.addListener(polyline, 'click', function (event) {
          var clickPoint = event.latLng;
          infoWindow.setPosition(clickPoint);
          infoWindow.open(map);
        });

        polyline.setMap(map);
      });
    },

    addPolygons: function addPolygons(map, polygons) {
      var google = window.google;
      polygons.forEach(function (polygon) {
        if (!_ember['default'].isEmpty(polygon)) {
          polygon.setMap(map);
          google.maps.event.addListener(polygon, 'mouseover', function () {
            polygon.set('fillOpacity', 0.25);
          });
          google.maps.event.addListener(polygon, 'mouseout', function () {
            polygon.set('fillOpacity', 0.15);
          });
        }
      });
    },

    geolocate: function geolocate(address, geolocation, latLng) {
      return new Promise(function (resolve, reject) {
        var google = window.google;
        var geocoder = new google.maps.Geocoder();
        var type = null;
        if (address) {
          type = { address: address };
          geocoder.geocode(type, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              var lat = results[0].geometry.location.lat();
              var lng = results[0].geometry.location.lng();
              latLng = new window.google.maps.LatLng(lat, lng);
              geolocation.set('latLng', latLng);
              resolve(geolocation);
            } else {
              reject('Unable to geolocate location.');
            }
          });
        } else {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
              var latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
              var address_components = null;
              type = { location: latLng };
              geocoder.geocode({ 'location': latLng }, function (results, status) {
                if (status === 'OK') {
                  if (results[0]) {
                    (function () {
                      address_components = results[0].address_components;
                      var components = {};
                      jQuery.each(address_components, function (k, v1) {
                        jQuery.each(v1.types, function (k2, v2) {
                          components[v2] = v1.long_name;
                        });
                      });
                      geolocation.setProperties({
                        address1: components.street_number + ' ' + components.route,
                        city: components.locality,
                        state: components.administrative_area_level_1,
                        zip: components.postal_code,
                        latLng: latLng
                      });
                      resolve();
                    })();
                  } else {
                    reject('Unable to geolocate location.');
                  }
                }
              });
            });
          }
        }
      });
    },

    // async geolocateByIp() {
    //   if(navigator.geolocation) {
    //     let result = await
    //       let place = null;
    //       let address = null;
    //       geolocate(address, latLng);
    //       // let geocoder = new google.maps.Geocoder;
    //       // geocoder.geocode({'location': latLng}, function(results, status) {
    //       //   if(status == 'OK') {
    //       //     if(results[0]) {
    //       //       place = results[0].formatted_address;
    //       //     }
    //       //   }
    //       // });
    //     });
    //   }
    // },

    geolocateByIp: function geolocateByIp(geolocation) {
      return regeneratorRuntime.async(function geolocateByIp$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.geolocate(null, geolocation));

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    // return result;
    checkBranches: function checkBranches(branches, geocoded, geolocation, latLng, marker) {
      branches.forEach(function (branch) {
        var mapPoints = branch.get('mapPoints');
        if (!_ember['default'].isEmpty(mapPoints)) {
          (function () {
            var path = [];
            var pointLatLng = [];

            mapPoints.forEach(function (point) {
              pointLatLng = new window.google.maps.LatLng(point.get('lat'), point.get('lng'));
              path.pushObject(pointLatLng);
            });
            var poly = new window.google.maps.Polygon({ paths: path });
            if (google.maps.geometry.poly.containsLocation(latLng, poly)) {
              console.log('dealership found');
              geocoded = {
                address1: geolocation.address1,
                city: geolocation.city,
                state: geolocation.state,
                zip: geolocation.zip,
                lat: geolocation.latLng.lat(),
                lng: geolocation.latLng.lng(),
                branchId: branch.id
              };
            }
          })();
        }
      });
      if (!geocoded) {
        geocoded = {
          address1: geolocation.address1,
          city: geolocation.city,
          state: geolocation.state,
          zip: geolocation.zip,
          lat: geolocation.latLng.lat(),
          lng: geolocation.latLng.lng(),
          branchId: 17
        };
      }
      if (marker) {
        geocoded.marker = marker;
      }
      return geocoded;
    },

    checkInRegion: function checkInRegion(address1, city, state, zip, branches) {
      var address, geolocation, latLng, marker, image, geocoded;
      return regeneratorRuntime.async(function checkInRegion$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            address = null;
            geolocation = [];

            if (address1 || city || state || zip) {
              geolocation.address1 = address1 || null;
              geolocation.city = city || null;
              geolocation.state = state || null;
              geolocation.zip = zip || null;
              address = address1 + ', ' + city + ', ' + state + ', ' + zip;
            }
            // geolocation.set('latLng', await this.geolocate(address));
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.geolocate(address, geolocation));

          case 5:
            latLng = geolocation.latLng;
            marker = null;

            if (geolocation.address1 != null && geolocation.city != null && geolocation.state != null && geolocation.zip != null) {
              image = { url: '/images/newfpxflag-small-blue.png',
                size: new google.maps.Size(23, 32),
                anchor: new google.maps.Point(2, 32)
              };

              marker = new window.google.maps.Marker({
                lot_num: 1,
                address1: geolocation.address1,
                city: geolocation.city,
                state: geolocation.state,
                zip: geolocation.zip,
                id: 1,
                lat: latLng.lat,
                lng: latLng.lng,
                position: new google.maps.LatLng(latLng.lat(), latLng.lng()),
                // animation: google.maps.Animation.DROP,
                crossOnDrag: false,
                draggable: false,
                icon: image
              });
            }
            context$1$0.next = 10;
            return regeneratorRuntime.awrap(this.checkBranches(branches, geocoded, geolocation, latLng, marker));

          case 10:
            geocoded = context$1$0.sent;
            return context$1$0.abrupt('return', geocoded);

          case 12:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    addDealerPlace: function addDealerPlace(place, address, lot_count, geocodedPlace) {
      var google, latLng, marker, markers, current_map, image, bounds, x;
      return regeneratorRuntime.async(function addDealerPlace$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            google = window.google;
            latLng = null;
            marker = null;
            markers = [];
            current_map = this.get('map');

            if (this.get('markers')) {
              markers = this.get('markers');
            }

            if (!geocodedPlace) {
              context$1$0.next = 11;
              break;
            }

            latLng = new google.maps.LatLng(geocodedPlace.lat, geocodedPlace.lng);
            marker = new google.maps.Marker(geocodedPlace.marker);
            context$1$0.next = 16;
            break;

          case 11:
            context$1$0.next = 13;
            return regeneratorRuntime.awrap(this.geolocate(address, latLng));

          case 13:
            latLng = context$1$0.sent;
            image = { url: '/images/newfpxflag-small-blue.png',
              size: new google.maps.Size(23, 32),
              anchor: new google.maps.Point(2, 32)
            };

            marker = new google.maps.Marker({
              lot_num: lot_count,
              lot_name: place.get('name'),
              address1: place.get('address1'),
              address2: place.get('address2'),
              city: place.get('city'),
              state: place.get('state'),
              zip: place.get('zip'),
              county: place.get('county'),
              phone: place.get('phone'),
              fax: place.get('fax'),
              status: place.get('status'),
              id: lot_count,
              lat: latLng.lat,
              lng: latLng.lng,
              place_id: place.id,
              position: new google.maps.LatLng(latLng.lat, latLng.lng),
              // animation: google.maps.Animation.DROP,
              crossOnDrag: true,
              draggable: true,
              icon: image
            });

          case 16:
            google.maps.event.addListener(marker, 'click', function () {
              var infoWindow = new google.maps.InfoWindow({
                content: '<p>Lot ' + lot_count + '</p>' + '<p>' + place.get('name') + '<br>' + place.get('address1') + '<br>' + place.get('city') + ', ' + place.get('state') + ', ' + place.get('zip'), visible: false });
              infoWindow.open(current_map, marker);
              place.send('activeMarker', marker);
            });
            google.maps.event.addListener(marker, 'dragend', function (event) {
              place.set('lat', event.latLng.lat());
              place.set('lng', event.latLng.lng());
              marker.set('lat', event.latLng.lat());
              marker.set('lng', event.latLng.lng());
            });
            bounds = new google.maps.LatLngBounds();

            markers.pushObject(marker);
            place.set('associated_marker', markers.indexOf(marker) + 1);
            marker.setMap(current_map);
            if (markers.length > 1) {
              for (x = 0; x < markers.length; x++) {
                bounds.extend(markers[x].position);
              }
              current_map.setCenter(bounds.getCenter());
              current_map.fitBounds(bounds);
            } else {
              current_map.setZoom(14);
              current_map.setCenter(marker.getPosition());
            }
            return context$1$0.abrupt('return', marker);

          case 24:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    placeMarkers: function placeMarkers(places) {
      var _this3 = this;

      var current_map = this.get('map');
      if (places) {
        for (var _x = 0; _x < places.length; _x++) {
          if (places[_x].marker != null) {
            var x;

            (function () {
              var image = { url: '/images/newfpxflag-small-blue.png',
                size: new google.maps.Size(23, 32),
                anchor: new google.maps.Point(2, 32)
              };
              var marker = new google.maps.Marker({
                lot_num: _x,
                lot_name: places[_x].name,
                address1: places[_x].address1,
                address2: places[_x].address2,
                city: places[_x].city,
                state: places[_x].state,
                zip: places[_x].zip,
                id: _x,
                map: current_map,
                lat: places[_x].lat,
                lng: places[_x].lng,
                place_id: null,
                position: new google.maps.LatLng(places[_x].lat, places[_x].lng),
                // animation: google.maps.Animation.DROP,
                crossOnDrag: true,
                draggable: true,
                icon: image
              });
              google.maps.event.addListener(marker, 'click', function () {
                var name = marker.name ? marker.name + '<br>' : '';
                var infoWindow = new google.maps.InfoWindow({
                  content: '<p>Lot</p>' + name + marker.address1 + '<br>' + marker.city + ', ' + marker.state + ', ' + marker.zip, visible: false });
                infoWindow.open(current_map, marker);
              });
              marker.setMap(current_map);

              var markers = _this3.get('markers');
              markers.pushObject(marker);
              var bounds = new google.maps.LatLngBounds();
              if (markers && markers.length > 1) {
                for (x = 0; _x < markers.length; _x++) {
                  bounds.extend(markers[_x].position);
                }
                current_map.setCenter(bounds.getCenter());
                current_map.fitBounds(bounds);
              } else {
                current_map.setZoom(14);
                current_map.setCenter(marker.getPosition());
              }
            })();
          }
        }
      }
    },

    auctionMarkers: function auctionMarkers(auctions) {
      var _this4 = this;

      var current_map = this.get('map');
      if (auctions) {
        var _loop2 = function (_x22) {
          var image = { url: '/images/newfpxflag-small-blue.png',
            size: new google.maps.Size(23, 32),
            anchor: new google.maps.Point(2, 32)
          };
          var marker = new google.maps.Marker({
            lot_num: _x22,
            name: auctions[_x22].name,
            address1: auctions[_x22].address1,
            address2: auctions[_x22].address2,
            city: auctions[_x22].city,
            state: auctions[_x22].state,
            zip: auctions[_x22].zip,
            id: auctions[_x22].id,
            map: current_map,
            lat: auctions[_x22].latitude,
            lng: auctions[_x22].longitude,
            position: new google.maps.LatLng(auctions[_x22].latitude, auctions[_x22].longitude),
            // animation: google.maps.Animation.DROP,
            crossOnDrag: true,
            draggable: true,
            icon: image
          });
          var formattedPhone = auctions[_x22].phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || '';
          google.maps.event.addListener(marker, 'click', function () {
            var name = marker.name ? marker.name + '<br>' : '';
            var infoWindow = new google.maps.InfoWindow({
              content: name + marker.address1 + '<br>' + marker.city + ', ' + marker.state + ', ' + marker.zip + '<br>' + formattedPhone + '<br>' + '<a href="' + auctions[_x22].url + '" target="_blank">Visit Website' + '</a><br>' + auctions[_x22].auction_times, visible: false });
            infoWindow.open(current_map, marker);
          });
          marker.setMap(current_map);

          var markers = _this4.get('markers');
          markers.pushObject(marker);
          var bounds = new google.maps.LatLngBounds();
          if (markers && markers.length > 1) {
            for (_x22 = 0; _x22 < markers.length; _x22++) {
              bounds.extend(markers[_x22].position);
            }
            current_map.fitBounds(bounds);
          } else {
            current_map.setZoom(14);
            current_map.setCenter(marker.getPosition());
          }
          _x2 = _x22;
        };

        for (var _x2 = 0; _x2 < auctions.get('length'); _x2++) {
          var _x22;

          _loop2(_x2);
        }
      }
    },

    createMarkers: function createMarkers(data, isIndex, isAppMap) {
      var markers = _ember['default'].A();
      var marker = null;
      var paths = [];
      var polygons = [];
      var geocoded = null;
      var latLng = [];
      if (isIndex) {
        if (data) {
          data.forEach(function (item) {
            var mapPoints = item.get('mapPoints');
            if (!_ember['default'].isEmpty('mapPoints')) {
              (function () {
                var path = [];
                var image = { url: '/images/newfpxflag-small.png',
                  size: new google.maps.Size(23, 32),
                  anchor: new google.maps.Point(2, 32)
                };
                var formattedPhone = item.get('phone').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || '';
                marker = new window.google.maps.Marker({
                  lot_num: null,
                  name: item.get('name') + ' Branch',
                  address1: item.get('address1'),
                  city: item.get('city'),
                  state: item.get('state'),
                  zip: item.get('zip'),
                  phone: formattedPhone,
                  id: item.get('id'),
                  lat: latLng.lat,
                  lng: latLng.lng,
                  slug: item.get('slug') || item.get('id'),
                  position: new google.maps.LatLng(item.get('latitude'), item.get('longitude')),
                  // animation: google.maps.Animation.DROP,
                  crossOnDrag: false,
                  draggable: false,
                  icon: image
                });
                markers.pushObject(marker);
                mapPoints.forEach(function (point) {
                  latLng = new window.google.maps.LatLng(point.get('lat'), point.get('lng'));
                  path.pushObject(latLng);
                });
                var poly = new window.google.maps.Polygon({
                  paths: path,
                  draggable: false,
                  editable: false,
                  strokeColor: item.get('color'),
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: item.get('color'),
                  fillOpacity: 0.15,
                  branchId: item.get('branchId')
                });
                polygons.pushObject(poly);
                // window.google.maps.event.addListener(poly, 'click', function() {
                //   this.get('controller').sendAction('redirectToShow', poly.get('branchId'));
                // });
              })();
            }
          });
        }
      } else if (isAppMap) {
          var places = data.toArray();
          if (places) {
            for (var x = 0; x < places.get('length'); x++) {
              latLng = new window.google.maps.LatLng(places[x].get('latitude'), places[x].get('longitude'));
              var image = { url: '/images/newfpxflag-small-blue.png',
                size: new google.maps.Size(23, 32),
                anchor: new google.maps.Point(2, 32)
              };
              var formattedPhone = places[x].get('phone').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || '';
              marker = new window.google.maps.Marker({
                lot_num: x + 1,
                name: places[x].get('name'),
                address1: places[x].get('address1'),
                city: places[x].get('city'),
                state: places[x].get('state'),
                zip: places[x].get('zip'),
                phone: formattedPhone,
                id: places[x].get('id'),
                lat: places[x].get('latitude'),
                lng: places[x].get('longitude'),
                position: latLng,
                // animation: google.maps.Animation.DROP,
                crossOnDrag: false,
                draggable: false,
                place_id: places[x].get('id'),
                icon: image
              });
              markers.pushObject(marker);
              places[x].set('associated_marker', markers.length);
              var _self = places[x];
              // window.google.maps.event.addListener(marker, 'click', function() {

              // });
              // window.google.maps.event.addListener(marker, 'dblclick', function() {
              //   self.send('activeMarker', marker);
              // });
            }
          }
        } else {
            var item = data;
            console.log(data);
            latLng = new window.google.maps.LatLng(item.get('lat'), item.get('lng'));
            var image = { url: '/images/newfpxflag-small.png',
              size: new google.maps.Size(23, 32),
              anchor: new google.maps.Point(2, 32)
            };
            var formattedPhone = item.get('phone').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || '';
            marker = new window.google.maps.Marker({
              lot_num: null,
              name: item.get('name') + ' branch',
              address1: item.get('address1'),
              city: item.get('city'),
              state: item.get('state'),
              zip: item.get('zip'),
              id: item.get('id'),
              phone: formattedPhone,
              lat: item.get('latitude'),
              lng: item.get('longitude'),
              position: new google.maps.LatLng(item.get('latitude'), item.get('longitude')),
              // animation: google.maps.Animation.DROP,
              crossOnDrag: false,
              draggable: false,
              icon: image
            });
            markers.pushObject(marker);
          }
      this.set('markers', markers);
      this.set('paths', paths);
      this.set('polygons', polygons);
    },

    auctionSearch: function auctionSearch(auctions) {
      var _this5 = this;

      // console.log('address')
      // console.log(address);
      // let origin = null;
      // let originLat = null;
      // let originLng = null;
      // let originLatLng = null;
      // let auctionLatLng = null;
      // let distance = null;
      // let inRadius = [];
      // let current_map = this.get('map');
      //
      // for(let x = 0; x < auctions.get('length'); x++) {
      //   if(address) {
      //     let geocoder = new google.maps.Geocoder();
      //     geocoder.geocode( { 'address': address }, function(results, status) {
      //       if(status == google.maps.GeocoderStatus.OK) {
      //         let auction = auctions[x]['attributes'];
      //         originLat = results[0].geometry.location.lat();
      //         originLng = results[0].geometry.location.lng();
      //         originLatLng = new google.maps.LatLng(originLat, originLng);
      //         auctionLatLng = new google.maps.LatLng(
      //           auction['latitude'],
      //           auction['longitude']);
      //         distance = google.maps.geometry.spherical.computeDistanceBetween(
      //           auctionLatLng, originLatLng);
      //         let distanceMiles = distance *  0.00062137;
      //         if(distanceMiles <= parseInt(radius)) {
      //           let marker = new window.google.maps.Marker({
      //             lot_num: null,
      //             name: auction['name'],
      //             address1: auction['address1'],
      //             city: auction['city'],
      //             state: auction['state'],
      //             zip: auction['zip'],
      //             id: auction['id'],
      //             lat: auction['latitude'],
      //             lng: auction['longitude'],
      //             position: new google.maps.LatLng(auction['latitude'],
      //               auction['longitude']),
      //             animation: google.maps.Animation.DROP,
      //             crossOnDrag: false,
      //             draggable: false
      //           });
      //           marker.setMap(current_map);
      //           markers.pushObject(marker);
      //         }
      //       }else {
      //         console.log('Unable to locate area.');
      //       }
      //     });
      //   }
      // }
      var markers = this.get('markers');
      var current_map = this.get('map');
      for (var y = 0; y < markers.get('length'); y++) {
        markers[y].setMap(null);
      }
      markers = [];

      var _loop3 = function (_x3) {
        var auction = auctions[_x3]['attributes'];
        var image = { url: '/images/newfpxflag-small-blue.png',
          size: new google.maps.Size(23, 32),
          anchor: new google.maps.Point(2, 32)
        };
        var marker = new window.google.maps.Marker({
          lot_num: null,
          name: auction['name'],
          address1: auction['address1'],
          city: auction['city'],
          state: auction['state'],
          zip: auction['zip'],
          id: auction['id'],
          lat: auction['latitude'],
          lng: auction['longitude'],
          position: new google.maps.LatLng(auction['latitude'], auction['longitude']),
          // animation: google.maps.Animation.DROP,
          crossOnDrag: false,
          draggable: false,
          icon: image
        });
        var formattedPhone = auction['phone'].replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || '';
        google.maps.event.addListener(marker, 'click', function () {
          var name = marker.name ? marker.name + '<br>' : '';
          var infoWindow = new google.maps.InfoWindow({
            content: name + marker.address1 + '<br>' + marker.city + ', ' + marker.state + ', ' + marker.zip + '<br>' + formattedPhone + '<br>' + '<a href=' + auction['url'] + ' target="_blank">Visit Website</a><br>' + auction['auction-times'],
            visible: false });
          infoWindow.open(current_map, marker);
        });
        marker.setMap(current_map);
        markers.pushObject(marker);
        _this5.set('markers', markers);
      };

      for (var _x3 = 0; _x3 < auctions.get('length'); _x3++) {
        _loop3(_x3);
      }
      var bounds = new google.maps.LatLngBounds();
      if (markers.get('length') > 1) {
        for (var x = 0; x < markers.get('length'); x++) {
          bounds.extend(markers[x].position);
        }
        current_map.setCenter(bounds.getCenter());
        current_map.fitBounds(bounds);
      } else if (markers.get('length') == 1) {
        current_map.setZoom(14);
        current_map.setCenter(markers[0].getPosition());
      } else {
        current_map.setZoom(4.495);
      }
    },

    refreshMap: function refreshMap() {
      var current_map = this.get('map');
      var markers = this.get('markers');
      var bounds = new google.maps.LatLngBounds();
      if (markers.length > 1) {
        for (var x = 0; x < markers.length; x++) {
          bounds.extend(markers[x].position);
        }
        current_map.setCenter(bounds.getCenter());
        current_map.fitBounds(bounds);
      } else if (markers.length == 1) {
        current_map.setZoom(14);
        current_map.setCenter(markers[0].getPosition());
      } else {
        current_map.setZoom(4.495);
      }
      // let markers = this.get('markers');
      // if(!authenticated) {
      //   for(let x = 0; x < markers.length; x++) {
      //     markers.removeAt(markers[x - 1]);
      //   }
      // }
      // authenticated = true;
      // console.log('in refreshMap');
      // console.log(current_map);
      // current_map.resize();
      // current_map.set(4);
      // google.maps.event.trigger(current_map, 'resize');
    }
  });
});