define('dealer-portal/routes/access-home/bank-accounts/ach-authorization', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    controllerName: 'access-home.bank-accounts',

    model: function model() {
      return _ember['default'].RSVP.hash({
        bankAccounts: this.get('store').query('bank-account', {
          filter: { owner_id: this.get('dealerId') }
        })
      });
    },
    dealerId: _ember['default'].computed(function () {
      return this.get('session').get('data').authenticated.dealerId;
    }),
    setupController: function setupController(controller, model) {
      controller.set('allAuthorized', false);
    }
  });
});