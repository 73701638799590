define('dealer-portal/utils/generated-file', ['exports', 'dealer-portal/adapters/application'], function (exports, _dealerPortalAdaptersApplication) {

  var generatedFile = function generatedFile(session, path, params) {
    var url = new _dealerPortalAdaptersApplication['default']().fullURL(path);

    var form = Ember.$("<form target='_blank' method='POST' style='display:none;'></form>").attr({
      action: url
    }).appendTo(document.body);

    session.authorize('authorizer:token', function (headerName, headerValue) {
      $('<input type="hidden">').attr({
        name: 'token', value: headerValue.split(' ')[1]
      }).appendTo(form);
    });

    console.log(params);
    Object.keys(params).forEach(function (key) {
      $('<input type="hidden">').attr({ name: key, value: params[key] }).appendTo(form);
    });

    form.submit();
    form.remove();
  };

  exports['default'] = generatedFile;
});
// based on
// https://stackoverflow.com/questions/7024040/jquery-open-page-in-a-tab-and-pass-some-post-values