define('dealer-portal/controllers/app/people/person', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    inputForm: true,

    actions: {
      // save(changeset) {
      //   changeset.save().then(()=> {

      //   });
      // save(person) {
      // alert('in controller save');
      // var new_person = person;
      // new_person.save();
      // this.setProperties({
      //   'first_name': '', 'last_name': '', 'birthdate': '', 'address': '',
      //   'city': '', 'state': '', 'zip': '', 'dl_number': '', 'dl_state': '',
      //   'ssn': '', 'title': '', 'rtb-ownership': '', 'own-or-rent-hom': '',
      //   'years-at-residence': '', 'us-citizen': '', 'ml-holder': '',
      //   'ml-payment': '', 'ml-purchase-price': '', 'ml-value': '',
      //   'ml-loan-balance': ''
      // });
      // }
      save: function save(changeset) {
        var _this = this;

        // var place = this.store.peekAll('dealer-place').findBy('associated_marker', this.get('current_place'));
        var new_person = person;
        var self = this;

        changeset.save().then(function () {
          _this.transitionToRoute('app.people');
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
      }
    }
  });
});