define('dealer-portal/helpers/is-private-key', ['exports', 'ember-sort-filter-table/helpers/is-private-key'], function (exports, _emberSortFilterTableHelpersIsPrivateKey) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersIsPrivateKey['default'];
    }
  });
  Object.defineProperty(exports, 'isPrivateKey', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersIsPrivateKey.isPrivateKey;
    }
  });
});