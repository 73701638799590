define('dealer-portal/routes/app/submitted', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('app');
    },

    controllerName: 'app',

    // observeSession: function() {
    //   this.send('sessionChanged');
    // }.observes('session.isAuthenticated'),

    actions: {
      // willTransition: function() {
      //   let controller = this.get('controller');
      //   controller.set('submitText', 'Continue >>');
      //   controller.set('submitButton', 'buttonClicked');
      // },
      // didTransition: function() {
      //   let controller = this.get('controller');
      //   controller.set('submitText', 'Submit');
      //   controller.set('submitButton', 'submitToCorporate');
      //   // Ember.run.scheduleOnce('afterRender', this, function(){
      //   //   if ($('#dealership_container').length) {
      //   //     var height = $(window).height();
      //   //     $('#dealership_container').css('height', height);

      //   //     $('html, body').animate({scrollTop: $('#dealership_container').position().top}, 1000);
      //   //     $('#nav_container').addClass('nav_container_fixed');
      //   //   }
      //   // });
      // },
      // sessionChanged: function(value) {
      //   console.log(value);
      //   // this.get('maps').set('markers', Ember.A());
      //   console.log('in dealership route');
      //   if(!this.get('session.isAuthenticated')) {
      //     this.transitionTo('/');
      //   }
      //   // this.refresh();
      //   // let authenticated = this.get('session').get('isAuthenticated');
      //   // this.get('maps').refreshMap(authenticated);
      // }
    }
  });
});