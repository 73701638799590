define('dealer-portal/models/auction', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr(),
    address1: _emberData['default'].attr(),
    address2: _emberData['default'].attr(),
    city: _emberData['default'].attr(),
    state: _emberData['default'].attr(),
    zip: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    url: _emberData['default'].attr(),
    latitude: _emberData['default'].attr(),
    longitude: _emberData['default'].attr(),
    phone: _emberData['default'].attr(),
    phone2: _emberData['default'].attr(),
    fax: _emberData['default'].attr(),
    // sunday: DS.attr(),
    // monday: DS.attr(),
    // tuesday: DS.attr(),
    // wednesday: DS.attr(),
    // thursday: DS.attr(),
    // friday: DS.attr(),
    // saturday: DS.attr(),
    // sunday_time: DS.attr(),
    // monday_time: DS.attr(),
    // tuesday_time: DS.attr(),
    // wednesday_time: DS.attr(),
    // thursday_time: DS.attr(),
    // friday_time: DS.attr(),
    // saturday_time: DS.attr(),
    auction_times: _emberData['default'].attr(),
    branches: _emberData['default'].hasMany('branch')
  });
});