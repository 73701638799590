define('dealer-portal/routes/app/people', ['exports', 'ember'], function (exports, _ember) {
  // import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    people: _ember['default'].A(),

    model: function model() {
      var currentDealer = this.get('session').get('data').authenticated.dealerId;
      var people = this.get('people');
      if (currentDealer) {
        people = this.get('store').query('person', { 'dealer_id': currentDealer });
      }
      return people;
    },

    // renderTemplate: function() {
    //   this.render();
    //   this.render('app.people.person', {
    //     // outlet: 'person',
    //     into: 'app.people',
    //     controller: 'app.people.person',
    //     model: 'app.people.person'
    //   });
    // },

    // afterModel(model, transition) {
    //   // this.refresh();
    // //   if(model.get('length') > 1) {
    // //     this.transitionTo('app.people.person', model.get('firstObject'));
    // //   }else {
    // //     this.transitionTo('app.people.person', this.store.createRecord('person'));
    // //   }
    // },

    actions: {
      didTransition: function didTransition() {
        var controller = this.get('controller');
        controller.set('inputForm', false);
        if (controller.get('refresh')) {
          this.refresh();
          controller.set('refresh', false);
        }
        // Ember.run.scheduleOnce('afterRender', this, function(){
        //   console.log('how many times am I calling this?')

        // });
        //   if ($('#people').length) {
        //     var height = $(window).height();
        //     $('#people_container').css('height', height);
        //     $('#nav_container').addClass('nav_container_fixed');
        //     $('html, body').animate({scrollTop: $('#people_container').offset().top}, 1000);
        //   }
        // });
        // var person = this.modelFor('people.person', 'test1');
      }
    }
  });
});