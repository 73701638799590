define('dealer-portal/models/txn', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    postAt: _emberData['default'].attr(),
    type: _emberData['default'].attr(),
    paymentsUnitsForTxn: _emberData['default'].attr(),
    advancesUnitsForTxn: _emberData['default'].attr(),
    linesForTxn: _emberData['default'].attr(),
    cdate: _emberData['default'].attr(),
    units: _emberData['default'].hasMany('unit')
  });
});