define('dealer-portal/routes/flexible-floor-plans', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    templateName: 'web-content',
    queryParams: {
      anchor: {
        refreshModel: true
      }
    },
    anchor: null,

    model: function model(params) {
      this.set('anchor', params.anchor);
      return this.get('store').findRecord('web-content', 'flexible-floor-plans');
    },

    actions: {
      didTransition: function didTransition() {
        var anchor = this.get('anchor');
        if (anchor != null) {
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            var aTag = _ember['default'].$("a[name='" + anchor + "']");
            _ember['default'].$('html').animate({ scrollTop: aTag.position().top + 50 });
            // Ember.$('html').animate({ scrollTop: Ember.$('#' + anchor).offset().top - 100 });
          });
        }
      }
    }
  });
});