define('dealer-portal/models/app', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    first_name: _emberData['default'].attr(),
    last_name: _emberData['default'].attr(),
    dealership_name: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    address1: _emberData['default'].attr(),
    address2: _emberData['default'].attr(),
    city: _emberData['default'].attr(),
    state: _emberData['default'].attr(),
    zip: _emberData['default'].attr(),
    aa_id: _emberData['default'].attr(),
    phone: _emberData['default'].attr(),
    cell_phone: _emberData['default'].attr(),
    app_terms: _emberData['default'].attr(),
    app_signature: _emberData['default'].attr(),
    branch_id: _emberData['default'].attr(),
    latitude: _emberData['default'].attr(),
    longitude: _emberData['default'].attr(),
    ssn: _emberData['default'].attr(),
    birthdate: _emberData['default'].attr(),
    dba: _emberData['default'].attr(),
    terms: _emberData['default'].attr(),
    o1_address1: _emberData['default'].attr(),
    o1_city: _emberData['default'].attr(),
    o1_state: _emberData['default'].attr(),
    o1_zip: _emberData['default'].attr(),
    referral_type: _emberData['default'].attr(),
    insurance_company: _emberData['default'].attr(),
    dealer: _emberData['default'].belongsTo('dealer')

  });
});
//import Ember from 'ember';
/* original app interface
  dealership_name: DS.attr(),
  dba: DS.attr(),
  loan_request: DS.attr(),
  corp_type: DS.attr(),
  sales_type: DS.attr(),
  dealer_number: DS.attr(),
  biz_years: DS.attr(),
  loc_years: DS.attr(),
  average_price: DS.attr(),
  average_days: DS.attr(),
  auction_1: DS.attr(),
  auction_2: DS.attr(),
  auction_3: DS.attr(),
  program_financing: DS.attr(),
  accounts_rec: DS.attr(),
  current_units: DS.attr(),
  current_units_floored: DS.attr(),
  accounting_firm: DS.attr(),
  d_bank: DS.attr(),
  d_bank_account: DS.attr(),
  d_bank_officer: DS.attr(),
  d_bank_phone: DS.attr(),
  r1_name: DS.attr(),
  r1_address: DS.attr(),
  r1_city: DS.attr(),
  r1_state: DS.attr(),
  r1_zip: DS.attr(),
  r1_phone: DS.attr(),
  r2_name: DS.attr(),
  r2_address: DS.attr(),
  r2_city: DS.attr(),
  r2_state: DS.attr(),
  r2_zip: DS.attr(),
  r2_phone: DS.attr(),
  r3_name: DS.attr(),
  r3_address: DS.attr(),
  r3_city: DS.attr(),
  r3_state: DS.attr(),
  r3_zip: DS.attr(),
  r3_phone: DS.attr(),
  r4_name: DS.attr(),
  r4_address: DS.attr(),
  r4_city: DS.attr(),
  r4_state: DS.attr(),
  r4_zip: DS.attr(),
  r4_phone: DS.attr(),
  current_page: DS.attr(),
  dealer: DS.belongsTo('dealer'),
  email: DS.attr(),
  password: DS.attr(),
  app_terms: DS.attr(),
  app_signatures: DS.attr(),
  submit_to_corporate: DS.attr(),
  status: DS.attr(),

  auctions: Ember.computed('aution_1', function() {
    let auction_array = Ember.A();
    if(this.get('auction_1')) {
      auction_array = Ember.A(this.get('auction_1').split(', '));
    }
    return auction_array;
  }),
  wholesalers: Ember.computed('auction_2', function() {
    let wholesaler_array = Ember.A();
    if(this.get('auction_2')) {
      wholesaler_array= Ember.A(this.get('auction_2').split(', '));
    }
    return wholesaler_array;
  }),
  others: Ember.computed('auction_3', function() {
    let other_array = Ember.A();
    if(this.get('auction_3')) {
      other_array = Ember.A(this.get('auction_3').split(', '));
    }
    return other_array;
  }),

  find_current_page: Ember.computed('current_page', function() {
    let page = this.get('current_page');
    switch(page) {
      case 2:
        this.get('controller').set('places', true);
        break;
      case 3:
        this.get('controller').setProperties({'places': true, 'dealership': true});
        break;
      case 4:
        this.get('controller').setProperties({
          'places': true, 'dealership': true, 'financials': true
        });
        break;
      case 5:
        this.get('controller').setProperties({
          'places': true, 'dealership': true, 'financials': true, 'people': true
        });
        break;
      case 6:
        this.get('controller').setProperties({
          'places': true, 'dealership': true, 'financials': true,
          'people': true, 'references': true
        });
        break;
      default:
        break;
    }
  })
*/