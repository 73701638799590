define('dealer-portal/components/add-bank-account', ['exports', 'ember', 'dealer-portal/validations/bank-account', 'ember-changeset-validations', 'ember-changeset'], function (exports, _ember, _dealerPortalValidationsBankAccount, _emberChangesetValidations, _emberChangeset) {
  exports['default'] = _ember['default'].Component.extend({
    BankAccountValidations: _dealerPortalValidationsBankAccount['default'],
    store: _ember['default'].inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset['default'](this.get('newAccount'), (0, _emberChangesetValidations['default'])(_dealerPortalValidationsBankAccount['default']));
    },
    accountType: [{ value: 'Checking', id: 1 }, { value: 'Savings', id: 2 }, { value: 'Money Market', id: 3 }],

    actions: {
      submit: function submit() {
        var component = this;
        var account = this.get('changeset');
        if (account.get('isValid')) {
          this.attrs.save(account, component);
        }
      }
    }
  });
});