define('dealer-portal/utils/uuid-generator', ['exports', 'ember-uuid/utils/uuid-generator'], function (exports, _emberUuidUtilsUuidGenerator) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator['default'];
    }
  });
  Object.defineProperty(exports, 'v4', {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator.v4;
    }
  });
  Object.defineProperty(exports, 'v1', {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator.v1;
    }
  });
  Object.defineProperty(exports, 'parse', {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator.parse;
    }
  });
  Object.defineProperty(exports, 'unparse', {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator.unparse;
    }
  });
});