define('dealer-portal/components/auction-search', ['exports', 'ember', 'ember-changeset'], function (exports, _ember, _emberChangeset) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    searchByAddress: true,
    searchButtonLabel: _ember['default'].computed('searchByAddress', function () {
      return this.get('searchByAddress') ? 'Search By City, State, Zip' : 'Search By Current Location';
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('model');
      this.changeset = new _emberChangeset['default'](model);
    },

    didInsertElement: function didInsertElement() {
      document.getElementsByName('auctionSearch')[0].setAttribute('checked', true);
      // $('.auctionRadio1').next(':input:radio')[0].setAttribute('checked', true);
    },

    searchBy: [{ value: 'Search By City, State, Zip', id: true }, { value: 'Search By Current Location', id: false }],

    statesSelect: [{ id: 'AL', value: 'AL' }, { id: 'AK', value: 'AK' }, { id: 'AZ', value: 'AZ' }, { id: 'AR', value: 'AR' }, { id: 'CA', value: 'CA' }, { id: 'CO', value: 'CO' }, { id: 'CT', value: 'CT' }, { id: 'DE', value: 'DE' }, { id: 'DC', value: 'DC' }, { id: 'FL', value: 'FL' }, { id: 'GA', value: 'GA' }, { id: 'HI', value: 'HI' }, { id: 'ID', value: 'ID' }, { id: 'IL', value: 'IL' }, { id: 'IN', value: 'IN' }, { id: 'IA', value: 'IA' }, { id: 'KS', value: 'KS' }, { id: 'KY', value: 'KY' }, { id: 'LA', value: 'LA' }, { id: 'ME', value: 'ME' }, { id: 'MD', value: 'MD' }, { id: 'MA', value: 'MA' }, { id: 'MI', value: 'MI' }, { id: 'MN', value: 'MN' }, { id: 'MS', value: 'MS' }, { id: 'MO', value: 'MO' }, { id: 'MT', value: 'MT' }, { id: 'NE', value: 'NE' }, { id: 'NV', value: 'NV' }, { id: 'NH', value: 'NH' }, { id: 'NJ', value: 'NJ' }, { id: 'NM', value: 'NM' }, { id: 'NY', value: 'NY' }, { id: 'NC', value: 'NC' }, { id: 'ND', value: 'ND' }, { id: 'OH', value: 'OH' }, { id: 'OK', value: 'OK' }, { id: 'OR', value: 'OR' }, { id: 'PA', value: 'PA' }, { id: 'RI', value: 'RI' }, { id: 'SC', value: 'SC' }, { id: 'SD', value: 'SD' }, { id: 'TN', value: 'TN' }, { id: 'TX', value: 'TX' }, { id: 'US', value: 'US' }, { id: 'UT', value: 'UT' }, { id: 'VT', value: 'VT' }, { id: 'VA', value: 'VA' }, { id: 'WA', value: 'WA' }, { id: 'WV', value: 'WV' }, { id: 'WI', value: 'WI' }, { id: 'WY', value: 'WY' }, { id: 'OT', value: 'OT' }],

    radiusSelect: [{ id: 50, value: '50 miles' }, { id: 75, value: '75 miles' }, { id: 100, value: '100 miles' }, { id: 500, value: '500 miles' }],

    actions: {
      changeSearchType: function changeSearchType(value) {
        _ember['default'].set(this, 'searchByAddress', value === 'searchByAddress');
      },
      submit: function submit() {
        var search = this.get('changeset');
        this.attrs.search(search, this.get('searchByAddress'));
      }
    }
  });
});