define('dealer-portal/controllers/access-home/bank-accounts', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    showForm: true,
    allAuthorized: true,
    unauthorized: [],

    actions: {
      save: function save(changeset, component) {
        var self, failure, onSuccess;
        return regeneratorRuntime.async(function save$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              onSuccess = function onSuccess() {
                component.set('changeset.dealer_nick_name', '');
                component.set('changeset.bank_name', '');
                component.set('changeset.name_business', '');
                component.set('changeset.personal_name', '');
                component.set('changeset.account_type', '');
                component.set('changeset.routing_number', '');
                component.set('changeset.account_number', '');
              };

              failure = function failure(reason) {
                self.toast.error('Creation failed. Please check the form and ensure all values are correct.');
              };

              self = component;
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(changeset.save().then(onSuccess)['catch'](failure));

            case 5:
              this.transitionToRoute('access-home.bank-account-added');

            case 6:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      makeDefault: function makeDefault(account) {
        return true;
      },
      deleteAccount: function deleteAccount(account) {
        return true;
      },
      showConfirm: function showConfirm(account) {
        window.$('#delete-link-' + account.get('id')).toggle();
        window.$('#confirm-link-' + account.get('id')).toggle();
      },
      achAuthorization: function achAuthorization(termsSignature, component) {
        var _this = this;

        var accounts = this.get('unauthorized');
        var headers = {};
        this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        var self = this;
        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('bank-accounts/ach-authorization'), {
          method: 'put',
          headers: headers,
          data: {
            accounts: accounts,
            terms_signature: termsSignature
          }
        }).then(function (response) {
          _this.toast.success(response[0].message);
          _this.set('allAuthorized', true);
          _this.transitionToRoute('access-home.bank-accounts');
        });
      }
    }
  });
});