define('dealer-portal/validators/aa-id', ['exports'], function (exports) {
  exports['default'] = validateAaId;

  function validateAaId() /* options = {} */{
    return function (key, newValue) {
      if (!newValue.length || '5' === newValue.charAt(0)) {
        return true;
      } else {
        return 'Must be a Valid Auction Access ID that starts with the number 5';
      }
    };
  }
});