define("dealer-portal/routes/login", ["exports", "ember", "dealer-portal/config/environment"], function (exports, _ember, _dealerPortalConfigEnvironment) {
  exports["default"] = _ember["default"].Route.extend({
    session: _ember["default"].inject.service(),

    model: function model(params) {
      var _this = this;

      if (params.token) {
        var credentials = { identification: "_t", password: params.token };
        this.get("session").authenticate("authenticator:jwt", credentials).then(function () {
          _this.get("session").set("data.expired", false);

          if (_this.get("session.data.authenticated.access")) {
            _this.transitionTo("access-home.index");
          }
        });
      } else {
        if (_dealerPortalConfigEnvironment["default"].environment === "production") {
          window.location = "https://www.fpxus.com";
        }
      }

      return {};
    }
  });
});