define('dealer-portal/routes/access-home/bank-account-added', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    controllerName: 'access-home.bank-accounts',
    redirect: function redirect() {
      this.transitionTo('access-home.bank-accounts');
    },
    actions: {
      resetForm: function resetForm() {}
    }
  });
});