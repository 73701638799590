define("dealer-portal/models/transfer", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    userId: _emberData["default"].attr(),
    branchId: _emberData["default"].attr(),
    paymentType: _emberData["default"].attr(),
    unitId: _emberData["default"].attr(),
    accountId: _emberData["default"].attr(),
    amount: _emberData["default"].attr(),
    year: _emberData["default"].attr(),
    make: _emberData["default"].attr(),
    model: _emberData["default"].attr(),
    stock: _emberData["default"].attr(),
    serial: _emberData["default"].attr(),
    paymentFor: _emberData["default"].attr(),
    safeRt: _emberData["default"].attr(),
    safeAct: _emberData["default"].attr(),
    transferDate: _emberData["default"].attr(),
    actualTransferDate: _emberData["default"].attr(),
    accountNameForDealer: _emberData["default"].attr(),
    couponRedemption: _emberData["default"].attr()
  });
});
// user: DS.belongsTo('user')