define("dealer-portal/routes/access-home/index", ["exports", "ember", "moment", "accounting/format-money", "ember-i18n", "dealer-portal/utils/generated-file"], function (exports, _ember, _moment, _accountingFormatMoney, _emberI18n, _dealerPortalUtilsGeneratedFile) {
  exports["default"] = _ember["default"].Route.extend({
    session: _ember["default"].inject.service(),
    moment: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    controllerName: "access-home",

    userLevel: _ember["default"].computed(function () {
      return this.get("session").get("data").authenticated.level;
    }),

    model: function model() {
      return _ember["default"].RSVP.hash({
        units: this.store.query("unit", {
          filter: { scope: "current" }
        }),
        dealer: this.store.find("dealer", this.get("session").get("data").authenticated.dealerId)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      function buildUnitName(unit) {
        var unitSoldLabel = unit.get("sold") ? '<span class="sold-label">SOLD - </span>' : "";
        return self.get("userLevel") === "sales" ? unitSoldLabel + unit.get("name") : "<a class='view-unit-detail' href='/access-home/" + unit.get("id") + "'>" + unitSoldLabel + unit.get("name") + "</a>";
      }

      function checkSold(unit) {
        var unitSoldLabel = unit.get("sold") ? '<span class="sold-label">SOLD</span>' : "";
        return unitSoldLabel;
      }

      function styleAndFormatAsMoney(dollarAmount) {
        return '<span class="pull-right">' + (0, _accountingFormatMoney["default"])(dollarAmount) + "</span>";
      }

      var currentInventoryHeadings = [{
        key: "serialNumber",
        display: this.get("i18n").t("currentUnitPage.serialNumber").string
      }, { key: "lineType", display: "Line" }, {
        key: "stockNumber",
        display: this.get("i18n").t("currentUnitPage.stockNumber").string
      }, {
        key: "sold",
        display: this.get("i18n").t("currentUnitPage.sold").string
      }, {
        key: "year",
        display: this.get("i18n").t("currentUnitPage.year").string
      }, {
        key: "make",
        display: this.get("i18n").t("currentUnitPage.make").string
      }, {
        key: "model",
        display: this.get("i18n").t("currentUnitPage.model").string
      }, {
        key: "color",
        display: this.get("i18n").t("currentUnitPage.color").string
      }, {
        key: "currentPrincipal",
        display: this.get("i18n").t("currentUnitPage.principal").string
      }, {
        key: "curtailment",
        display: this.get("i18n").t("currentUnitPage.refloor").string
      }, {
        key: "payoff",
        display: this.get("i18n").t("currentUnitPage.currentPayoff").string
      }, {
        key: "dueOn",
        display: this.get("i18n").t("currentUnitPage.paymentDue").string
      }, {
        key: "pendingTransferInfo",
        display: this.get("i18n").t("currentUnitPage.pendingTransferInfo").string
      }, {
        key: "titleStatus",
        display: this.get("i18n").t("currentUnitPage.titleStatus").string
      }, {
        key: "daysFloored",
        display: this.get("i18n").t("unitShowPage.daysFloored").string
      }, {
        key: "",
        display: this.get("i18n").t("currentUnitPage.actions").string
      }];
      controller.set("inventoryHeadings", currentInventoryHeadings);
      var currentInventoryRows = [];
      var self = this;
      model.units.forEach(function (unit) {
        var titleStatus = unit.translatedTitleStatus;

        if (unit.get("titleFileUrl")) {
          titleStatus = "<span class=\"title-download\" data-id=\"" + unit.id + "\">" + unit.translatedTitleStatus + "</span>";
        }

        var unitHash = {
          serialNumber: "<a href='/access-home/" + unit.get("id") + "'>" + unit.get("serialNumber") + "</a>",
          lineType: unit.get("lineType"),
          stockNumber: unit.get("stockNumber"),
          sold: checkSold(unit),
          year: unit.get("year"),
          make: unit.get("make"),
          model: unit.get("model"),
          color: unit.get("color"),
          currentPrincipal: styleAndFormatAsMoney(unit.get("currentPrincipal")),
          curtailment: unit.get("refloorAllowed") ? styleAndFormatAsMoney(unit.get("curtailment")) : "No Refloor",
          payoff: styleAndFormatAsMoney(unit.get("payoff")),
          dueOn: (0, _moment["default"])(unit.get("dueOn")).format("MM/DD"),
          pendingTransferInfo: unit.get("pendingTransferInfo") ? unit.get("pendingTransferInfo") : "No transfer pending",
          titleStatus: titleStatus,
          daysFloored: unit.get("daysFloored"),
          actions: unit.get("achInitiated") ? "ACH Transfer Initiated" : self.get("userLevel") === "sales" ? "<a href='/access-home/payoff-calculator/" + unit.get("id") + "'>Payoff Calculator</a>" : "<a href='/access-home/ach-scheduling/" + unit.get("id") + "'>Make Payment</a> | " + "<a href='/access-home/payoff-calculator/" + unit.get("id") + "'>Payoff Calculator</a> | " + "<a href='/access-home/" + unit.get("id") + "'>View</a>"
        };

        console.log(unitHash);
        currentInventoryRows.pushObject(unitHash);
      });

      controller.set("userLevel", self.get("userLevel"));
      controller.set("inventoryRows", currentInventoryRows);

      _ember["default"].run.schedule("afterRender", this, function () {
        _ember["default"].$(".index-units-box").on("click", ".title-download", function () {
          var unitId = _ember["default"].$(this).data("id");
          (0, _dealerPortalUtilsGeneratedFile["default"])(self.get("session"), "units/" + unitId + "/title", {});
        });
      });
    }
  });
});