define('dealer-portal/routes/access-home/reports', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var userLevel = this.get('session').get('data').authenticated.level;
      if (userLevel === 'sales') {
        this.transitionTo('access-home.index');
      }
    },

    actions: {

      downloadPaidReport: function downloadPaidReport(reportType) {
        generatedFile(this.get('session'), 'units/paid-off-report', { start_on: this.get('fromDate'), end_on: this.get('toDate'),
          report_type: reportType });
      }

    }
  });
});