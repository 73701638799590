define('dealer-portal/models/dealer-user', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    username: _emberData['default'].attr(),
    firstName: _emberData['default'].attr(),
    lastName: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    email2: _emberData['default'].attr(),
    textMessageNumber: _emberData['default'].attr(),
    emailsFloor: _emberData['default'].attr(),
    emailsPayment: _emberData['default'].attr(),
    emailsComingDue: _emberData['default'].attr(),
    emailsLateCurtail: _emberData['default'].attr(),
    emailsAvail: _emberData['default'].attr(),
    status: _emberData['default'].attr(),
    dealer: _emberData['default'].belongsTo('dealer')
  });
});