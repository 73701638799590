define('dealer-portal/components/corporate-branch', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var component = this;

      this.get('store').query('branch', {
        filter: { scope: 'corporate' }
      }).then(function (branches) {
        component.set('branches', branches);
      });
    }
  });
});