define('dealer-portal/routes/access-home/uploads', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),

    queryParams: {
      showForm: {
        refreshModel: false
      }
    },
    showForm: true,

    model: function model() {
      return _ember['default'].RSVP.hash({
        dealerFiles: this.get('store').query('dealer-file', {
          dealer_id: this.get('session').get('data').authenticated.dealerId
        }),
        newDealerFile: this.get('store').createRecord('dealer-file')
      });
    }
  });
});