define("dealer-portal/templates/app/people/person", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 6
          }
        },
        "moduleName": "dealer-portal/templates/app/people/person.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "person_container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "person_left");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "person_right");
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "dealer-person", [], ["model", ["subexpr", "@mut", [["get", "model.firstObject", ["loc", [null, [3, 26], [3, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "married", ["subexpr", "@mut", [["get", "married", ["loc", [null, [3, 52], [3, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "spouseId", ["subexpr", "@mut", [["get", "currentPersonId", ["loc", [null, [3, 69], [3, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "dataEntered", "dataEntered", "dataEnteredWarning", ["subexpr", "@mut", [["get", "dataEnteredWarning", ["loc", [null, [4, 49], [4, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "save", ["subexpr", "action", ["save"], [], ["loc", [null, [5, 9], [5, 24]]], 0, 0], "rollback", ["subexpr", "action", ["rollback"], [], ["loc", [null, [5, 34], [5, 53]]], 0, 0]], ["loc", [null, [3, 4], [5, 55]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});