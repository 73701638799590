define('dealer-portal/controllers/access-home', ['exports', 'ember', 'dealer-portal/adapters/application', 'dealer-portal/utils/generated-file'], function (exports, _ember, _dealerPortalAdaptersApplication, _dealerPortalUtilsGeneratedFile) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    userLog: _ember['default'].inject.service(),
    queryParams: ['fromDate', 'toDate'],

    inventoryHeadings: [],
    inventoryRows: [],
    fromDate: null,
    toDate: null,
    transitionFrom: null,
    soldForm: false,
    unsoldForm: false,
    errorMessage: null,
    txnTitle: null,
    unitFiles: [],
    showForm: true,
    newDealerFile: null,
    nameError: false,
    fileError: false,
    saving: false,
    typeError: false,
    saveError: false,
    saveSuccess: false,
    lastUpload: null,
    showUnitFiles: true,
    unitId: null,

    dealerUsername: _ember['default'].computed(function () {
      return this.get('session').get('data').authenticated.dealer_first_name;
    }),

    headers: _ember['default'].computed(function () {
      var headers = {};
      this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      return headers;
    }),

    sold: _ember['default'].computed(function () {
      return this.get('model.unit.sold');
    }),

    actions: {
      searchDates: function searchDates(from, to) {
        this.setProperties({ fromDate: moment(from).format('YYYY-MM-DD'),
          toDate: moment(to).format('YYYY-MM-DD') });
        this.transitionToRoute('access-home.update-search', this.get('transitionFrom'));
      },
      searching: function searching() {
        var input = document.getElementById("search");
        var filter, table, tr, td, i, k, txtValue;
        filter = input.value.toUpperCase();
        table = document.getElementById("inventory-body");
        tr = table.getElementsByTagName("tr");
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td");
          for (k = 0; k < td.length; k++) {
            var column = td[k];
            txtValue = column.textContent || column.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
              break;
            } else {
              tr[i].style.display = "none";
            }
          }
        };
      },

      showSoldForm: function showSoldForm() {
        this.set('soldForm', true);
      },
      sort: function sort(sortBy) {
        if (sortBy.length > 0) {
          var ascending = this.toggleProperty('sortAscending');
          var units = this.get('model.units');
          if (ascending) {
            this.set('model.units', units.sortBy(sortBy));
          } else {
            this.set('model.units', units.sortBy(sortBy)).reverse();
          }
        }
      },
      markUnitSold: function markUnitSold(changeset, bosUFile, fundUFile) {
        var unit, self, headers;
        return regeneratorRuntime.async(function markUnitSold$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              this.toast.info('Scanning and saving Bill of Sale, Funding Notice, and ' + 'updating sold details for car.');
              // let self = this;
              // await unit.save().then(unit => {
              //   this.set('sold', true);
              //   this.set('soldForm', false);
              // });
              unit = {
                sold_to: changeset.get('soldTo'),
                sold_on: changeset.get('soldOn'),
                sale_type: changeset.get('saleType')
              };
              self = this;
              headers = this.get('headers');
              context$1$0.next = 6;
              return regeneratorRuntime.awrap(this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('units/' + this.get('model.unit.id') + '/sold'), {
                method: 'PUT',
                headers: headers,
                data: {
                  unit: unit
                }
              }).then(function (response) {
                if (response.data) {
                  // this.toast.info('Request to mark unit unsold submitted.');
                  // this.set('unsoldForm', false);
                  if (bosUFile.get('changes').length) {
                    bosUFile.save().then(function (bosFile) {
                      // this.toast.info('Scanning and saving Funding Notice');
                    });
                  }
                  if (fundUFile.get('changes').length) {
                    fundUFile.save().then(function (fundFile) {
                      // this.toast.info('Updating sold details for car');
                    });
                  }
                  _this.set('sold', true);
                  _this.set('soldForm', false);
                } else {
                  _this.toast.error('There was an error during the process of marking ' + 'the car sold. Please try again or contact your branch.');
                }
              }));

            case 6:
              return context$1$0.abrupt('return', context$1$0.sent);

            case 7:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      markUnsold: function markUnsold() {
        this.set('unsoldForm', true);
      },
      markUnitUnsold: function markUnitUnsold(changeset) {
        var _this2 = this;

        var self = this;
        var headers = this.get('headers');
        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('units/' + this.get('model.unit.id') + '/mark-unsold'), {
          method: 'GET',
          headers: headers,
          data: {
            unsold_reason: changeset.get('unsoldReason')
          }
        }).then(function (response) {
          _this2.toast.info('Request to mark unit unsold submitted.');
          _this2.set('unsoldForm', false);
        });
      },
      uploadUnitFile: function uploadUnitFile(changeset) {
        return regeneratorRuntime.async(function uploadUnitFile$(context$1$0) {
          var _this3 = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              changeset.save().then(function (file) {
                _this3.toast.info('Scanning and saving Uploaded File');
                _this3.setProperties({ showUnitFiles: false, unitId: _this3.get('model.unit.id'),
                  transitionFrom: 'show', lastUpload: file.get('name') + ' - ' + file.get('ufile_file_name') });
                _this3.transitionToRoute('access-home.reset-route');
              })['catch'](function (error) {
                _this3.set('errorMessage', error);
                _this3.toast.error(_this3.get('errorMessage'));
              });

            case 1:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      downloadTxnReports: function downloadTxnReports(reportName, reportType) {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'dealer/txn-reports', { start_on: this.get('fromDate'), end_on: this.get('toDate'),
          report_name: reportName, report_type: reportType });
      },
      downloadReservesReport: function downloadReservesReport(reportType) {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'dealer/reserves-report', { report_type: reportType });
      },
      downloadCurrentInventory: function downloadCurrentInventory() {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'dealer/current-inventory-report', {});
      },
      downloadComingDueReport: function downloadComingDueReport(reportType) {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'units/coming-due-report', {
          report_type: reportType
        });
      },
      downloadPaidReport: function downloadPaidReport(reportType) {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'units/paid-off-report', { start_on: this.get('fromDate'), end_on: this.get('toDate'),
          report_type: reportType });
      },
      downloadSoldReport: function downloadSoldReport(reportType) {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'units/sold-report', {
          report_type: reportType
        });
      },
      downloadPreviousInventoryReport: function downloadPreviousInventoryReport(reportType) {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'units/previous-inventory-report', { start_on: this.get('fromDate'), end_on: this.get('toDate'),
          report_type: reportType });
      },
      downloadTitle: function downloadTitle(unitId) {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'units/' + unitId + '/title', {});
      },
      printReceipt: function printReceipt(txnId) {
        (0, _dealerPortalUtilsGeneratedFile['default'])(this.get('session'), 'txns/' + txnId + '/payment-receipt', {});
      }
    }
  });
});