define('dealer-portal/validations/geolocate', ['exports', 'ember-changeset-validations/validators'], function (exports, _emberChangesetValidationsValidators) {
  /* globals requirejs */
  requirejs.entries['ember-validations/messages'] = requirejs.entries['ember-changeset-validations/utils/messages'];

  exports['default'] = {
    street: [(0, _emberChangesetValidationsValidators.validatePresence)({ presence: true }), (0, _emberChangesetValidationsValidators.validateLength)({ min: 4 })],
    city: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ min: 4 })],
    state: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ min: 2 })],
    zip: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ min: 5 })]
  };
});