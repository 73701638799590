define('dealer-portal/routes/access-home/reports/coming-due', ['exports', 'ember', 'moment', 'accounting/format-money', 'ember-i18n'], function (exports, _ember, _moment, _accountingFormatMoney, _emberI18n) {
  exports['default'] = _ember['default'].Route.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    moment: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    controllerName: 'access-home',
    model: function model() {
      return _ember['default'].RSVP.hash({
        units: this.get('store').query('unit', {
          filter: { scope: 'coming_due' }
        }),
        dealer: this.get('store').find('dealer', this.get('session').get('data').authenticated.dealerId)
      });
    },
    setupController: function setupController(controller, model) {
      var i18n = this.get('i18n');
      this._super.apply(this, arguments);
      var comingDueInventoryHeadings = [{ key: 'dueOn', display: this.get('i18n').t('reports.dueOn').string }, { key: 'stockNumber', display: this.get('i18n').t('reports.stockNumber').string }, { key: 'serialNumber', display: this.get('i18n').t('reports.serialNumber').string }, { key: 'lineType', display: this.get('i18n').t('reports.lineType').string }, { key: 'name', display: this.get('i18n').t('reports.car').string }, { key: 'daysFloored', display: this.get('i18n').t('reports.days').string }, { key: 'payoff', display: this.get('i18n').t('reports.payoff').string }, { key: 'curtailment', display: this.get('i18n').t('reports.refloor').string }, { key: 'currentPrincipal', display: this.get('i18n').t('reports.principal').string }, { key: 'titleStatus', display: this.get('i18n').t('reports.title').string }, { key: '', display: this.get('i18n').t('reports.actions').string }];

      controller.set('inventoryHeadings', comingDueInventoryHeadings);
      var comingDueInventoryRows = [];

      function buildUnitName(unit) {
        var unitSoldLabel = unit.get('sold') ? '<span class="sold-label">SOLD - </span>' : '';
        return "<a class='view-unit-detail' href='/access-home/" + unit.get('id') + "'>" + unitSoldLabel + unit.get('name') + "</a>";
      }
    }
  });
});