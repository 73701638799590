define('dealer-portal/components/upload-multiple-files', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    actions: {
      uploadMultiple: function uploadMultiple() {
        var $blankInputForm = _ember['default'].$('#uploadFormInputs').clone().find("input").val("").end();
        var $uploadButtons = _ember['default'].$('#upload-buttons');

        $blankInputForm.prependTo($uploadButtons);
      },
      submit: function submit(stuff) {
        console.log("Submitting these form elements: ", stuff);
      }
    }
  });
});