define('dealer-portal/routes/auctions/index', ['exports', 'ember', 'dealer-portal/mixins/reset-scroll-position'], function (exports, _ember, _dealerPortalMixinsResetScrollPosition) {
  // import API from '../../adapters/application';

  exports['default'] = _ember['default'].Route.extend({
    maps: _ember['default'].inject.service(),
    // ajax: Ember.inject.service(),

    // city: null,
    // state: null,
    // zip: null,
    // radius: null,

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        auctions: null,
        search: { city: null, state: null, zip: null, radius: null },
        isIndex: 'true',
        isSearch: 'false'
      });
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.set('auctionResults', []);
      },
      didTransition: function didTransition(transition) {
        // Ember.run.scheduleOnce('afterRender', this, function(){
        //   let map = this.get('maps').get('mapUtil').get('map');
        //   map.setZoom(4.495);
        // });
      },
      redirectToShow: function redirectToShow(auctionId, markers) {
        return regeneratorRuntime.async(function redirectToShow$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get('maps').auctionMarkers());

            case 2:
              this.transitionTo('auctions.show', auctionId);

            case 3:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }

    }
  });
});
// search: function() {
//   alert('hitting search');
//   let city = this.get('city');
//   let state = this.get('state');
//   let zip = this.get('zip');
//   let data = { city: city, state: state, zip: zip };
//   let self = this;
//   var headers = {};
//   this.get('session').authorize(
//     'authorizer:token', (headerName, headerValue) => {
//       headers[headerName] = headerValue;
//     }
//   );
//   console.log(headers);

//   return this.get('ajax').request(
//     new API().fullURL('auctions/search'), {
//       headers: headers,
//       method: 'GET',
//       data: { data }
//     }).then(
//     response => {
//       console.log('response ' + response);
//   });
// }