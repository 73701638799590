define('dealer-portal/models/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  var schema = {
    from: _emberData['default'].attr(),
    subject: _emberData['default'].attr(),
    phone: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    body: _emberData['default'].attr(),
    branch_id: _emberData['default'].attr(),
    dealer_user_id: _emberData['default'].attr()
  };

  exports.schema = schema;
  exports['default'] = _emberData['default'].Model.extend(schema);
});