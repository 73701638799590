define('dealer-portal/routes/access-home/payoff-calculator', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    // controllerName: 'access-home.bank-accounts',
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        unit: this.get('store').findRecord('unit', params['id']),
        dealer: this.store.find('dealer', this.get('session').get('data').authenticated.dealerId)
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.setProperties({ unitName: model.unit.get('name'), dealerName: model.dealer.get('name'), unitId: model.unit.get('id') });
    }
  });
});