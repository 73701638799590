define('dealer-portal/helpers/trim', ['exports', 'ember-cli-string-helpers/helpers/trim'], function (exports, _emberCliStringHelpersHelpersTrim) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersTrim['default'];
    }
  });
  Object.defineProperty(exports, 'trim', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersTrim.trim;
    }
  });
});