define('dealer-portal/routes/app-simple/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    controllerName: 'app-simple',
    templateName: 'app-simple/show',
    queryParams: {
      showForm: {
        refreshModel: false
      },
      appId: {
        refreshModel: false
      },
      dealerId: {
        refreshModel: false
      }
    },
    appId: null,
    dealerId: null,
    showForm: null,

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        dealerFiles: this.get('store').query('dealer-file', {
          dealer_id: params['dealerId']
        }),
        newDealerFile: this.get('store').createRecord('dealer-file'),
        app: this.get('store').findRecord('app', params['appId'])
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var branch = model.app.get('dealer').get('branch');
      controller.setProperties({ branchName: branch.get('name'), branchFax: branch.get('fax').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || '(405) 605-6998', branchEmail: branch.get('id') == 17 || branch.get('email') == '' ? 'contracting@fpxus.com' : branch.get('email') });
    },

    actions: {
      didTransition: function didTransition() {
        // this.get('controller').set('showForm', true);
      }
    }

  });
});