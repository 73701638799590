define("dealer-portal/templates/access-home/prior-units", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "dealer-portal/templates/access-home/prior-units.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-fluid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row units-index-main");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "previously-floored-box");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "home");
        dom.setAttribute(el4, "class", "tab-pane fade in active");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5, "class", "index-unit-title");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "index-units-box-mobile");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["currentUnitPage.previouslyFlooredTitle"], [], ["loc", [null, [5, 37], [5, 83]]], 0, 0], ["inline", "sort-filter-table", [], ["model", ["subexpr", "@mut", [["get", "model.units", ["loc", [null, [6, 34], [6, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "inventoryRows", ["subexpr", "@mut", [["get", "inventoryRows", ["loc", [null, [6, 60], [6, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "inventoryHeadings", ["subexpr", "@mut", [["get", "inventoryHeadings", ["loc", [null, [7, 28], [7, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "fromDate", ["subexpr", "@mut", [["get", "fromDate", ["loc", [null, [7, 55], [7, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "toDate", ["subexpr", "@mut", [["get", "toDate", ["loc", [null, [7, 71], [7, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "searchDates", ["subexpr", "action", ["searchDates"], [], ["loc", [null, [8, 22], [8, 44]]], 0, 0], "dateFiltered", true, "inventoryRowsEmpty", "No Cars In Previous Inventory", "downloadPreviousInventoryReport", ["subexpr", "action", ["downloadPreviousInventoryReport"], [], ["loc", [null, [10, 42], [10, 84]]], 0, 0], "reportName", "previousInventory"], ["loc", [null, [6, 8], [12, 10]]], 0, 0], ["inline", "mobile-prior-units", [], ["units", ["subexpr", "@mut", [["get", "model.units", ["loc", [null, [16, 33], [16, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "inventoryRows", ["subexpr", "@mut", [["get", "inventoryRows", ["loc", [null, [16, 59], [16, 72]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 6], [16, 74]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});