define('dealer-portal/routes/app/dealer-places', ['exports', 'ember'], function (exports, _ember) {
  // import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

  exports['default'] = _ember['default'].Route.extend({
    maps: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    geocoded: null,
    app: null,
    places: _ember['default'].A(),
    // authenticationCheck: true,

    model: function model() {
      var currentDealer = this.get('session').get('data').authenticated.dealerId;
      var places = this.get('places');
      if (currentDealer) {
        places = this.get('store').query('dealer-place', { 'dealer_id': currentDealer });
      }
      return new _ember['default'].RSVP.Promise(function (resolve) {
        resolve(places);
      });
    },
    observeSession: (function () {
      // this.controller.set('refresh', true);
      console.log(this.get('authenticationCheck'));
      this.send('sessionChanged');
    }).observes('session.isAuthenticated'),

    actions: {
      didTransition: function didTransition() {
        var maps = this.get('maps');
        var geocoded = maps.geocoded;
        var controller = this.get('controller');
        this.set('geocoded', geocoded);
        controller.set('inputForm', false);
        console.log('calling didTransition');
        // this.controller.set('refresh', true);
        if (controller.get('refresh')) {
          this.refresh();
          controller.set('refresh', false);
        }
        // if(Ember.isEmpty(this.get('places'))) {
        //   this.get('controller').set('inputForm', true);
        // }
        _ember['default'].run.schedule('afterRender', this, function () {
          this.get('maps').refreshMap();
          // let placesCount = this.get('controller').get('selected_count');
          var placesCount = this.modelFor('app.dealer-places').get('length');
          controller.set('selected_count', placesCount);
          // window.$('#number_of_lots').val(placesCount);
        });
        // Ember.run.scheduleOnce('afterRender', this, function(){
        //   if ($('#places_container').length) {
        //     var height = $(window).height();
        //     $('#places_container').css('height', height);

        //     $('html, body').animate({scrollTop: $('#places_container').position().top}, 1000);
        //     $('#nav_container').addClass('nav_container_fixed');
        //   }
        // });
      },
      sessionChanged: function sessionChanged() {
        // this.get('maps').set('markers', Ember.A());
        // this.get('maps').refreshMap(authenticated);
        console.log(_ember['default'].$('.map-container'));
        var map = _ember['default'].$('.map-container .map');
        console.log('result ');
        console.log(map);
        map.detach();
        this.refresh();
        // this.controller.set('refresh', true);
        // let authenticated = this.get('session').get('isAuthenticated');
      }
    }
  });
});