define('dealer-portal/controllers/app/dealer-places/dealer-place', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    maps: _ember['default'].inject.service(),
    inputForm: true,
    dealerPlacesController: _ember['default'].inject.controller('dealer-places'),
    primaryPlaced: _ember['default'].computed('primaryPlaced', function () {
      return this.get('dealerPlacesController').primaryPlaced;
    }),
    observeSession: (function () {
      this.send('sessionChanged');
    }).observes('session.isAuthenticated'),

    actions: {
      updateLotsSelect: function updateLotsSelect(value) {
        this.set('value', value);
        this.set('selected_count', value);
      }
    }

  });
});
// save(place) {
//   // var place = this.store.peekAll('dealer-place').findBy('associated_marker', this.get('current_place'));
//   let new_place = place;
//   let self = this;
//   if(place.get('primary')) {
//     self.set('primaryPlaced', true);
//   }

//   new_place.save().then(() => {
//     let markers = this.get('markers');
//     let marker = markers.objectAt(new_place.associated_marker);
//     markers.splice(new_place.associated_marker, 1);
//     // alert(marker.lot_name);
//     // alert(new_place.id);
//     // marker.place_id = place.id;

//     Ember.set(marker, 'place_id', new_place.id);
//     // Ember.set(marker, 'infoWindow', { content: '<p>Lot ' + marker.lot_count + '</p>' +
//     //   '<p>' + new_place.get('name') + '<br>' +
//     //   new_place.get('address1') + '<br>' + new_place.get('city') + ', ' +
//     //   new_place.get('state') + ', ' + new_place.get('zip') + '</br>' +
//     //   new_place.id +
//     //   '</p><br>Double Click marker icon to edit this location',
//     //   visible: false });
//   // Ember.set(marker, 'infoWindow', { content: '<p>Lot ' + marker.lot_count + '</p>' +
//   //     '<p>' + new_place.get('name') + '<br>' +
//   //     new_place.get('address1') + '<br>' + new_place.get('city') + ', ' +
//   //     new_place.get('state') + ', ' + new_place.get('zip'), visible: false });
//   //   markers.pushObject(marker);
//     // Ember.set(marker, 'infoWindow', 'test');
//     // alert(marker.place_id);
//     this.transitionTo('app.dealer-places');
//   });
// },