define('dealer-portal/controllers/app/references', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    appController: _ember['default'].inject.controller('app'),

    actions: {
      add_reference: function add_reference() {
        var count = this.get('reference_count');
        count += 1;
        this.set('count', count);
        this.set('r_count', 'r' + count);
        this.set('r_count_name', 'r' + count + '_name');
        this.set('r_count_address', 'r' + count + '_address');
        this.set('r_count_city', 'r' + count + '_city');
        this.set('r_count_state', 'r' + count + '_state');
        this.set('r_count_zip', 'r' + count + '_zip');
        this.set('r_count_phone', 'r' + count + '_phone');
        this.set('title', 'Reference #' + count);
        this.set('label_title', 'Reference\'s');
      }
    }
  });
});