define("dealer-portal/components/request-floor-add-title-file", ["exports", "ember", "ember-changeset", "ember-i18n"], function (exports, _ember, _emberChangeset, _emberI18n) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("model", this.get("model"));
      this.changeset = new _emberChangeset["default"](this.get("store").createRecord("unit-file"));
    },

    fileTypes: _ember["default"].computed("i18n.locale", function () {
      return [{ value: this.get("i18n").t("unitFileUpload.title"), id: "Title" }];
    }),

    actions: {
      updateFileTypeSelect: function updateFileTypeSelect(value) {
        var selected = value.target.value;
        _ember["default"].set(this, "value", selected);
        this.set("nameError", false);
      },
      fileSelect: function fileSelect(value) {
        this.set("typeError", false);
        this.changeset.set("name", "Title");
        this.set("nameError", false);
      },
      expired: function expired() {},
      cancel: function cancel() {
        this.set("showAddFileForm", false);
        this.set("showSubmit", true);
      },
      submit: function submit() {
        var _this = this;

        var self = this;
        var upload = this.get("changeset");
        if (upload.get("name") && document.getElementById("file-field-title").files[0]) {
          (function () {
            var file = document.getElementById("file-field-title").files[0];
            var allowed = ["text/plain", "application/pdf", "image/png", "image/jpeg"];
            var found = false;
            allowed.forEach(function (type) {
              if (file.type.match(type)) {
                found = true;
              }
            });
            if (found) {
              upload.set("ufile", file);

              _this.set("showAddTitleForm", false);
              _this.set("titleUploaded", true);

              console.log("title added", file);
              $(".file-upload-button").attr("disabled", "disabled").val("Adding to List...");
              _this.attrs.save(upload);
            } else {
              _this.set("typeError", true);
            }
          })();
        } else {
          if (!upload.get("name")) {
            this.set("nameError", true);
          } else {
            this.set("fileError", true);
          }
        }
      }
    }
  });
});