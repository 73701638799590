define("dealer-portal/templates/components/ach-terms", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "dealer-portal/templates/components/ach-terms.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "hdr");
        var el2 = dom.createTextNode("Pay Now Terms and Conditions");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("ACH Transfers");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\nFloorplan Xpress and Great American Credit Acceptance Company are now offering\nACH Transfers directly from our website. The following information walks you\nthrough how this system works and our privacy policy.\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("What is an ACH Transfer?");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\nACH stands for Automated Clearing House. This is the system that allows our\ncompanies to request a money transfer directly from your bank account to ours.\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("How does it work?");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\nOnce you agree to our terms and conditions and our privacy policy located\nbelow you will be able to save bank account information on our website. This\naccount information is safe and protected through a secure connection\nbetween you and our server. Once on our server the information is protected\nbehind a firewall to ensure that no one can access it.\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\nWhen you log in to floorplanxpress.com you will see a \"Make Payment\" link located\nnext to each unit, and next to your total. You will be able to choose to pay\neither the current balance or to payoff the entire floor. Then we will be\nnotified of your online payment.\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\nOur staff will work with our bank to begin the transfer process. This process\nwill take 3 - 5 business days.\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("Your Privacy");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\nYour privacy is our highest priority. You may view our Privacy Policy by\nclicking on the link at the bottom of this page.\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("Terms and Conditions");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\nYou may utilize the ACH Payment Service to make requests for the payment of\nyour Current Day and Future Dated ACH Payments, and Repeating ACH Payments\nfrom your Pay From account. We may, from time to time, establish minimum and\nmaximum transactional and daily dollar limits on ACH Payments that may be\ninitiated via the Online Service which we may impose in our discretion and\nat our option. The \"General Terms Applicable to Bill Payment and Transfer\nServices\" section of the Initial Agreement shall apply equally to ACH\nPayments issued hereunder, as if those terms were restated herein, unless\nthe context clearly indicates otherwise.\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});