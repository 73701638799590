define('dealer-portal/routes/app/references', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('app');
    },

    controllerName: 'app',

    observeSession: (function () {
      this.send('sessionChanged');
    }).observes('session.isAuthenticated'),

    actions: {
      didTransition: function didTransition() {}
    }
  });
});
// sessionChanged: function() {
//   console.log('in route sessionChanged');
//   // console.log(this.get('model'));
//   // this.get('model').refresh();
//   // this.get('target.route').refresh();
// }