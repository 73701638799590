define('dealer-portal/controllers/app/dealer-places', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    maps: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    lot_count: 1,
    selected_count: 1,
    current_selection_id: null,
    current_place: null,
    inputForm: false,

    lat: 39.09024,
    lng: -95.912891,
    zoom: 4,
    editingPlace: true,
    marker_count: 0,
    markerDropped: false,
    allowTransition: true,
    dataEnteredWarning: false,
    // markers: Ember.A([]),
    appController: _ember['default'].inject.controller('app'),
    // primaryPlaced: false,
    refresh: false,
    // primaryPlaced: Ember.computed('primaryPlaced', function() {
    //   let places = this.get('model');
    //   console.log('places length' + places.length);
    //   let self = this;
    //   if(places.get('length') > 1) {
    //     // this.set('selected_count', places.get('length'));
    //     console.log('am I getting in this if?');
    //     self.send('updateLotsSelect', places.get('length'));
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }),
    primaryPlaced: _ember['default'].computed('primaryPlaced', function () {
      var dealer_primary = this.get('appController').get('primaryPlaceId');
      var placed = false;
      if (dealer_primary) {
        placed = true;
      }

      console.log('dealer');
      console.log(dealer_primary);
      return placed;
    }),
    // markers: Ember.computed('markers', function() {
    //   return this.get('maps').get('markers');
    // }),
    // primaryPlaced: true,

    geolocatedPlaces: _ember['default'].computed('geolocatedPlaces', function () {
      if (localStorage.places) {
        return JSON.parse(localStorage.places);
      } else {
        return [];
      }
    }),

    number_of_lots: [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 }, { label: '6', value: 6 }, { label: '7', value: 7 }, { label: '8', value: 8 }, { label: '9', value: 9 }, { label: '10', value: 10 }, { label: 'More than 10', value: 11 }],
    observeSession: (function () {
      console.log('in dealer-place controller');
      this.send('sessionChanged');
    }).observes('session.isAuthenticated'),

    actions: {
      updateLotsSelect: function updateLotsSelect(value) {
        this.set('value', value);
        this.set('selected_count', value);
      },
      addNewPlace: function addNewPlace() {
        this.get('appController').set('showAppSave', false);
        this.transitionToRoute('app.dealer-places.dealer-place', 'new');
      },
      sessionChanged: function sessionChanged() {
        console.log('in controller sessionChanged');
        return true;
      },
      dataEntered: function dataEntered() {
        this.set('allowTransition', false);
      },
      save: function save(place) {
        var _this = this;

        var new_place = place;
        var self = this;
        if (place.get('primary')) {
          self.set('primaryPlaced', true);
        }

        new_place.save().then(function () {
          // let markers = this.get('markers');
          // console.log('index ' + new_place.associated_marker);
          // let marker = markers[new_place.associated_marker];
          // console.log('marker ' + marker);
          // markers.splice(new_place.associated_marker, markers.length);

          // Ember.set(marker, 'place_id', new_place.id);

          // Ember.set(marker, 'infoWindow', { content: '<p>Lot ' +
          //   marker.lot_count + '</p>' + '<p>' + new_place.get('name') + '<br>' +
          //   new_place.get('address1') + '<br>' + new_place.get('city') + ', ' +
          //   new_place.get('state') + ', ' + new_place.get('zip'), visible: false });
          // markers.pushObject(marker);
          _this.set('inputForm', false);
          _this.get('appController').set('showAppSave', true);
          _this.set('allowTransition', true);
          _this.set('dataEnteredWarning', false);
          _this.transitionToRoute('app.dealer-places');
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.set('inputForm', false);
        this.get('appController').set('showAppSave', true);
        this.set('allowTransition', true);
        this.set('dataEnteredWarning', false);
        this.transitionToRoute('app.dealer-places');
      },
      activeMarker: function activeMarker(marker) {
        var selected_marker = marker;
        this.set('dealership_name', selected_marker.get('name'));
        this.set('current_place', this.get('marker_index'));
        this.set('inputForm', true);
        this.set('markerDropped', true);
        this.get('appController').set('showAppSave', false);
        this.transitionToRoute('app.dealer-places.dealer-place', selected_marker.place_id);
      },
      activeMarkerFromLink: function activeMarkerFromLink(place) {
        this.set('dealership_name', place.get('name'));
        this.set('current_place', place.get('associated_marker'));
        this.set('inputForm', true);
        this.set('markerDropped', true);
        this.get('appController').set('showAppSave', false);
        this.transitionToRoute('app.dealer-places.dealer-place', place.id);
      }
    }
  });
});