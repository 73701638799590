define("dealer-portal/routes/access-home/prior-units", ["exports", "ember", "moment", "accounting/format-money", "ember-i18n"], function (exports, _ember, _moment, _accountingFormatMoney, _emberI18n) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports["default"] = _ember["default"].Route.extend({
    session: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    queryParams: {
      fromDate: {
        refreshModel: false
      }
    },
    fromDate: null,
    toDate: null,

    controllerName: "access-home",
    model: function model(params) {
      var from = null;
      var to = null;
      this.setProperties({
        fromDate: params["fromDate"],
        toDate: params["toDate"]
      });
      if (params["fromDate"] == null) {
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear() - 1;
        from = mm + "/" + dd + "/" + yyyy;
        this.set("fromDate", (0, _moment["default"])(from).format("YYYY-MM-DD"));
      }
      if (params["toDate"] == null) {
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear();
        to = mm + "/" + dd + "/" + yyyy;
        this.set("toDate", (0, _moment["default"])(to).format("YYYY-MM-DD"));
      }
      return _ember["default"].RSVP.hash({
        units: this.store.query("unit", {
          filter: {
            scope: "paid_off",
            fromDate: this.get("fromDate"),
            toDate: this.get("toDate")
          }
        }),
        dealer: this.store.find("dealer", this.get("session").get("data").authenticated.dealerId)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      function styleAndFormatAsMoney(dollarAmount) {
        return '<span class="pull-right">' + (0, _accountingFormatMoney["default"])(dollarAmount) + "</span>";
      }

      function styleAndFormatAsMoneyAlt(dollarAmount) {
        return '<span style="padding-left:2.0em;">' + (0, _accountingFormatMoney["default"])(dollarAmount) + "</span>";
      }

      function styleAndFormatAlt(dollarAmount) {
        return '<span style="padding-left:1.9em;">' + dollarAmount + "</span>";
      }

      var i18n = this.get("i18n");

      var previousInventoryHeadings = [{
        key: "serialkNumber",
        display: '<span class="first-heading">' + this.get("i18n").t("currentUnitPage.serialNumber").string + "</span>"
      }, {
        key: "stockNumber",
        display: this.get("i18n").t("currentUnitPage.stockNumber").string
      }, { key: "vin", display: this.get("i18n").t("currentUnitPage.vin").string }, {
        key: "lineType",
        display: this.get("i18n").t("currentUnitPage.lineType").string
      }, {
        key: "name",
        display: this.get("i18n").t("currentUnitPage.car").string
      }, {
        key: "payoffOn",
        display: this.get("i18n").t("currentUnitPage.paidOffOn").string
      }, {
        key: "paidPrincipal",
        display: this.get("i18n").t("currentUnitPage.principalPaid").string
      }, {
        key: "paidFees",
        display: this.get("i18n").t("currentUnitPage.feesPaid").string
      }, {
        key: "paidInterest",
        display: this.get("i18n").t("currentUnitPage.interestPaid").string
      }, {
        key: "paidReserves",
        display: this.get("i18n").t("currentUnitPage.addedToReserves").string
      }, {
        key: "amountWrittenOff",
        display: this.get("i18n").t("currentUnitPage.totalWrittenOff").string
      }];
      if (model.dealer.get("insuranceCoverageElected") === true) {
        previousInventoryHeadings.splice(9, 0, {
          key: "paidInsuranceFees",
          display: this.get("i18n").t("currentUnitPage.insuranceFeesPaid").string
        });
      }

      controller.set("inventoryHeadings", previousInventoryHeadings);
      var previousInventoryRows = [];
      model.units.forEach(function (unit) {
        var unitHash = _extends({
          serialNumber: unit.get("serialNumber"),
          stockNumber: styleAndFormatAlt(unit.get("stockNumber")),
          vin: unit.get("vin"),
          lineType: unit.get("lineType"),
          name: "<a href='/access-home/" + unit.get("id") + "'>" + unit.get("name") + "</a>",
          payoffOn: "<span class='timestamp' style='display: none;'>" + (0, _moment["default"])(unit.get("payoffOn")).format("x") + "</span> " + (0, _moment["default"])(unit.get("payoffOn")).format("MM/DD/YYYY"),
          paidPrincipal: styleAndFormatAsMoney(unit.get("paidPrincipal")),
          paidFees: styleAndFormatAsMoney(unit.get("paidFees")),
          paidInterest: styleAndFormatAsMoneyAlt(unit.get("paidInterest"))
        }, model.dealer.get("insuranceCoverageElected") === true ? {
          paidInsuranceFees: styleAndFormatAsMoneyAlt(unit.get("paidInsuranceFees"))
        } : {}, {
          paidReserves: styleAndFormatAsMoneyAlt(unit.get("paidReserves")),
          amountWrittenOff: styleAndFormatAsMoneyAlt(unit.get("amountWrittenOff"))
        });
        previousInventoryRows.pushObject(unitHash);
      });
      controller.set("inventoryRows", previousInventoryRows);
      if (this.get("fromDate") != controller.get("fromDate")) {
        controller.set("fromDate", this.get("fromDate"));
      }
      if (this.get("toDate") != controller.get("toDate")) {
        controller.set("toDate", this.get("toDate"));
      }
      controller.set("transitionFrom", "prior-units");
    }
  });
});