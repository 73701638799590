define('dealer-portal/components/google-map', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    maps: _ember['default'].inject.service(),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      var self = this;
      var data = this.get('data');
      var maps = this.get('maps');
      maps.createMarkers(data, this.isIndex, this.isAppMap);
      var mapElement = maps.getMapElement(this.isIndex);
      var markers = maps.getMarkers();
      // let polygons = maps.getPolygons();
      // if(this.isIndex) {
      //   for(let x = 0; x < polygons.length; x++) {
      //     window.google.maps.event.addListener(polygons[x], 'click', function() {
      //       self.get('controller').sendAction('redirectToShow', polygons[x].get('branchId'));
      //     });
      //   }
      // }
      if (this.isBranchIndex) {
        this.$('.map-container').addClass('index').addClass('col-md-8');
      }
      if (this.isAppMap) {
        var _loop = function (x) {
          window.google.maps.event.addListener(markers[x], 'click', function () {});
          window.google.maps.event.addListener(markers[x], 'dblclick', function () {
            self.send('activeMarker', markers[x]);
          });
        };

        for (var x = 0; x < markers.length; x++) {
          _loop(x);
        }
      }
      if (this.$('.map-container .map').length < 1) {
        this.$('.map-container').append(mapElement);
      } else {
        maps.refreshMap();
      }
    },
    actions: {
      redirectToShow: function redirectToShow(branchId) {},
      geolocate: function geolocate(byIP) {
        var self, address1, city, state, zip, geocoded, places;
        return regeneratorRuntime.async(function geolocate$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              self = this;
              address1 = this.get('address1');
              city = this.get('city');
              state = this.get('state');
              zip = this.get('zip');
              geocoded = '';

              if (!(city && state || zip || byIP)) {
                context$1$0.next = 16;
                break;
              }

              context$1$0.next = 9;
              return regeneratorRuntime.awrap(self.get('maps').getBranch(address1, city, state, zip, this.get('data')));

            case 9:
              geocoded = context$1$0.sent;
              places = [];

              places.pushObject(geocoded);
              localStorage.setItem('places', JSON.stringify(places));
              localStorage.setItem('map', JSON.stringify(this.get('maps').map));
              this.get('maps').set('places', places);
              this.get('controller').sendAction('redirectToShow', geocoded.branchId);

            case 16:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      activeMarker: function activeMarker(marker) {
        this.get('controller').sendAction('activeMarker', marker);
      }
    }
  });
});