define('dealer-portal/validations/contact', ['exports', 'ember-changeset-validations/validators'], function (exports, _emberChangesetValidationsValidators) {
  /* globals requirejs */
  requirejs.entries['ember-validations/messages'] = requirejs.entries['ember-changeset-validations/utils/messages'];

  exports['default'] = {
    from: [(0, _emberChangesetValidationsValidators.validatePresence)({ presence: true }), (0, _emberChangesetValidationsValidators.validateLength)({ min: 4 })],
    subject: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateLength)({ min: 10 })],
    phone: (0, _emberChangesetValidationsValidators.validateFormat)({ type: 'phone' }),
    email: (0, _emberChangesetValidationsValidators.validateFormat)({ type: 'email' }),
    body: (0, _emberChangesetValidationsValidators.validatePresence)(true)
  };
});