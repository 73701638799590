define('dealer-portal/controllers/reset-password', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    userLog: _ember['default'].inject.service(),

    queryParams: ['token'],
    processing: false,
    token: undefined,

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        new _dealerPortalAdaptersApplication['default']().simple(this.get('ajax'), this.get('session'), this.get('userLog'), 'auth/reset-password', 'POST', {
          reset_password_token: this.get('token'),
          password: this.get('password'),
          password_confirmation: this.get('passwordConfirmation')
        }).then(function () {
          _this.toast.info('Password changed!');
          _this.transitionToRoute('login');
        })['catch'](function (data) {
          _this.toast.error('Password change failed. Try again.');
        });
      }
    }
  });
});