define('dealer-portal/models/carousel-item', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    body: _emberData['default'].attr(),
    bodyEs: _emberData['default'].attr(),
    header: _emberData['default'].attr(),
    headerEs: _emberData['default'].attr(),
    headerColor: _emberData['default'].attr(),
    callToAction: _emberData['default'].attr(),
    callToActionEs: _emberData['default'].attr(),
    startOn: _emberData['default'].attr(),
    endOn: _emberData['default'].attr(),
    linkUrl: _emberData['default'].attr(),
    position: _emberData['default'].attr(),
    placement: _emberData['default'].attr(),
    clicks: _emberData['default'].attr(),

    photoUrl: _emberData['default'].attr(),
    videoMp4Url: _emberData['default'].attr(),
    videoWebmUrl: _emberData['default'].attr(),

    hasVideo: _ember['default'].computed('videoMp4Url', 'videoWebmUrl', function () {
      return this.get('videoMp4Url') || this.get('videoWebmUrl');
    })
  });
});