define('dealer-portal/components/unit-file-upload', ['exports', 'ember', 'ember-changeset', 'ember-i18n'], function (exports, _ember, _emberChangeset, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('model'));
      this.changeset = new _emberChangeset['default'](this.get('model'));
    },

    fileTypes: _ember['default'].computed('i18n.locale', function () {
      return [{ value: this.get('i18n').t('unitFileUpload.title'), id: 'Title' }, { value: this.get('i18n').t('unitFileUpload.billOfSale'), id: 'Bill of Sale' }, { value: this.get('i18n').t('unitFileUpload.fundingDelay'), id: 'Funding Delay' }, { value: this.get('i18n').t('unitFileUpload.photo'), id: 'Photo of Car' }, { value: this.get('i18n').t('dealerFileUpload.other'), id: 'Other' }];
    }),

    actions: {
      updateFileTypeSelect: function updateFileTypeSelect(value) {
        var selected = value.target.value;
        _ember['default'].set(this, 'value', selected);
        this.set('nameError', false);
      },
      fileSelect: function fileSelect(value) {
        this.set('typeError', false);
      },
      expired: function expired() {},
      submit: function submit() {
        var _this = this;

        var self = this;
        var upload = this.get('changeset');
        if (upload.get('name') && document.getElementById('file-field').files[0]) {
          (function () {
            var file = document.getElementById('file-field').files[0];
            var allowed = ['text/plain', 'application/pdf', 'image/png', 'image/jpeg', 'image/tiff'];
            var found = false;
            allowed.forEach(function (type) {
              if (file.type.match(type)) {
                found = true;
              }
            });
            if (found) {
              upload.set('ufile', file);
              upload.set('unit_id', _this.get('unitId'));
              _this.set('showForm', false);
              $('.file-upload-button').attr("disabled", "disabled").val('Saving...');
              _this.attrs.save(upload);
            } else {
              _this.set('typeError', true);
            }
          })();
        } else {
          if (!upload.get('name')) {
            this.set('nameError', true);
          } else {
            this.set('fileError', true);
          }
        }
      }
    }
  });
});