define('dealer-portal/routes/access-home/pending-payments', ['exports', 'ember', 'moment', 'accounting/format-money', 'ember-i18n'], function (exports, _ember, _moment, _accountingFormatMoney, _emberI18n) {
  exports['default'] = _ember['default'].Route.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    moment: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    controllerName: 'access-home',
    beforeModel: function beforeModel(transition) {
      var userLevel = this.get('session').get('data').authenticated.level;
      if (userLevel === 'sales') {
        this.transitionTo('access-home.index');
      }
    },
    model: function model() {
      return this.get('store').query('transfer', {
        filter: { scope: 'pending' }
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var i18n = this.get('i18n');
      var pendingTransferHeadings = [{ key: 'paymentFor', display: 'Car' }, { key: 'paymentType', display: 'Type' }, { key: 'amount', display: 'Amount' }, { key: 'transferDate', display: 'Payment Date' }];
      controller.set('inventoryHeadings', pendingTransferHeadings);
      var pendingTransferRows = [];

      function styleAndFormatAsMoney(dollarAmount) {
        return '<span class="pull-right">' + (0, _accountingFormatMoney['default'])(dollarAmount) + '</span>';
      }
      model.forEach(function (transfer) {
        var rowHash = {
          paymentFor: transfer.get('paymentFor'),
          paymentType: transfer.get('paymentType'),
          amount: styleAndFormatAsMoney(transfer.get('amount')),
          transferDate: "<span class='timestamp' style='display: none;'>" + (0, _moment['default'])(transfer.get('transferDate')).format('x') + "</span> " + (0, _moment['default'])(transfer.get('transferDate')).format('MM/DD/YYYY')
        };
        pendingTransferRows.pushObject(rowHash);
      });
      controller.set('inventoryRows', pendingTransferRows);
    }
  });
});