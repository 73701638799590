define('dealer-portal/components/dealer-spouse', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    principal: 'true',

    owner_type: _ember['default'].computed('principal', function () {
      var person_to_enter = '';
      if (this.get('principal')) {
        person_to_enter = 'Principal';
      } else {
        person_to_enter = 'Other';
      }
      return person_to_enter;
    })
  });
});