define("dealer-portal/components/request-floor-add-bos-file", ["exports", "ember", "ember-changeset", "ember-i18n"], function (exports, _ember, _emberChangeset, _emberI18n) {
  exports["default"] = _ember["default"].Component.extend({
    store: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("model", this.get("model"));
      this.changeset = new _emberChangeset["default"](this.get("store").createRecord("unit-file"));
    },

    fileTypes: _ember["default"].computed("i18n.locale", function () {
      return [{
        value: this.get("i18n").t("unitFileUpload.billOfSale"),
        id: "Bill Of Sale"
      }];
    }),

    actions: {
      fileSelect: function fileSelect(value) {
        this.set("typeError", false);
        this.changeset.set("name", "Bill of Sale");
        this.set("nameError", false);
      },
      expired: function expired() {},
      submit: function submit() {
        var _this = this;

        var self = this;
        var upload = this.get("changeset");
        if (upload.get("name") && document.getElementById("file-field-bos").files[0]) {
          (function () {
            var file = document.getElementById("file-field-bos").files[0];
            var allowed = ["text/plain", "application/pdf", "image/png", "image/jpeg"];
            var found = false;
            allowed.forEach(function (type) {
              if (file.type.match(type)) {
                found = true;
              }
            });
            if (found) {
              upload.set("ufile", file);

              console.log("bos added", file);

              _this.set("showAddBOSForm", false);
              $(".file-upload-button").attr("disabled", "disabled").val("Adding to List...");
              _this.attrs.save(upload);
              _this.set("bosUploaded", true);
            } else {
              _this.set("typeError", true);
            }
          })();
        } else {
          if (!upload.get("name")) {
            this.set("nameError", true);
          } else {
            this.set("fileError", true);
          }
        }
      }
    }
  });
});