define('dealer-portal/routes/access-home/user-management', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        dealerUsers: this.get('store').query('dealer-user', { dealerId: this.get('dealerId') }),
        newDealerUser: this.get('store').createRecord('dealer-user')
      });
    },
    dealerId: _ember['default'].computed(function () {
      return this.get('session').get('data').authenticated.dealerId;
    })
  });
});