define('dealer-portal/routes/app', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),

    model: function model() {
      var currentModel = null;
      if (this.get('session.isAuthenticated')) {
        currentModel = this.get('store').queryRecord('app', { dealer_id: this.get('session').get('data').authenticated.dealerId }, { include: ['dealer', 'dealer.people'] });
      } else {
        currentModel = this.get('store').createRecord('app');
      }
      return new _ember['default'].RSVP.Promise(function (resolve) {
        resolve(currentModel);
      });
      // console.log(this.get('session').get('data').authenticated.dealerId);
      // if(this.get('session').isAuthenticated) {
      //   return new Ember.RSVP.hash({
      //     app: this.get('store').queryRecord('app',
      //       { dealer_id: this.get('session').get('data').authenticated.dealerId },
      //       {include: ['dealer', 'dealer-places', 'people'] })
      //   });
      // }else {
      //   return new Ember.RSVP.hash({ app: this.get('store').createRecord('app') });
      // }
    },
    afterModel: function afterModel(model) {
      // this._super(...arguments);
      var people = model.get('dealer').get('people');
      if (people) {
        var signatures = this.controllerFor('app').get('signatures');
        for (var x = 0; x < people.get('length'); x++) {
          console.log(people.objectAt(x).get('id'));
          var person_signature = {
            id: people.objectAt(x).get('id'),
            signature: _ember['default'].A(), dataURL: null };
          signatures.pushObject(person_signature);
        }
      }
      if (model.get('status') >= 2) {
        this.get('controller').set('isSubmitted', true);
      }
    },
    // beforeModel: function() {
    //   let session = this.get('session');
    //   console.log('test ' + Ember.inspect(session.get('data')));
    //   let login = session.get('data').login;
    //   let dealerUser = this.get('store').queryRecord('dealer-user', { email: login });
    //   this.set('dealerUser', dealerUser);
    // },

    // find_current_page: Ember.computed('current_page', function() {
    //   alert('here ' + this.get('controller').get('current_page'));
    //   var page = this.get('controller').get('current_page');
    //   switch(page) {
    //     case 2:
    //       this.get('controller').set('places', true);
    //       break;
    //     case 3:
    //       this.get('controller').setProperties({'places': true, 'dealership': true});
    //       break;
    //     case 4:
    //       this.get('controller').setProperties({
    //         'places': true, 'dealership': true, 'financials': true
    //       });
    //       break;
    //     case 5:
    //       this.get('controller').setProperties({
    //         'places': true, 'dealership': true, 'financials': true, 'people': true
    //       });
    //       break;
    //     case 6:
    //       this.get('controller').setProperties({
    //         'places': true, 'dealership': true, 'financials': true,
    //         'people': true, 'references': true
    //       });
    //       break;
    //     default:
    //       break;
    //   }
    // }),

    renderTemplate: function renderTemplate() {
      this.render();
      this.render('app.dealer-places', {
        outlet: 'dealer-places',
        into: 'app'
      });
      // controller: 'app/dealer_place',
      // model: 'app'
      this.render('app.dealership', {
        outlet: 'dealership',
        into: 'app',
        // controller: 'app',
        model: 'app'
      });
      this.render('app.dealer-financials', {
        outlet: 'dealer-financials',
        into: 'app',
        // controller: 'app/dealer_place',
        model: 'app'
      });
      this.render('app.people', {
        outlet: 'people',
        into: 'app'
      });
      // controller: 'app/people',
      // model: 'app'
      this.render('app.references', {
        outlet: 'references',
        into: 'app'
      });
    },
    // controller: 'app/references',
    // model: 'app'
    observeSession: (function () {
      this.send('sessionChanged');
    }).observes('session.isAuthenticated'),

    actions: {
      move_to_dealership: function move_to_dealership() {
        this.transitionTo('app.dealership');
        this.get('controller').set('dealership', true);
        this.get('controller').set('current_page', 2);
      },
      move_to_financials: function move_to_financials() {
        this.transitionTo('app.dealer-financials');
        this.get('controller').set('financials', true);
        this.get('controller').set('current_page', 3);
      },
      move_to_people: function move_to_people() {
        this.get('controller').set('people', true);
        this.transitionTo('app.people');
        this.get('controller').set('current_page', 4);
      },
      move_to_references: function move_to_references() {
        this.get('controller').set('references', true);
        this.transitionTo('app.references');
        this.get('controller').set('current_page', 5);
      },
      sessionChanged: function sessionChanged() {
        // this.get('maps').set('markers', Ember.A());
        console.log('in app route');
        this.refresh();
        // let authenticated = this.get('session').get('isAuthenticated');
        // this.get('maps').refreshMap(authenticated);
      }
    }
  });
});