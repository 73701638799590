define('dealer-portal/routes/app/people/person', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    controllerName: 'app.people',
    model: function model(params) {
      // alert('in person route');
      // console.log(params);
      // return this.store.modelFor('app.people.person');
      var peopleArray = _ember['default'].A();
      var currentPerson = null;
      if (params.id === 'new' || params.id === 'undefined') {
        var newPerson = this.get('store').createRecord('person');
        newPerson.set('dealerId', this.get('session').get('data').authenticated.dealerId);
        peopleArray.pushObject(newPerson);
      } else {
        var people = _ember['default'].A();
        people = this.modelFor('app.people');
        currentPerson = people.findBy('id', params.id);
        peopleArray.pushObject(currentPerson);
      }
      return peopleArray;
    },

    actions: {
      willTransition: function willTransition(transition) {
        // if (this.controller.get('userHasEnteredData') &&
        //     !confirm('You have unsaved changes that will be lost if you reload. Continue?')) {
        //   transition.abort();
        // } else {
        //   // Bubble the `willTransition` action so that
        //   // parent routes can decide whether or not to abort.
        //   return true;
        // }
        var self = this;
        var controller = this.get('controller');
        if (!controller.get('allowTransition')) {
          console.log('transition abort');
          transition.abort();
        }
      },
      didTransition: function didTransition() {
        var changeset = this.get('changeset');
        var controller = this.get('controller');
        controller.set('inputForm', true);
        controller.set('refresh', true);
        this.controllerFor('app').set('showAppSave', false);
      }
    }
  });
});