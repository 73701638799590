define('dealer-portal/helpers/greeting', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Helper.extend({
    i18n: _ember['default'].inject.service(),

    compute: function compute() {
      var today = new Date();
      var currentHour = today.getHours();
      var i18n = this.get('i18n');
      if (currentHour < 12) {
        return i18n.t('currentUnitPage.goodMorning').string;
      } else if (currentHour < 18) {
        return i18n.t('currentUnitPage.goodAfternoon').string;
      } else {
        return i18n.t('currentUnitPage.goodEvening').string;
      }
    }
  });
});