define('dealer-portal/components/session-timeout', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Component.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    userLog: _ember['default'].inject.service(),
    timeoutWatcher: undefined,
    warningTime: 60000,
    warningVisible: false,
    secondsUnitExpiration: undefined,

    expirationUpdated: (function () {
      if (!this.get('timeoutWatcher')) {
        this.updateTimeoutWatcher();
      }
    }).observes('userLog.secondsUntilExpiration'),

    timeUntilExpiration: function timeUntilExpiration() {
      return this.get('userLog.secondsUntilExpiration') * 1000;
    },

    updateTimeoutWatcher: function updateTimeoutWatcher() {
      if (this.get('timeoutWatcher')) {
        var timeoutWatcher = this.get('timeoutWatcher');
        this.set('timeoutWatcher', undefined);
        _ember['default'].run.cancel(timeoutWatcher);
      }

      if (this.get('userLog.secondsUntilExpiration')) {
        this.set('timeoutWatcher', _ember['default'].run.later(this, this.checkExpiration, this.timeUntilExpiration() - this.get('warningTime')));
      }
    },

    checkExpiration: function checkExpiration() {
      this.set('timeoutWatcher', undefined);

      if (this.timeUntilExpiration() <= 0) {
        this.get('session').set('data.expired', true);
        this.get('session').invalidate();
      } else if (this.timeUntilExpiration() <= this.get('warningTime')) {
        this.showWarning();
      } else {
        this.hideWarning();
        this.updateTimeoutWatcher();
      }
    },

    hideWarning: function hideWarning() {
      this.set('warningVisible', false);
    },

    showWarning: function showWarning() {
      if (this.get('session.isAuthenticated')) {
        this.set('warningVisible', true);
        _ember['default'].run.later(this, this.checkExpiration, 200);
      } else {
        this.set('warningVisible', false);
      }
    },

    didInsertElement: function didInsertElement() {
      if (this.get('userLog.secondsUntilExpiration')) {
        this.checkExpiration();
      }
    },

    actions: {
      refreshToken: function refreshToken() {
        var _this = this;

        if (this.get('session.isAuthenticated')) {
          new _dealerPortalAdaptersApplication['default']().simple(this.get('ajax'), this.get('session'), this.get('userLog'), 'auth/refresh-token', 'GET', {}, 'raw').then(function (response) {
            var seconds = parseInt(response.jqXHR.getResponseHeader('X-Token-expiration'));

            _this.set('secondsUntilExpiration', seconds);
          });
        }
      }
    }
  });
});