define('dealer-portal/helpers/truncate-text', ['exports', 'ember-truncate-text/helpers/truncate-text'], function (exports, _emberTruncateTextHelpersTruncateText) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTruncateTextHelpersTruncateText['default'];
    }
  });
  Object.defineProperty(exports, 'truncateText', {
    enumerable: true,
    get: function get() {
      return _emberTruncateTextHelpersTruncateText.truncateText;
    }
  });
});