define('dealer-portal/routes/app-simple/file-submitted', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    // controllerName: 'app-simple',
    redirect: function redirect() {
      this.transitionTo('app-simple.show');
    },
    actions: {
      resetForm: function resetForm() {}
    }
  });
});