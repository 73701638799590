define("dealer-portal/templates/components/dealer-user-lock", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 4
          }
        },
        "moduleName": "dealer-portal/templates/components/dealer-user-lock.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "href", "#");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        var el3 = dom.createTextNode("\n      Locked -\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'dealerUserId');
        morphs[2] = dom.createAttrMorph(element0, 'name');
        morphs[3] = dom.createAttrMorph(element0, 'onClick');
        morphs[4] = dom.createAttrMorph(element1, 'class');
        morphs[5] = dom.createAttrMorph(element1, 'id');
        morphs[6] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["attribute", "id", ["subexpr", "concat", ["lock-account-link", ["get", "user.id", ["loc", [null, [1, 35], [1, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [1, 44]]], 0, 0], 0, 0, 0, 0], ["attribute", "dealerUserId", ["get", "user.id", ["loc", [null, [1, 60], [1, 67]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["subexpr", "concat", ["lockAccount", ["get", "user.id", ["loc", [null, [2, 30], [2, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [2, 39]]], 0, 0], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["lockAccount", ["get", "user.status", ["loc", [null, [3, 33], [3, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [3, 46]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["subexpr", "check-user-locked", ["css", ["get", "user.status", ["loc", [null, [4, 42], [4, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [4, 55]]], 0, 0], 0, 0, 0, 0], ["attribute", "id", ["subexpr", "concat", ["accountlocked", ["get", "user.id", ["loc", [null, [5, 34], [5, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [5, 43]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "check-user-locked", ["label", ["get", "user.status", ["loc", [null, [8, 35], [8, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 8], [8, 47]]], 0, 0]], [], ["loc", [null, [8, 4], [8, 49]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});