define('dealer-portal/components/unit-calendar', ['exports', 'ember', 'moment', 'ember-i18n'], function (exports, _ember, _moment, _emberI18n) {

  var NUMBER_OF_WEEKS_TO_DISPLAY = 2;
  var DAYS_IN_A_WEEK = 7;

  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    determineButtonText: function determineButtonText(isCollapsed) {
      return isCollapsed ? this.get('i18n').t('currentUnitPage.showCalendar').string : this.get('i18n').t('currentUnitPage.hideCalendar').string;
    },

    init: function init() {
      this._super.apply(this, arguments);

      var allUnits = this.get('units');

      var isMobile = $(window).width() < 960;
      var isCollapsed = this.get('session').get('data.calendarCollapsed') || isMobile;
      this.set('collapsed', !!this.get('session').get('data.calendarCollapsed'));
      this.set('buttonText', this.determineButtonText(isCollapsed));
      this.set('weeks', buildWeeks(NUMBER_OF_WEEKS_TO_DISPLAY, allUnits));
    },

    setCalendarCollapsed: function setCalendarCollapsed(collapsed) {
      this.set('collapsed', collapsed);
      this.get('session').set('data.calendarCollapsed', collapsed);
    },

    actions: {
      toggleCollapsed: function toggleCollapsed(currentlyCollapsed) {
        this.set('buttonText', this.determineButtonText(!currentlyCollapsed));

        currentlyCollapsed ? this.setCalendarCollapsed(false) : this.setCalendarCollapsed(true);
      }
    }
  });

  function toFormattedForCalendar(unit, currentDate) {
    return {
      id: unit.get('id'),
      name: unit.get('name'),
      amountToDisplay: determineAmountDue(unit),
      date: currentDate
    };
  }

  function determineAmountDue(unit) {
    var canRefloor = unit.get('canRefloor');
    var curtailment = unit.get('curtailment');
    var payoff = unit.get('payoff');
    var amountToDisplay = canRefloor ? curtailment : payoff;

    return amountToDisplay;
  }

  function buildDays(offsetWeek, relevantUnits) {
    var daysForCalendar = [];
    var totalOffsetDays = offsetWeek * DAYS_IN_A_WEEK;
    var today = Date.now();

    var _loop = function (currentDayCount) {

      var currentDate = (0, _moment['default'])(today).add(totalOffsetDays + currentDayCount, 'days');
      var unitsDueOnCurrentDate = relevantUnits.filter(function (unit) {
        var unitDueDate = unit.get('dueOn');

        return (0, _moment['default'])(unitDueDate).isSame(currentDate, 'day');
      });

      var totalAmountDueOnDate = unitsDueOnCurrentDate.reduce(function (accumulator, unit) {
        return accumulator + determineAmountDue(unit);
      }, 0);

      daysForCalendar.push({
        date: currentDate,
        units: unitsDueOnCurrentDate.map(function (unit) {
          return toFormattedForCalendar(unit, currentDate);
        }),

        showAsSummary: unitsDueOnCurrentDate.length > 2,
        totalDue: totalAmountDueOnDate
      });
    };

    for (var currentDayCount = 0; currentDayCount < DAYS_IN_A_WEEK; currentDayCount++) {
      _loop(currentDayCount);
    }

    return daysForCalendar;
  }

  function isDueWithinTwoWeeks(unit) {
    var unitDueDate = unit.get('dueOn');
    var now = (0, _moment['default'])(now).subtract(1, 'day');
    var twoWeeksFromNow = (0, _moment['default'])(now).add(15, 'days');

    return (0, _moment['default'])(unitDueDate).isBetween(now, twoWeeksFromNow);
  }

  function buildWeeks(weeksToBuild, allUnits) {
    var relevantUnits = allUnits.filter(isDueWithinTwoWeeks);
    var weeksForCalendar = [];

    for (var currentWeekCount = 0; currentWeekCount < weeksToBuild; currentWeekCount++) {
      var offsetWeek = currentWeekCount;
      var weekAtCurrentOffset = {
        days: buildDays(offsetWeek, relevantUnits)
      };

      weeksForCalendar.push(weekAtCurrentOffset);
    }

    return weeksForCalendar;
  }
});