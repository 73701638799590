define('dealer-portal/helpers/dealer-user-type', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    session: _ember['default'].inject.service(),

    compute: function compute(params) {
      var dealer_user_type = this.get('session').get('data').authenticated.level;
      var currentUserLevel = params[0];
      if (dealer_user_type === currentUserLevel) {
        return true;
      } else {
        return false;
      }
    }
  });
});