define('dealer-portal/helpers/is-primitive', ['exports', 'ember-sort-filter-table/helpers/is-primitive'], function (exports, _emberSortFilterTableHelpersIsPrimitive) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersIsPrimitive['default'];
    }
  });
  Object.defineProperty(exports, 'isPrimitive', {
    enumerable: true,
    get: function get() {
      return _emberSortFilterTableHelpersIsPrimitive.isPrimitive;
    }
  });
});