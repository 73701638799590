define('dealer-portal/routes/access-home/bank-accounts', ['exports', 'ember', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    beforeModel: function beforeModel(transition) {
      var userLevel = this.get('session').get('data').authenticated.level;
      if (userLevel === 'financial' || userLevel === 'sales') {
        this.transitionTo('access-home.index');
      }
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        bankAccounts: this.get('store').query('bank-account', {
          filter: { owner_id: this.get('dealerId') }
        }),
        newAccount: this.get('store').createRecord('bank-account')
      });
    },
    redirect: function redirect(model, transition) {
      var needsAuth = false;
      model.bankAccounts.forEach(function (ba) {
        if (ba.get('needsAchAuth')) {
          needsAuth = true;
        }
      });
      if (needsAuth) {
        this.transitionTo('access-home.bank-accounts.ach-authorization');
      }
    },
    dealerId: _ember['default'].computed(function () {
      return this.get('session').get('data').authenticated.dealerId;
    }),
    actions: {
      makeDefault: function makeDefault(account) {
        var headers = {};
        this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        var self = this;
        self.toast.info('Updating...');
        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('bank-accounts/' + account.id + '/make-default'), {
          method: 'put',
          headers: headers,
          data: {}
        }).then(function (response) {
          self.transitionTo('access-home.bank-account-added');
        });
      },
      deleteAccount: function deleteAccount(account) {
        var headers = {};
        this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        var self = this;
        return this.get('ajax').request(new _dealerPortalAdaptersApplication['default']().fullURL('bank-accounts/' + account.id + '/delete'), {
          method: 'put',
          headers: headers,
          data: {}
        }).then(function (response) {
          self.toast.info(response[0].message);
          self.transitionTo('access-home.bank-account-added');
        });
      }
    }
  });
});