define('dealer-portal/components/branch-address', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var component = this;
      this.get('store').findRecord('dealer', this.get('session').get('data').authenticated.dealerId, { includes: 'branches' }).then(function (dealer) {
        component.set('dealer', dealer);
      });
    }
  });
});