define('dealer-portal/helpers/check-user-locked', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    session: _ember['default'].inject.service(),

    compute: function compute(params) {
      var type = params[0];
      var userLocked = params[1];

      if (type === 'label') {
        return userLocked === 2 ? 'userManagementPage.unlock' : 'userManagementPage.lock';
      } else {
        if (userLocked === 2) {
          return 'user-locked';
        } else {
          return 'user-not-locked';
        }
      }
    }
  });
});