define("dealer-portal/routes/access-home/txn-detail", ["exports", "ember", "accounting/format-money", "ember-i18n"], function (exports, _ember, _accountingFormatMoney, _emberI18n) {
  exports["default"] = _ember["default"].Route.extend({
    session: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    queryParams: {
      fromDate: {
        refreshModel: true
      }
    },
    fromDate: null,
    toDate: null,

    controllerName: 'access-home',
    model: function model(params) {
      var from = null;
      var to = null;
      if (params['fromDate'] == null) {
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear() - 1;
        from = yyyy + '/' + mm + '/' + dd;
        this.set('fromDate', from);
      } else {
        from = params['fromDate'];
      }
      if (params['toDate'] == null) {
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear();
        to = yyyy + '/' + mm + '/' + dd;
        this.set('toDate', to);
      } else {
        to = params['toDate'];
      }
      return this.get('store').findRecord('txn', params['id']);
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var i18n = this.get('i18n');
      // console.log("???" , Ember.$('#expand-transactions-button').click(function() {console.log('stuff')}))
      // window.$("#expand-transactions-button").addListener('click', function() {console.log('stuff')});
      // console.log(window.$(""))
      function styleAndFormatAsMoney(dollarAmount) {
        return '<span class="pull-right">' + (0, _accountingFormatMoney["default"])(dollarAmount) + '</span>';
      }

      var txnDetailHeadings = [{ key: 'serial_number', display: i18n.t('transactionsPage.serialNumber').string }, { key: 'line_type', display: i18n.t('transactionsPage.lineType').string }, { key: 'name', display: i18n.t('transactionsPage.car').string }, { key: 'amount', display: i18n.t('transactionsPage.amount').string }, { key: 'principal_paid', display: i18n.t('transactionsPage.principalPaid').string }, { key: 'fees_paid', display: i18n.t('transactionsPage.feesPaid').string }, { key: 'insurance_fees_paid', display: i18n.t('transactionsPage.insuranceFeesPaid').string }, { key: 'interest_paid', display: i18n.t('transactionsPage.interestPaid').string }, { key: 'added_to_reserves', display: i18n.t('transactionsPage.addedToReserves').string }, { key: 'total_deposit', display: i18n.t('transactionsPage.totalDeposit').string }];
      controller.set('inventoryHeadings', txnDetailHeadings);
      var txnDetailRows = [];

      model.get('paymentsUnitsForTxn').forEach(function (unit) {
        var unitHash = {
          serialNumber: unit.serial_number,
          lineType: unit.line_type,
          name: unit.name, //+  '<button id="expand-transactions-button">+</button>',
          amount: styleAndFormatAsMoney(unit.amount),
          principalPaid: styleAndFormatAsMoney(unit.principal_paid),
          feesPaid: styleAndFormatAsMoney(unit.fees_paid),
          insuranceFeesPaid: styleAndFormatAsMoney(unit.insurance_fees_paid),
          interestPaid: styleAndFormatAsMoney(unit.interest_paid),
          addedToReserves: styleAndFormatAsMoney(unit.added_to_reserves),
          totalDeposit: styleAndFormatAsMoney(unit.total_deposit)
        };
        txnDetailRows.pushObject(unitHash);

        model.get('linesForTxn').forEach(function (line) {
          if (line.id == unit.id) {
            var lineHash = {
              serial_number: '',
              line_type: '',
              memo: line.memo + ':' + styleAndFormatAsMoney(line.credit),
              amount: '',
              principalPaid: '',
              feesPaid: '',
              insuranceFeesPaid: '',
              interestPaid: '',
              addedToReserves: '',
              totalDeposit: ''
            };
            txnDetailRows.pushObject(lineHash);
          }
        });
      });
      controller.set('inventoryRows', txnDetailRows);

      if (this.get('fromDate') != controller.get('fromDate')) {
        controller.set('fromDate', this.get('fromDate'));
      }
      if (this.get('toDate') != controller.get('toDate')) {
        controller.set('toDate', this.get('toDate'));
      }
      controller.set('transitionFrom', 'reports.txns');
    },

    actions: {
      expandUnitTxnLines: function expandUnitTxnLines() {
        console.log('expandTxnLines:');
        return true;
      }
    }
  });
});