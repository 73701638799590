define('dealer-portal/components/sort-filter-table', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({

    didRender: function didRender() {
      this._super.apply(this, arguments);
      var from = (0, _moment['default'])(this.get('fromDate')).format('MM/DD/YYYY');
      var to = (0, _moment['default'])(this.get('toDate')).format('MM/DD/YYYY');
      this.$('#from-date').val(from);
      this.$('#to-date').val(to);
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (this.$('.first-heading')) {
          this.$('.first-heading').parent().parent().trigger('click').trigger('click');
        }
      });
    },

    actions: {
      searchDates: function searchDates() {
        var from = this.$('#from-date').val();
        var to = this.$('#to-date').val();
        this.attrs.searchDates(from, to);
      },
      downloadTxnReports: function downloadTxnReports(reportName, reportType) {
        this.attrs.downloadTxnReports(reportName, reportType);
      },
      downloadReservesReport: function downloadReservesReport(reportType) {
        this.attrs.downloadReservesReport(reportType);
      },
      downloadCurrentInventory: function downloadCurrentInventory() {
        this.attrs.downloadCurrentInventory();
      },
      downloadComingDueReport: function downloadComingDueReport(reportType) {
        this.attrs.downloadComingDueReport(reportType);
      },
      downloadSoldReport: function downloadSoldReport(reportType) {
        this.attrs.downloadSoldReport(reportType);
      },
      downloadPaidReport: function downloadPaidReport(reportType) {
        this.attrs.downloadPaidReport(reportType);
      },
      downloadPreviousInventoryReport: function downloadPreviousInventoryReport(reportType) {
        this.attrs.downloadPreviousInventoryReport(reportType);
      },
      printReceipt: function printReceipt() {
        this.attrs.printReceipt(this.get('txnId'));
      }
    }
  });
});