define('dealer-portal/routes/auctions/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    maps: _ember['default'].inject.service('maps'),

    model: function model(params) {
      return this.find('auction', params[':auctionId']);
    },
    afterModel: function afterModel(model) {
      _ember['default'].run.schedule('afterRender', this, function () {
        var maps = this.get('maps');
        var auctions = [];
        var auctionList = model.get('auctions');
        for (var x = 0; x < auctionList.get('length'); x++) {
          auctions.pushObject(auctionList.objectAt(x).get('data'));
        }
        maps.auctionMarkers(auctions);
      });
    },
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});