define("dealer-portal/controllers/access-home/ach-scheduling", ["exports", "ember", "dealer-portal/adapters/application", "moment"], function (exports, _ember, _dealerPortalAdaptersApplication, _moment) {
  exports["default"] = _ember["default"].Controller.extend({
    ajax: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    paymentAmount: 0,
    paymentTypeNumber: null,
    refloorPayment: null,
    payoffPayment: null,
    feePayment: null,
    feeLineId: null,
    selectedAccount: null,
    selectedFee: null,
    selectedCoupon: null,
    selectedFeeMemo: null,
    confirmPayment: false,
    payNowDetails: false,
    queryParams: ["unitId", "achId", "confirmPayment", "calculatorPayoffDate"],
    achId: null,
    unitId: null,
    payOnDate: null,
    fromCalculator: null,
    today: null,

    init: function init() {
      this._super();
      var self = this;
      _ember["default"].run.schedule("afterRender", this, function () {
        this.get("model.bankAccounts").forEach(function (account) {
          if (account.get("defaultAccount")) {
            self.set("selectedAccount", account.get("id"));
          }
        });

        if (!this.get("selectedAccount")) {
          this.set("selectedAccount", this.get("model.bankAccounts.firstObject.id"));
        }

        if (this.get("feePayment")) {
          this.send("setACHAmount", null, "currentFees");
          window.$("#payment-upload-type").val("currentFees");
          this.send("setSelectedFee", null, this.get("fee_line_id"));
          window.$("#fee-dropdown").val(this.get("selectedFee"));
        }

        if (this.get("fromCalculator")) {
          if ((0, _moment["default"])(this.get("model.dealer.branch.achDate")) <= (0, _moment["default"])(this.get("calculatorPayoffDate"))) {
            window.$("#payment-upload-type").val("payoff");
            this.send("setACHAmount", null, "payoff");
            this.set("payOnDate", this.get("calculatorPayoffDate"));

            window.$("#payment-date").val((0, _moment["default"])(this.get("payOnDate")).format("MM/DD/YYYY"));
            this.send("changeDate");
          }
        }
      });
    },

    achProcessed: _ember["default"].computed("model.dealer.branch.achProcessed", function () {
      return this.get("model.dealer.branch.achProcessed");
    }),

    achDate: _ember["default"].computed("model.dealer.branch.achDate", function () {
      return this.get("model.dealer.branch.achDate");
    }),

    headers: _ember["default"].computed(function () {
      var headers = {};
      this.get("session").authorize("authorizer:token", function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });
      return headers;
    }),

    fees: _ember["default"].computed(function () {
      return this.get("model.unit.activeFees");
    }),

    dueOn: _ember["default"].computed(function () {
      return this.get("model.unit.dueOn");
    }),

    actions: {
      setACHAmount: function setACHAmount(event, initFeeType) {
        var paymentType = event == null ? initFeeType : event.target.value;
        this.setProperties({
          refloorPayment: false,
          payoffPayment: false,
          currentFees: false,
          feePayment: false
        });
        switch (paymentType) {
          case "curtailment":
            this.set("refloorPayment", true);
            this.set("paymentTypeNumber", 1);
            break;
          case "payoff":
            this.set("payoffPayment", true);
            this.set("paymentTypeNumber", 3);
            break;
          case "currentFees":
            this.set("feePayment", true);
            this.set("paymentTypeNumber", 5);
            var firstFee = this.get("model.unit.activeFees")[0];
            this.setProperties({
              selectedFee: firstFee.fee_line_id,
              selectedFeeMemo: firstFee.memo,
              paymentAmount: firstFee.amount
            });
            break;
        }
        this.set("paymentAmount", paymentType === "currentFees" ? this.get("paymentAmount") : this.get("model").unit.get(paymentType));
        var today = this.get("achDate");
        var next_day = null;
        if (this.get("achProcessed")) {
          this.toast.info("ACHs for your branch have already been processed " + "for today. The earliest available payment date is " + (0, _moment["default"])(today).format("MM/DD/YYYY") + ".", "", { hideDuration: 25000 });
        }
        window.$("#payment-date").val((0, _moment["default"])(today).format("MM/DD/YYYY"));
        this.send("changeDate");
      },
      setSelectedFee: function setSelectedFee(element, existingId) {
        var feeId = element == null ? existingId : element.target.value;
        var fees = this.get("fees");
        var self = this;
        fees.forEach(function (fee) {
          if (fee.fee_line_id == feeId) {
            self.setProperties({
              paymentAmount: fee.amount,
              selectedFee: fee.fee_line_id,
              selectedFeeMemo: fee.memo
            });
          }
        });
      },
      setSelectedCoupon: function setSelectedCoupon(element) {
        var _this = this;

        this.set("selectedCoupon", element.target.value);

        var paymentType = window.$("#payment-upload-type").val();
        var paymentDate = (0, _moment["default"])(window.$("#payment-date").val());
        var couponRedemptionId = element.target.value;

        if (paymentDate.isValid()) {
          this.set("payOnDate", paymentDate.format());
          var headers = this.get("headers");
          window.$("#submit-payment-button").attr("disabled", "disabled");

          return this.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("units/" + this.get("model.unit.id") + "/amounts_due_on"), {
            method: "get",
            headers: headers,
            data: {
              amounts_due_on_date: paymentDate.format("YYYY/MM/DD"),
              coupon_redemption_id: couponRedemptionId
            }
          }).then(function (response) {
            switch (paymentType) {
              case "curtailment":
                _this.set("paymentAmount", response.refloor);
                break;
              case "payoff":
                _this.set("paymentAmount", response.payoff);
                break;
              case "currentFees":
                _this.set("paymentAmount", response.fees);
                break;
              default:
                break;
            }

            window.$("#submit-payment-button").removeAttr("disabled");
          });
        }
      },
      disableSubmit: function disableSubmit() {
        window.$("#submit-payment-button").attr("disabled", "disabled");
      },
      changeDate: function changeDate() {
        var _this2 = this;

        var paymentDate = (0, _moment["default"])(window.$("#payment-date").val());
        var minPaymentDate = (0, _moment["default"])(this.get("achDate"));
        var dueOn = (0, _moment["default"])(this.get("dueOn"));
        var achProcessed = this.get("achProcessed");
        this.send("disableSubmit");

        var sortedMax = [dueOn, minPaymentDate].sort(function (a, b) {
          return a.valueOf() - b.valueOf();
        });

        var maxPaymentDate = sortedMax[1];

        if (paymentDate.isValid()) {
          if (paymentDate < minPaymentDate) {
            window.$("#submit-payment-button").attr("disabled", "disabled");
            window.$("#payment-date").focus();
            this.toast.error("Payment Date cannot be set for earlier than " + minPaymentDate.format("MM/DD/YYYY") + ".");
            return false;
          } else if (paymentDate > maxPaymentDate) {
            this.set("fromCalculator", null);
            this.toast.error("Payment date cannot be set for later than " + maxPaymentDate.format("MM/DD/YYYY") + ".", "", { hideDuration: 25000 });
            return false;
          }

          this.set("payOnDate", paymentDate.format());
          var paymentType = window.$("#payment-upload-type").val();
          if (paymentType !== null) {
            var _ret = (function () {
              var paymentType = window.$("#payment-upload-type").val();
              var headers = _this2.get("headers");
              window.$("#submit-payment-button").removeAttr("disabled");

              if (paymentType !== "currentFees") {
                window.$("#submit-payment-button").attr("disabled", "disabled");
                window.$("#payment-upload-type").attr("disabled", "disabled");

                return {
                  v: _this2.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("units/" + _this2.get("model.unit.id") + "/amounts_due_on"), {
                    method: "get",
                    headers: headers,
                    data: {
                      amounts_due_on_date: paymentDate.format("YYYY/MM/DD")
                    }
                  }).then(function (response) {
                    var selectedDate = (0, _moment["default"])(window.$("#payment-date").val()).format("YYYY/MM/DD");
                    var responseDate = response.amounts_due_on_date;
                    if (selectedDate === responseDate) {
                      switch (paymentType) {
                        case "curtailment":
                          _this2.set("paymentAmount", response.refloor);
                          break;
                        case "payoff":
                          _this2.set("paymentAmount", response.payoff);
                          break;
                        default:
                          break;
                      }
                      window.$("#submit-payment-button").removeAttr("disabled");
                      window.$("#payment-upload-type").removeAttr("disabled");
                    }
                  })
                };
              } else {
                window.$("#submit-payment-button").removeAttr("disabled");
                window.$("#payment-upload-type").removeAttr("disabled");
              }
            })();

            if (typeof _ret === "object") return _ret.v;
          }
        }
      },
      setSelectedAccount: function setSelectedAccount(element) {
        this.set("selectedAccount", element.target.value);
      },
      checkSubmit: function checkSubmit(ach) {
        if (ach.payment_type != null && ach.transfer_date != null && ach.amount != null) {
          if (ach.payment_type == 5 && (!ach.account_id != null || !ach.fee_line_id != null)) {
            return false;
          }
        } else {
          return false;
        }
      },
      submitPayment: function submitPayment() {
        var headers = this.get("headers");
        var ach = {
          user_id: this.get("model.dealer.id"),
          branch_id: this.get("model.dealer.branch.id"),
          payment_type: this.get("paymentTypeNumber"),
          transfer_date: this.get("payOnDate"),
          amount: this.get("paymentAmount"),
          account_id: this.get("selectedAccount"),
          fee_line_id: this.get("selectedFee"),
          unit_id: this.get("model.unit.id"),
          coupon_redemption_id: this.get("selectedCoupon")
        };
        var self = this;
        if (self.send("checkSubmit", ach) != false) {
          return this.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("transfers/create-payment"), {
            method: "post",
            headers: headers,
            data: {
              ach: ach
            }
          }).then(function (response) {
            if (response.data) {
              var achId = response.data.id;
              self.setProperties({ confirmPayment: true, achId: achId });
              self.transitionToRoute("/access-home/ach-scheduling/" + self.get("model.unit.id") + "/confirm-payment", {
                achId: achId,
                unitId: self.get("model.unit.id"),
                confirmPayment: self.get("confirmPayment")
              });
            } else {
              self.toast.error(response[0].message);
            }
          });
        } else {
          return false;
        }
      },
      processPayment: function processPayment(achId) {
        window.$("#submit-payment-button").attr("disabled", "disabled");
        var headers = this.get("headers");
        var self = this;
        self.toast.info("Initiating Transfer");
        return this.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("transfers/" + achId + "/process-transfer"), {
          method: "put",
          headers: headers,
          data: {}
        }).then(function (response) {
          if (response.data) {
            self.toast.success("Transfer Scheduled");
            self.transitionToRoute("access-home.index");
          } else {
            self.toast.error(response[0].message);
          }
        });
      },
      cancelPayment: function cancelPayment(achId) {
        window.$("#submit-payment-button").attr("disabled", "disabled");
        var headers = this.get("headers");
        var self = this;
        return this.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("transfers/" + achId + "/cancel-transfer"), {
          method: "put",
          headers: headers,
          data: {}
        }).then(function (response) {
          if (response[0].message === "Transfer canceled") {
            self.toast.success(response[0].message);
            self.transitionToRoute("access-home.index");
          } else {
            self.toast.error(response[0].message);
          }
        });
      }
    }
  });
});