define('dealer-portal/routes/account', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model() {
      var token = this.get('session').session.content.authenticated.token;
      var payload = token.split('.')[1];
      var tokenData = decodeURIComponent(window.escape(atob(payload)));
      var parsedToken = JSON.parse(tokenData);
      return this.get('store').findRecord('dealer', parsedToken['dealer-id']);
    }
  });
});