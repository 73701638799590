define('dealer-portal/components/dealer-person', ['exports', 'ember', 'dealer-portal/validations/person', 'ember-changeset-validations', 'ember-changeset'], function (exports, _ember, _dealerPortalValidationsPerson, _emberChangesetValidations, _emberChangeset) {

  // const { Component } = Ember;

  exports['default'] = _ember['default'].Component.extend({
    PersonValidations: _dealerPortalValidationsPerson['default'],
    store: _ember['default'].inject.service(),
    principal: 'true',

    init: function init() {
      this._super.apply(this, arguments);
      var model = this.get('model');
      this.changeset = new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(_dealerPortalValidationsPerson['default']));
    },

    bankruptcy: [{ id: 0, value: 'No' }, { id: 1, value: 'Yes' }],

    citizen: [{ id: 0, value: 'No' }, { id: 1, value: 'Yes' }],

    property_status: [{ value: 'Own - Paid Off', id: 1 }, { value: 'Own - Under Mortgage', id: 2 }, { value: 'Rental / Lease', id: 3 }],

    states: [{ id: 'AL', value: 'AL' }, { id: 'AK', value: 'AK' }, { id: 'AZ', value: 'AZ' }, { id: 'AR', value: 'AR' }, { id: 'CA', value: 'CA' }, { id: 'CO', value: 'CO' }, { id: 'CT', value: 'CT' }, { id: 'DE', value: 'DE' }, { id: 'DC', value: 'DC' }, { id: 'FL', value: 'FL' }, { id: 'GA', value: 'GA' }, { id: 'HI', value: 'HI' }, { id: 'ID', value: 'ID' }, { id: 'IL', value: 'IL' }, { id: 'IN', value: 'IN' }, { id: 'IA', value: 'IA' }, { id: 'KS', value: 'KS' }, { id: 'KY', value: 'KY' }, { id: 'LA', value: 'LA' }, { id: 'ME', value: 'ME' }, { id: 'MD', value: 'MD' }, { id: 'MA', value: 'MA' }, { id: 'MI', value: 'MI' }, { id: 'MN', value: 'MN' }, { id: 'MS', value: 'MS' }, { id: 'MO', value: 'MO' }, { id: 'MT', value: 'MT' }, { id: 'NE', value: 'NE' }, { id: 'NV', value: 'NV' }, { id: 'NH', value: 'NH' }, { id: 'NJ', value: 'NJ' }, { id: 'NM', value: 'NM' }, { id: 'NY', value: 'NY' }, { id: 'NC', value: 'NC' }, { id: 'ND', value: 'ND' }, { id: 'OH', value: 'OH' }, { id: 'OK', value: 'OK' }, { id: 'OR', value: 'OR' }, { id: 'PA', value: 'PA' }, { id: 'RI', value: 'RI' }, { id: 'SC', value: 'SC' }, { id: 'SD', value: 'SD' }, { id: 'TN', value: 'TN' }, { id: 'TX', value: 'TX' }, { id: 'US', value: 'US' }, { id: 'UT', value: 'UT' }, { id: 'VT', value: 'VT' }, { id: 'VA', value: 'VA' }, { id: 'WA', value: 'WA' }, { id: 'WV', value: 'WV' }, { id: 'WI', value: 'WI' }, { id: 'WY', value: 'WY' }, { id: 'OT', value: 'OT' }],

    owner_type: _ember['default'].computed('principal', function () {
      var person_to_enter = '';
      if (this.get('principal')) {
        person_to_enter = 'Principal';
      } else {
        person_to_enter = 'Other';
      }
      return person_to_enter;
    }),

    person_type: _ember['default'].computed('married', function () {
      var person_to_enter = '';
      if (this.get('married')) {
        person_to_enter = 'Spouse\'s';
      } else {
        person_to_enter = '';
      }
      return person_to_enter;
    }),

    actions: {
      updateStatesSelect: function updateStatesSelect(value) {
        console.log(value);
        var person = this.get('changeset');
        var selected = value.target.value;
        _ember['default'].set(this, 'value', selected);
        var element = ~event.srcElement.id.indexOf('dl_state') ? 'dl_state' : 'state';
        console.log(element);
        _ember['default'].set(person, element, selected);
      },
      dataEntered: function dataEntered() {
        this.sendAction('dataEntered');
      },
      submit: function submit() {
        var changeset = this.get('changeset');
        console.log('in component');
        console.log(this.get('spouseId'));
        changeset.set('spouse_id', this.get('spouseId'));
        console.log('here' + changeset);
        // changeset.set('first_name', this.get('model').first_name);
        // console.log(changeset.changes);
        this.attrs.save(changeset);
      },
      rollback: function rollback() {
        var changeset = this.get('changeset');
        this.attrs.rollback(changeset);
      }
    }
  });
});