define('dealer-portal/components/dealer-sign-in', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    showSignUp: false,

    loginLabel: _ember['default'].computed('current_route', function () {
      var label = 'Don\'t have an account?';
      if (this.get('current_route') === 'app') {
        label = 'Already have an account?';
      }
      return label;
    }),

    actions: {
      // showSignUp: function() {
      //   let showing = this.get('showSignUp');
      //   this.set('showSignUp', showing ? false : true);
      //   this.set('loginLabel', showing ? 'Don\'t have an account?' :
      //     'Already have an account?' );
      // },
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('identification', 'password'),
            authenticator = 'authenticator:jwt';
        // if(this.get('showSignUp')) {
        //   let email = this.get('identification');
        //   // let changeset = this.get('changeset');
        //   this.set('email', email);
        //   this.set('password', this.get('password'));
        //   // this.attrs.triggerSave(changeset);
        // }else {
        this.get('session').authenticate(authenticator, credentials).then(function () {
          _this.attrs.redirectAfterLogin;
          // let email = this.getProperties('identification').identification;
          // session.set('data.dealerId', dealerUser.dealerId);
          // let dealerId = this.get('store').queryRecord('dealer-user',
          //   {email: email}).then(() => {
          //     let session = this.get('session');
          //     session.set('data.login', this.getProperties('identification').identification);
          //     session.set('data.dealerId', dealerUser.dealerId);
          // });
        });
        // }
      }
    }
  });
});