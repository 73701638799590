define("dealer-portal/controllers/access-home/payoff-calculator", ["exports", "ember", "dealer-portal/adapters/application"], function (exports, _ember, _dealerPortalAdaptersApplication) {
  exports["default"] = _ember["default"].Controller.extend({
    session: _ember["default"].inject.service(),
    ajax: _ember["default"].inject.service(),
    unitName: null,
    dealerName: null,
    payoff: null,
    refloor: null,
    principal: null,
    fees: null,
    interest: null,
    addToReserves: null,
    showResults: false,
    payoffDate: null,
    unitId: null,
    showError: false,
    showPayoffLink: false,
    showCoupons: false,

    actions: {
      calculatePayoff: function calculatePayoff() {
        var _this = this;

        var date = $("#payoff-date").val();
        var payoffDate = moment(date).format("YYYY/MM/DD");
        var now = new Date();
        var mm = now.getMonth() + 1;
        var dd = now.getDate();
        var yyyy = now.getFullYear();
        var today = yyyy + "/" + mm + "/" + dd;
        today = moment(today).format("YYYY/MM/DD");
        this.set("payoffDate", date);

        var couponRedemptionId = undefined;

        if (this.get("showCoupons")) {
          couponRedemptionId = $("#coupon-select").val();
        }

        if (moment(payoffDate).isValid() && payoffDate >= today) {
          var _ret = (function () {
            var headers = {};
            _this.get("session").authorize("authorizer:token", function (headerName, headerValue) {
              headers[headerName] = headerValue;
            });
            var self = _this;
            return {
              v: _this.get("ajax").request(new _dealerPortalAdaptersApplication["default"]().fullURL("units/" + _this.get("unitId") + "/amounts_due_on"), {
                method: "get",
                headers: headers,
                data: {
                  amounts_due_on_date: moment(payoffDate).format("YYYY/MM/DD"),
                  coupon_redemption_id: couponRedemptionId
                }
              }).then(function (response) {
                _this.setProperties({
                  payoff: response["payoff"],
                  refloor: response["refloor"],
                  fees: response["fees"],
                  interest: response["interest"],
                  showResults: true,
                  addToReserves: _this.get("model.unit.addToReserves"),
                  showError: false
                });

                var principal = _this.get("payoff") - (parseFloat(_this.get("fees")) + parseFloat(_this.get("interest")) + parseFloat(_this.get("addToReserves")));
                _this.set("principal", principal);

                _this.set("showPayoffLink", moment(_this.get("model.dealer.branch.achDate")) <= moment(payoffDate));

                if (_this.get("model.dealer.couponRedemptions").length > 0) {
                  _this.set("showCoupons", true);
                }
              })
            };
          })();

          if (typeof _ret === "object") return _ret.v;
        } else {
          this.setProperties({ showResults: false, showError: true });
        }
      },
      makePayment: function makePayment() {
        var unit = this.get("model.unit");
        this.transitionToRoute("access-home.ach-scheduling", unit.get("id"), {
          queryParams: { calculatorPayoffDate: this.get("payoffDate") }
        });
      }
    }
  });
});