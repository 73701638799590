define('dealer-portal/routes/access-home/ach-scheduling', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    queryParams: {
      fee_line_id: {
        refresh_model: false
      },
      confirmPayment: {
        refresh_model: false
      },
      unitId: {
        refresh_model: false
      },
      calculatorPayoffDate: {
        refresh_model: false
      }
    },
    fee_line_id: null,
    confirmPayment: null,
    calculatorPayoffDate: null,

    model: function model(params) {
      this.set('fee_line_id', params['fee_line_id']);
      if (params['calculatorPayoffDate'] && params['calculatorPayoffDate'].length) {
        this.set('calculatorPayoffDate', params['calculatorPayoffDate']);
      }
      return _ember['default'].RSVP.hash({
        unit: this.get('store').findRecord('unit', params['id']),
        dealer: this.store.find('dealer', this.get('session').get('data').authenticated.dealerId),
        bankAccounts: this.get('store').query('bank-account', {
          filter: { owner_id: this.get('dealerId') }
        }),
        ach: params['achId'] ? this.get('store').find('transfer', params['achId']) : null
      });
    },
    redirect: function redirect(model, transition) {
      if (!transition.queryParams.confirmPayment) {
        var needsAuth = false;
        if (model.bankAccounts.get('length')) {
          model.bankAccounts.forEach(function (ba) {
            if (ba.get('needsAchAuth')) {
              needsAuth = true;
            }
          });
        } else {
          needsAuth = true;
        }
        if (needsAuth) {
          this.transitionTo('access-home.bank-accounts');
          alert('A bank account must be setup to make payments from.');
        }
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (this.get('fee_line_id') != null) {
        controller.setProperties({ selectedFee: this.get('fee_line_id'),
          feePayment: true });
      }
      if (this.get('calculatorPayoffDate') && this.get('calculatorPayoffDate') != null) {
        controller.setProperties({ payOnDate: this.get('calculatorPayoffDate'),
          payoffPayment: true, paymentTypeNumber: 3, fromCalculator: true });
      }
    },
    dealerId: _ember['default'].computed(function () {
      return this.get('session').get('data').authenticated.dealerId;
    })
  });
});