define('dealer-portal/routes/access-home/reports/sold', ['exports', 'ember', 'moment', 'accounting/format-money', 'ember-i18n'], function (exports, _ember, _moment, _accountingFormatMoney, _emberI18n) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _ember['default'].Route.extend({
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    moment: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    controllerName: 'access-home',
    model: function model() {
      return _ember['default'].RSVP.hash({
        units: this.get('store').query('unit', {
          filter: { scope: 'sold' }
        }),
        dealer: this.get('store').find('dealer', this.get('session').get('data').authenticated.dealerId)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var i18n = this.get('i18n');
      var soldInventoryHeadings = [{ key: 'serialNumber', display: this.get('i18n').t('reports.serialNumber').string }, { key: 'stockNumber', display: '<span class="first-heading">' + this.get('i18n').t('reports.stockNumber').string + '</span>' }, { key: 'lineType', display: this.get('i18n').t('reports.lineType').string }, { key: 'name', display: this.get('i18n').t('reports.car').string }, { key: 'firstFlooredOn', display: this.get('i18n').t('reports.firstFlooredOn').string }, { key: 'totalDaysFloored', display: this.get('i18n').t('reports.days').string }, { key: 'currentPrincipal', display: this.get('i18n').t('reports.principal').string }, { key: 'currentFees', display: this.get('i18n').t('reports.fees').string }, { key: 'currentInterest', display: this.get('i18n').t('reports.interest').string }, { key: 'payoff', display: this.get('i18n').t('reports.payoff').string }, { key: 'titleStatus', display: this.get('i18n').t('reports.title').string }, { key: 'soldOn', display: this.get('i18n').t('reports.sold').string }, { key: 'paidFees', display: this.get('i18n').t('reports.paidFees').string }, { key: 'paidInterest', display: this.get('i18n').t('reports.paidInterest').string }];

      if (model.dealer.get('insuranceCoverageElected') === true) {
        soldInventoryHeadings.splice(9, 0, { key: 'currentInsuranceFees', display: this.get('i18n').t('reports.insuranceFees').string });
      }

      controller.set('inventoryHeadings', soldInventoryHeadings);
      var soldInventoryRows = [];

      function buildUnitName(unit) {
        var unitSoldLabel = unit.get('sold') ? '<span class="sold-label">SOLD - </span>' : '';
        return "<a class='view-unit-detail' href='/access-home/" + unit.get('id') + "'>" + unitSoldLabel + unit.get('name') + "</a>";
      }

      function styleAndFormatAsMoney(dollarAmount) {
        return '<span class="pull-right">' + (0, _accountingFormatMoney['default'])(dollarAmount) + '</span>';
      }
      model.units.forEach(function (unit) {
        var rowHash = _extends({
          serialNumber: unit.get('serialNumber'),
          stockNumber: unit.get('stockNumber'),
          lineType: unit.get('lineType'),
          name: "<a href='/access-home/" + unit.get('id') + "'>" + unit.get('name') + "</a>",
          firstFlooredOn: "<span class='timestamp' style='display: none;'>" + (0, _moment['default'])(unit.get('firstFlooredOn')).format('x') + "</span> " + (0, _moment['default'])(unit.get('firstFlooredOn')).format('MM/DD/YYYY'),
          totalDaysFloored: unit.get('totalDaysFloored'),
          currentPrincipal: styleAndFormatAsMoney(unit.get('currentPrincipal')),
          currentFees: styleAndFormatAsMoney(unit.get('currentFees')),
          currentInterest: styleAndFormatAsMoney(unit.get('currentInterest'))
        }, model.dealer.get('insuranceCoverageElected') === true ? { currentInsuranceFees: styleAndFormatAsMoney(unit.get('currentInsuranceFees')) } : {}, {
          payoff: styleAndFormatAsMoney(unit.get('payoff')),
          titleStatus: unit.get('titleStatus'),
          soldOn: "<span class='timestamp' style='display: none;'>" + (0, _moment['default'])(unit.get('soldOn')).format('x') + "</span> " + (0, _moment['default'])(unit.get('soldOn')).format('MM/DD/YYYY'),
          paidFees: styleAndFormatAsMoney(unit.get('paidFees')),
          paidInterest: styleAndFormatAsMoney(unit.get('paidInterest'))
        });
        soldInventoryRows.pushObject(rowHash);
      });
      controller.set('inventoryRows', soldInventoryRows);
    }
  });
});