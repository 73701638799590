define('dealer-portal/adapters/application', ['exports', 'ember', 'ember-data', 'dealer-portal/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _ember, _emberData, _dealerPortalConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin) {
  exports['default'] = _emberData['default'].JSONAPIAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    authorizer: 'authorizer:token',
    host: _dealerPortalConfigEnvironment['default'].APP.apiHost,
    namespace: 'api/1',
    userLog: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      if (_ember['default'].getOwner(this)) {
        this.set('headers', this.headers(this.get('userLog')));
      }
    },

    fullURL: function fullURL(path) {
      return this.urlPrefix() + '/' + path;
    },

    headers: function headers(userLog) {
      return {
        'X-Correlation-ID': userLog.uuid(),
        'X-Dimensions': userLog.dimensions()
      };
    },

    headersWithAuth: function headersWithAuth(session, userLog) {
      var headers = this.headers(userLog);

      session.authorize('authorizer:token', function (headerName, headerValue) {
        headers[headerName] = headerValue;
      });

      return headers;
    },

    // This is silly, but I don't understand all the injects/getOwner stuff.
    // But, it's somewhat more DRY.
    simple: function simple(ajax, session, userLog, path, method, data, ajaxMethod) {
      var options = {
        method: method,
        headers: this.headersWithAuth(session, userLog),
        data: data || {}
      };

      if ('raw' === ajaxMethod) {
        return ajax.raw(this.fullURL(path), options);
      } else {
        return ajax.request(this.fullURL(path), options);
      }
    },

    queryRecord: function queryRecord(store, type, query) {
      if ('string' === typeof query.slug) {
        var url = this.urlForFindRecord(query.slug, type.modelName);
        return _ember['default'].$.getJSON(url);
      } else {
        var url = this.buildURL(type.modelName, null, null, 'queryRecord', query);
        return this.ajax(url, 'GET', { data: query });
      }
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      return this.ajax(url, 'PUT', { data: data });
    },

    /***
     * Overriding to handle expiration header. This is probably a bad place to
     * do so.
     */
    isSuccess: function isSuccess(status, headers) {
      var retval = status >= 200 && status < 300 || status === 304;

      if (retval && headers['x-token-expiration'] && _ember['default'].getOwner(this)) {
        this.get('userLog').set('secondsUntilExpiration', parseInt(headers['x-token-expiration']));
      }

      return retval;
    }
  });
});