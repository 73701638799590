define('dealer-portal/controllers/contact', ['exports', 'ember', 'dealer-portal/validations/contact', 'dealer-portal/validations/local-contact'], function (exports, _ember, _dealerPortalValidationsContact, _dealerPortalValidationsLocalContact) {
  exports['default'] = _ember['default'].Controller.extend({
    ContactValidations: _dealerPortalValidationsContact['default'],
    LocalContactValidations: _dealerPortalValidationsLocalContact['default'],
    // branchesController: Ember.inject.controller('branches'),
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    branch_id: null,
    currentUserId: null,

    branchesSelect: _ember['default'].computed(function () {
      var branches = this.get('model').branches;
      var branchOptions = [];
      // if(localStorage.places) {
      //   let branchId = JSON.parse(localStorage.places).objectAt(0).branchId;
      //   this.get('model').contact.set('branch_id', branchId);
      // }
      if (this.get('session.isAuthenticated')) {
        var dealerId = this.get('session').get('data').authenticated.dealerId;
        var dealer = this.get('store').find('dealer', dealerId);
        this.get('model').contact.setProperties({ dealer_user_id: dealerId,
          branch_id: dealer.get('branch_id') });
      }
      for (var x = 0; x < branches.get('length'); x++) {
        branchOptions.pushObject({ value: branches.objectAt(x).get('name'),
          id: branches.objectAt(x).id });
      }
      return branchOptions;
    }),

    actions: {
      updateBranchSelect: function updateBranchSelect(value) {
        var selected = value.target.value;
        _ember['default'].set(this, 'value', selected);
      },
      expired: function expired() {},
      submit: function submit() {
        return true;
      }
    }
  });
});