define('dealer-portal/validations/dealer-file', ['exports'], function (exports) {
  /* globals requirejs */
  requirejs.entries['ember-validations/messages'] = requirejs.entries['ember-changeset-validations/utils/messages'];

  // import {
  //   validatePresence
  // } from 'ember-changeset-validations/validators';

  exports['default'] = {
    // name: validatePresence({presence: true})
  };
});