define('dealer-portal/services/user-log', ['exports', 'ember', 'ember-uuid'], function (exports, _ember, _emberUuid) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service('session'),

    _uuid: undefined,
    secondsUntilExpiration: undefined,

    uuid: function uuid() {
      if (!this.get('_uuid')) {
        if (!this.get('session').get('data.sessionUuid')) {
          this.get('session').set('data.sessionUuid', (0, _emberUuid.v1)());
        }

        this.set('_uuid', this.get('session').get('data.sessionUuid'));
      }

      return this.get('_uuid');
    },

    dimensions: function dimensions() {
      return window.screen.availWidth + 'x' + window.screen.availHeight;
    },

    expireSession: function expireSession() {
      this.get('session').set('data.expired', true);
    }
  });
});