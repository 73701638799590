define('dealer-portal/mixins/dealer-redirect', ['exports', 'ember', 'dealer-portal/config/environment', 'dealer-portal/adapters/application'], function (exports, _ember, _dealerPortalConfigEnvironment, _dealerPortalAdaptersApplication) {
  exports['default'] = _ember['default'].Mixin.create({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var access = this.get('session.data.authenticated.access');

      if (false === access || 'false' === access) {
        this.transitionTo('account-unavailable');
      }
    }
  });
});