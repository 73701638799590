define('dealer-portal/components/invite-user-form', ['exports', 'ember', 'dealer-portal/validations/user', 'ember-changeset-validations', 'ember-changeset'], function (exports, _ember, _dealerPortalValidationsUser, _emberChangesetValidations, _emberChangeset) {
  exports['default'] = _ember['default'].Component.extend({
    UserValidations: _dealerPortalValidationsUser['default'],
    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset['default'](this.get('model'), (0, _emberChangesetValidations['default'])(_dealerPortalValidationsUser['default']));
    },

    userLevels: [{ id: 'sales', value: 'Sales' }, { id: 'financial', value: 'Financial' }, { id: 'admin', value: 'Administrator' }],
    actions: {
      submit: function submit() {
        var changeset = this.get('changeset');
        this.attrs.save(changeset);
      }
    }
  });
});