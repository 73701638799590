define('dealer-portal/models/web-content', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({

    body: _emberData['default'].attr('string'),
    bodyEs: _emberData['default'].attr('string'),
    header: _emberData['default'].attr('string'),
    headerEs: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    photoUrl: _emberData['default'].attr('string'),
    videoMp4Url: _emberData['default'].attr('string'),
    videoWebmUrl: _emberData['default'].attr('string'),

    hasVideo: _ember['default'].computed('videoMp4Url', 'videoWebmUrl', function () {
      console.log("This: ", this);
      return this.get('videoMp4Url') || this.get('videoWebmUrl');
    })
  });
});