define('dealer-portal/models/dealer-place', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    number_of_lots: _emberData['default'].attr(),
    name: _emberData['default'].attr(),
    address1: _emberData['default'].attr(),
    address2: _emberData['default'].attr(),
    city: _emberData['default'].attr(),
    state: _emberData['default'].attr(),
    zip: _emberData['default'].attr(),
    county: _emberData['default'].attr(),
    phone: _emberData['default'].attr(),
    fax: _emberData['default'].attr(),
    status: _emberData['default'].attr(),
    latitude: _emberData['default'].attr(),
    longitude: _emberData['default'].attr(),
    dealer: _emberData['default'].belongsTo('dealer'),
    branch: _emberData['default'].attr(),
    dealerId: _emberData['default'].attr(),
    associated_marker: _emberData['default'].attr(),
    placeType: _emberData['default'].attr()

    // slugOrId: Ember.computed('id', 'slug', function() {
    //   return (this.get('name') || this.get('id') || 'new').dasherize();
    // })
  });
});
//import Ember from 'ember';