define('dealer-portal/validations/bank-account', ['exports', 'ember-changeset-validations/validators'], function (exports, _emberChangesetValidationsValidators) {
  /* globals requirejs */
  requirejs.entries['ember-validations/messages'] = requirejs.entries['ember-changeset-validations/utils/messages'];

  exports['default'] = {
    bank_name: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    account_type: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    routingNumber: [(0, _emberChangesetValidationsValidators.validatePresence)({ presence: true }), (0, _emberChangesetValidationsValidators.validateNumber)({ positive: true }), (0, _emberChangesetValidationsValidators.validateLength)({ min: 9 }), (0, _emberChangesetValidationsValidators.validateLength)({ max: 9 })],
    accountNumber: [(0, _emberChangesetValidationsValidators.validatePresence)({ presence: true }), (0, _emberChangesetValidationsValidators.validateNumber)({ positive: true })]
  };
});