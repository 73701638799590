define('dealer-portal/components/em-form', ['exports', 'ember-rapid-forms/components/em-form'], function (exports, _emberRapidFormsComponentsEmForm) {
  exports['default'] = _emberRapidFormsComponentsEmForm['default'].extend({
    showErrorsOnRender: true,

    submit: function submit(e) {
      var promise;
      if (e) {
        e.preventDefault();
      }

      var changeset = this.get('model');
      var snapshot = changeset.snapshot();

      promise = changeset.validate();
      return promise.then((function (_this) {
        _this.set('isSubmitted', true);

        return function () {
          if (changeset.get('isValid')) {
            return _this.sendAction('action', changeset);
          }
        };
      })(this))['catch'](function () {
        changeset.restore(snapshot);
      });
    }
  });
});