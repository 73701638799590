define('dealer-portal/routes/access-home/reset-route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    controllerName: 'access-home',
    redirect: function redirect(params) {
      var controller = this.controllerFor('access-home');
      controller.set('showUnitFiles', true);
      this.transitionTo('access-home.show', controller.get('unitId'));
    },
    renderTemplate: function renderTemplate() {
      var from = this.controller.get('transitionFrom');
      this.transitionTo('access-home.' + from);
    }

  });
});