define('dealer-portal/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'dealer-portal/config/environment'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _dealerPortalConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    i18n: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    userLog: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var locale = this.get('session.data.locale');
      if (locale) {
        this.set('i18n.locale', locale);
      }
    },

    model: function model() {
      // const dealer = this.store.findRecord('dealer',
      // this.get('session').get('data').authenticated.dealerId, {include: 'branches'});
      // console.log('dealer.branch: ', console.log(this.branches));
      // return dealer;
      var dealer = null;
      if (this.get('session.isAuthenticated')) {
        dealer = this.store.findRecord('dealer', this.get('session').get('data').authenticated.dealerId, { includes: 'branch' });
      }

      var isSafari = navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i) && !navigator.userAgent.match(/crios/i);

      return _ember['default'].RSVP.hash({
        isSafari: isSafari,
        dealer: dealer,
        branches: this.store.query('branch', {
          filter: { scope: 'open_corp' }
        })
      });
    },

    actions: {
      error: function error(_error) {
        var errorObject = _error;

        if (_error.errors) {
          if ('401' === _error.errors[0].status) {
            return false;
          }
        } else {
          errorObject = {
            message: _error.message,
            stack: _error.stack
          };
        }

        var url = _dealerPortalConfigEnvironment['default'].APP.apiHost + '/api/1/logging/error';
        var errorData = Object.assign({
          correlationId: this.get('userLog').uuid(),
          authToken: this.get('session.data.authenticated.token')
        }, errorObject);

        if (!_error.errors || _error.errors[0].detail != 'The adapter operation was aborted') {
          _ember['default'].$.post(url, { error: errorData });
        }

        return true;
      }
    }
  });
});