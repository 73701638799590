define('dealer-portal/routes/access-home/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    controllerName: 'access-home',
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var userLevel = this.get('session').get('data').authenticated.level;
      if (userLevel === 'sales') {
        this.transitionTo('access-home.index');
      }
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        unit: this.get('store').findRecord('unit', params['id']),
        pendingTransfers: this.get('store').query('transfer', {
          filter: { scope: 'unit', unit_id: params['id'] }
        }),
        bosUFile: this.get('store').createRecord('unit-file'),
        fundUFile: this.get('store').createRecord('unit-file'),
        unitFiles: this.get('store').query('unit-file', {
          id: params['id']
        }),
        newUnitFile: this.get('store').createRecord('unit-file')
      });
    }
  });
});