define('dealer-portal/models/branch', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    address1: _emberData['default'].attr('string'),
    address2: _emberData['default'].attr('string'),
    branchId: _emberData['default'].attr('number'),
    branchMarkets: _emberData['default'].hasMany('branchMarket'),
    city: _emberData['default'].attr('string'),
    code: _emberData['default'].attr(),
    color: _emberData['default'].attr('string'),
    email: _emberData['default'].attr(),
    fax: _emberData['default'].attr('string'),
    fridayHours: _emberData['default'].attr('string'),
    groupPhotoUrl: _emberData['default'].attr('string'),
    auctions: _emberData['default'].hasMany('auction'),
    mapPoints: _emberData['default'].hasMany('mapPoint'),
    polygonPath: _emberData['default'].attr('string'),
    latitude: _emberData['default'].attr('number'),
    location_state: _emberData['default'].attr(),
    longitude: _emberData['default'].attr('number'),
    name: _emberData['default'].attr('string'),
    nameWithState: _emberData['default'].attr('string'),
    normalHours: _emberData['default'].attr('string'),
    officePhotoUrl: _emberData['default'].attr('string'),
    phone2: _emberData['default'].attr('string'),
    phone: _emberData['default'].attr('string'),
    slug: _emberData['default'].attr('string'),
    state: _emberData['default'].attr('string'),
    web: _emberData['default'].attr('string'),
    zip: _emberData['default'].attr('string'),
    achDate: _emberData['default'].attr(''),
    achProcessed: _emberData['default'].attr(''),

    branchAddress: _ember['default'].computed('address1', 'address2', 'city', 'state', 'zip', function () {
      return this.get('address1') + ' ' + this.get('address2') + '  ' + this.get('city') + ', ' + this.get('state') + ', ' + this.get('zip');
    }),
    polygonPoints: _ember['default'].computed('mapPoints', function () {
      var latLngPoints = [];
      var points = this.get('mapPoints');
      if (!_ember['default'].isEmpty(points)) {
        points.forEach(function (item) {
          latLngPoints.push(new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)));
        });
      }
      return latLngPoints;
    }),

    slugOrId: _ember['default'].computed('id', 'slug', function () {
      return this.get('slug') || this.get('id');
    })
  });
});